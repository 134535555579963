import React, { useEffect, useState } from "react";
import Logo from "../../assets/hire48logoHorizontal.png";
import { MdFindInPage } from "react-icons/md";
import { BsPersonWorkspace } from "react-icons/bs";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoArrowUpRight } from "react-icons/go";
import { capitalizeFirstLetterOfEachWord } from "../Functions/word.fun";
import { logout } from "../../Redux/User/login_reducer";
import { candidate, company, recruiter, superAdmin } from "../../Config/config";
import { notification } from "../../Redux/notifications/notificationsReducer";
import performRequest from "../../APIs/request";
import { persistor } from "../../Redux/store";
import Logintimecounter from "./login_time_counter";
import { CustomErrorResponse } from "../../Pages/CustomComponent/custom_error_response";
import { FcEngineering, FcLock, FcSportsMode } from "react-icons/fc";
const getNavItemByRole = (role) => {
  const roles = [
    "purchaseStaff",
    "saleStaff",
    "opsStaff",
    "inspectionStaff",
    "marketingStaff",
    "adminStaff",
    "labourStaff",
    "salesAgency",
    "ITStaff",
    "SSMStaff",
    "salesManagerStaff",
    "clientFeedback",
    "accountStaff",
  ];
  const employeeRole = roles.find((el) => el === role);

  if (employeeRole) {
    return [
      "Internal",
      "Task Management",
      "Leave Management",
      "Employee Salary",
      "Internal FAQ",
    ];
  }

  if (role === superAdmin) {
    return [
      "Internal",
      "Find Work",
      "Free Resume Builder",
      "Browse Top Companies",
      "AI Interview Cracker",
      "Get Skill Badged",
      "Find Talent",
      "Onboard Hiring Partner",
      // "Browse Top Candidate",
      "Post Jobs",
      "View Jobs",
      "Job Board",
      "Post Jobs",
      "Post Contest",
      "Applicants",
      "All Payments",
      "All Companies",
      "All Shortlist Request",
      "Booked Demo List",
      "Contact Us List",
      "Create",
      "Actions",
      "Data",
      "Task Management",
      "Leave Management",
      "Employee Management",
      "Employee Salary",
      "Internal FAQ",
      "All Candidates",
      "Attendance Management",
      "Email Marketing",
    ];
  }
  if (role === candidate) {
    return [
      "Find Work",
      "Free Resume Builder",
      "Browse Top Companies",
      // "AI Interview Cracker",
      "Get Skill Badged",
    ];
  }
  if (role === company) {
    return [
      "Find Talent",

      // "Browse Top Candidate",
      "Post Jobs",
      "View Jobs",
      "Job Board",
      "My Scheduled Interview",
    ];
  }
  if (role === recruiter) {
    return [
      "Internal",
      // "Browse Top Candidate",
      "Post Jobs",
      "Post Contest",
      "Applicants",
      "All Candidates",
      "List All Companies",
      "Onboard Hiring Partner",
      "All Onboarding Request",
      "All Shortlist Request",
      "All Payments",
      "Booked Demo List",
      "Contact Us List",
      "Create",
      "Actions",
      "Data",
      "Task Management",
      "Leave Management",
      "Employee Management",
      "Employee Salary",
      "Internal FAQ",

    ];
  } else {
    return [];
  }
};
const Header = () => {
  const location = useLocation();
  const endpoint = location.pathname;
  const [activeNavItem, setActiveNavItem] = useState("");
  const store = useSelector((store) => store.userLogin);
  const role = useSelector((store) => store.userLogin?.user?.Role);
  const notifications = useSelector((store) => store.allNotifications);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navItem, setNavItem] = useState([]);
  const loginState = useSelector((store) => store.userLogin);
  const [loginTime, setLoginTime] = useState();

  useEffect(() => {
    if (store.isLoggedIn && role) {
      setNavItem(getNavItemByRole(role));
    }
    if (!store.isLoggedIn) {
      setNavItem([]);
    }
  }, [role, store]);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  useEffect(() => {
    if (store?.user?.UserId && store?.user?.isEmployee) {
      performRequest("GET", `/attendance/getuserattendance/`)
        .then((res) => {
          if (res.status === 201) {
            setLoginTime(res.data?.attendance?.time);
          }
        })
        .catch((err) => {
          CustomErrorResponse(err);
        });
    }
  }, [store, loginState.userlog]);

  useEffect(() => {
    if (
      endpoint.includes("/top-hires") ||
      endpoint.includes("/resume-builder") ||
      endpoint.includes("/ai-interview-cracker") ||
      endpoint.includes("/learn-soft-skills") ||
      endpoint.includes("/get-skill-badges")
    ) {
      setActiveNavItem("FindWork");
    } else if (
      endpoint.includes("/candidate-comparison") ||
      endpoint.includes("/job-board") ||
      endpoint.includes("/create-jobs")
    ) {
      setActiveNavItem("FindTalent");
    } else if (
      endpoint.includes("/employee-management") ||
      endpoint.includes("/task-management") ||
      endpoint.includes("/internal-FAQ") ||
      endpoint.includes("/employee-salary") ||
      endpoint.includes("/employee-attendance") ||
      endpoint.includes("/employee-leave-management") ||
      endpoint.includes("/email-marketing")
    ) {
      setActiveNavItem("Internal");
    } else if (
      endpoint.includes("/post-contest") ||
      endpoint.includes("/create-jobs")
    ) {
      setActiveNavItem("Create");
    } else if (
      endpoint.includes("/onboard-hiring-partner") ||
      endpoint.includes("/job-details") ||
      endpoint.includes("/employer-onboarding") ||
      endpoint.includes("/schedule-interview") ||
      endpoint.includes("/view-jobs") ||
      endpoint.includes("/candidate-comparison") ||
      endpoint.includes("/job-board") ||
      endpoint.includes("/my-scheduled-interview") ||
      endpoint.includes("/shortlist-request")
    ) {
      setActiveNavItem("Actions");
    } else if (
      endpoint.includes("/list-allcandidates") ||
      endpoint.includes("/job-details") ||
      endpoint.includes("/book-demo-list") ||
      endpoint.includes("/employer-onboarding") ||
      endpoint.includes("/schedule-interview") ||
      endpoint.includes("/view-jobs") ||
      endpoint.includes("/candidate-comparison") ||
      endpoint.includes("/jobs-list") ||
      endpoint.includes("/my-scheduled-interview") ||
      endpoint.includes("/all-payments")
    ) {
      setActiveNavItem("Data");
    } else {
      setActiveNavItem("");
    }
  }, [endpoint]);

  const closeNavbar = () => {
    const navbarMenu = document.getElementById("navbar-menu");
    if (navbarMenu && navbarMenu.classList.contains("show")) {
      document.getElementById("navbar-toggler").click();
    }
  };

  const handleNotifications = (el) => {
    if (!el.isRead) {
      const data = { isRead: true };
      const updateRedux = notifications.allNotifications.map((ele) => {
        if (ele.id === el.id) {
          return {
            ...ele,
            isRead: true,
          };
        }
        return ele;
      });
      dispatch(notification(updateRedux));

      performRequest("PATCH", `/notification/updatenotification/${el.id}`, data)
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        });
      if (el.jobId) {
        navigate(`/view-jobs/${el.jobId}`);
      }
    }
    if (el.jobId) {
      navigate(`/view-jobs/${el.jobId}`);
    }
  };
  const userReviewedTime = (time) => {
    const currentTime = new Date();
    const notificationTime = new Date(time);
    const difference = currentTime.getTime() - notificationTime.getTime();
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    return days > 0
      ? `${days} days ago`
      : hours > 0
      ? `${hours} hours ago`
      : minutes > 0
      ? `${minutes} minutes ago`
      : `${seconds} seconds ago`;
  };

  const handelLogout = () => {
    dispatch(logout());
    navigate("/account-login");
  };
  const officeLogoutAttendance = () => {
    navigate("/logout-attendance");
  };

  return (
    <>
      <style>
        {`
          .cumstom-class  {
            width: 30vw;   /* Default width */
          }

          @media (max-width: 768px) {
            .cumstom-class  {
              width: 75vw;   /* Adjust width for smaller screens */
            }
          }

          @media (max-width: 480px) {
            .cumstom-class  {
              width: 75vw;   
            }
          }
        `}
      </style>
      <header className="navbar navbar-expand-md d-print-none">
        <div className="container-xxl">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
            aria-controls="navbar-menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" id="navbar-toggler"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <span onClick={() => navigate("/")} className="cursor-pointer">
              <img
                src={Logo}
                width="210"
                height="42"
                alt="Tabler"
                className="navbar-brand-image"
              />
            </span>
          </h1>
          <div className="navbar-nav flex-row order-md-last">
            {store.isLoggedIn && (
              <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link d-flex lh-1 text-reset p-0"
                  data-bs-toggle="dropdown"
                  aria-label="Open user menu"
                >
                  <div className="nav-item dropdown">
                    {store?.user?.Role === "company" && (
                      <div className="d-flex">
                        <div className="mt-1 me-1">
                          {store?.user?.credit ?? 0} Credits
                        </div>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="green"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-cards"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3.604 7.197l7.138 -3.109a.96 .96 0 0 1 1.27 .527l4.924 11.902a1 1 0 0 1 -.514 1.304l-7.137 3.109a.96 .96 0 0 1 -1.271 -.527l-4.924 -11.903a1 1 0 0 1 .514 -1.304z" />
                          <path d="M15 4h1a1 1 0 0 1 1 1v3.5" />
                          <path d="M20 6c.264 .112 .52 .217 .768 .315a1 1 0 0 1 .53 1.311l-2.298 5.374" />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="nav-item dropdown me-3">
                    <span
                      href="#"
                      className="nav-link px-0"
                      data-bs-toggle="dropdown"
                      tabIndex="-1"
                      aria-label="Show notifications"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                        <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                      </svg>
                      {notifications?.allNotifications &&
                        notifications?.allNotifications.length > 0 &&
                        notifications?.allNotifications.map((el) => {
                          if (!el.isRead) {
                            return (
                              <span className="badge bg-red" key={el.id}></span>
                            ); // Make sure to add a unique key
                          }
                          return null; // Return null for read notifications
                        })}
                    </span>

                    <div
                      className="dropdown-menu cumstom-class dropdown-menu-arrow dropdown-menu-end dropdown-menu-card"
                      style={{ maxHeight: "400px" }}
                    >
                      <div className="card " style={{ maxHeight: "400px" }}>
                        <div className="card-header">
                          <h3 className="card-title">Notifications</h3>
                        </div>
                        <div className="list-group list-group-flush list-group-hoverable overflow-scroll">
                          {notifications?.allNotifications &&
                          notifications?.allNotifications?.length > 0 ? (
                            notifications?.allNotifications?.map(
                              (el, index) => {
                                let classDetail = "";
                                if (!el.isRead) {
                                  classDetail =
                                    "status-dot status-dot-animated bg-red d-block";
                                } else if (el.isRead) {
                                  classDetail =
                                    "status-dot status-dot-animated bg-green d-block";
                                }
                                return (
                                  <div
                                    className="list-group-item"
                                    key={index}
                                    onClick={() => handleNotifications(el)}
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-auto">
                                        <span className={classDetail}></span>
                                      </div>
                                      <div className="col text-truncate  p-2">
                                        <div className="d-block text-secondary text-truncate mt-n1">
                                          {el.message}
                                          <br />

                                          <div className="ps-0 fs-0 text-black-50">
                                            {userReviewedTime(el?.createdAt)}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <span
                                          href="#"
                                          className="list-group-item-actions"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="icon text-muted"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            ></path>
                                            <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <div>
                              <p className="pt-2 ps-2">
                                Stay tuned for future updates!
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {loginState?.isLoggedIn &&
                    loginState?.userlog &&
                    loginTime && (
                      <div className="nav-item ms-0 me-2">
                        <span className="d-flex lh-1 text-reset p-0 cursor-pointer">
                          <div
                            className="d-xl-block pe-2"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div>
                              <span className="ps-2">{loginTime}</span>
                            </div>
                            <div className="ms-2 mt-1 small text-muted">
                              <Logintimecounter loginTime={loginTime} />
                            </div>
                          </div>
                        </span>
                      </div>
                    )}

                  <span className="avatar avatar-sm">
                    <img src={store?.user?.profilePicture} alt="" srcSet="" />
                  </span>
                  <div className="d-none d-xl-block ps-2">
                    <div>
                      {capitalizeFirstLetterOfEachWord(store?.user?.Name)}
                    </div>
                    <div className="mt-1 small text-secondary">
                      {capitalizeFirstLetterOfEachWord(store?.user?.Role)}
                    </div>
                  </div>
                </a>

                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <button
                    onClick={() => navigate("/my-profile")}
                    className="dropdown-item"
                  >
                    My Profile
                  </button>
                  {store?.user?.Role !== company &&
                    store?.user?.isEmployee === false &&
                    store?.user?.Role !== recruiter && (
                      <button
                        onClick={() => navigate("/my-applications")}
                        className="dropdown-item"
                      >
                        My Applications
                      </button>
                    )}
                  {(store?.user?.isEmployee === false ||
                    store?.user?.Role === company) && (
                    <button
                      onClick={() => {
                        dispatch(logout());
                        persistor.purge();
                        localStorage.removeItem("previousUrl");
                        navigate("/");
                      }}
                      className="dropdown-item"
                    >
                      Logout
                    </button>
                  )}

                  {loginState.isLoggedIn &&
                    loginState.user &&
                    loginState.userlog &&
                    loginState.userlog.firstLogin && (
                      <span
                        onClick={officeLogoutAttendance}
                        className="dropdown-item"
                      >
                        Office Logout
                      </span>
                    )}
                  {loginState.isLoggedIn &&
                    loginState.user &&
                    loginState.userlog &&
                    loginState.userlog.firstLogin && (
                      <span onClick={handelLogout} className="dropdown-item">
                        System Logout
                      </span>
                    )}
                </div>
              </div>
            )}
            {!store.isLoggedIn && (
              <div
                className="collapse navbar-collapse me-5 d-lg-block d-none"
                id="navbar-menu"
              >
                <div className="  d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        href="/pricing"
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                          color: "black",
                        }}
                      >
                        <span className="nav-link-title">Pricing</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
             
            {!store.isLoggedIn && (
              <div className="nav-item">
                <div className="flex-row">
                  <button
                    onClick={() => navigate("/account-login")}
                    className="btn btn-pill btn-indigo"
                  >
                    Account Login
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="collapse navbar-collapse" id="navbar-menu">
            <div className="  d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
              <ul className="navbar-nav">
                {!store.isLoggedIn && (
                  <div className="d-lg-none d-md-none">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        href="/pricing"
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                          color: "black",
                        }}
                      >
                        <span className="nav-link-title">Pricing</span>
                      </a>
                    </li>
                          
                  </div>
                )}

                {navItem.includes("Find Work") && (
                  <li
                    className={
                      activeNavItem === "FindWork"
                        ? "nav-item active dropdown"
                        : "nav-item dropdown"
                    }
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span
                        id="k-findwork"
                        className="nav-link-icon d-md-none d-lg-inline-block"
                      >
                        <BsPersonWorkspace size={18} />
                      </span>
                      <span className="nav-link-title">Find Work</span>
                    </a>
                    <div
                      onClick={() => {
                        document.getElementById("k-findwork").click();
                        closeNavbar();
                      }}
                      className="dropdown-menu "
                    >
                      {navItem.includes("Browse Top Companies") && (
                        <NavLink
                          to={"/top-hires"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Browse Top Companies
                        </NavLink>
                      )}

                      {navItem.includes("Free Resume Builder") && (
                        <NavLink
                          to={"/resume-builder"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Free Resume Builder
                        </NavLink>
                      )}

                      {navItem.includes("AI Interview Cracker") && (
                        <NavLink
                          to={"/ai-interview-cracker"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          {" "}
                          AI Interview Cracker
                        </NavLink>
                      )}

                      {/* {  navItem.includes('AI Interview Cracker') &&<NavLink
                      to={"/learn-soft-skills"}
                      className={({ isActive }) =>
                        isActive ? "dropdown-item active" : "dropdown-item"
                      }
                    >
                      Learn Soft Skills
                    </NavLink>} */}

                      {navItem.includes("Get Skill Badged") && (
                        <NavLink
                          to={"/get-skill-badges"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Get Skill Badged
                        </NavLink>
                      )}
                    </div>
                  </li>
                )}
                {navItem.includes("Find Talent") && (
                  <li
                    className={
                      activeNavItem === "FindTalent"
                        ? "nav-item active dropdown"
                        : "nav-item dropdown"
                    }
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span
                        id="k-findtalent"
                        className="nav-link-icon d-md-none d-lg-inline-block"
                      >
                        <MdFindInPage size={20} />
                      </span>
                      <span className="nav-link-title">Find Talent</span>
                    </a>
                    <div
                      onClick={() => {
                        document.getElementById("k-findtalent").click();
                        closeNavbar();
                      }}
                      className="dropdown-menu"
                    >
                      {/* {navItem.includes("Browse Top Candidate") && (
                        <NavLink
                          to={"/top-candidates"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Browse Top Candidate
                        </NavLink>
                      )} */}

                      {navItem.includes("Post Jobs") && (
                        <NavLink
                          to={"/create-jobs"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Post Jobs
                        </NavLink>
                      )}

                      {navItem.includes("Job Board") && (
                        <NavLink
                          to={"/job-board"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Job Board
                        </NavLink>
                      )}
                      {navItem.includes("My Scheduled Interview") && (
                        <NavLink
                          to={"/my-scheduled-interview"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          All Scheduled Interview
                        </NavLink>
                      )}
                    </div>
                  </li>
                )}
                {navItem.includes("Internal") && (
                  <li
                    className={
                      activeNavItem === "Internal"
                        ? "nav-item active dropdown"
                        : "nav-item dropdown"
                    }
                  >
                    <button
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span
                        id="k-internal"
                        className="nav-link-icon d-md-none d-lg-inline-block"
                      >
                        <BsPersonWorkspace size={18} />
                      </span>
                      <span className="nav-link-title">Internal</span>
                    </button>
                    <div
                      onClick={() => {
                        document.getElementById("k-internal").click();
                        closeNavbar();
                      }}
                      className="dropdown-menu"
                    >
                      {navItem.includes("Task Management") && (
                        <NavLink
                          to={"/task-management"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Task Management
                        </NavLink>
                      )}

                      {navItem.includes("Leave Management") && (
                        <NavLink
                          to={"/employee-leave-management"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          {" "}
                          Leave Management
                        </NavLink>
                      )}

                      {navItem.includes("Employee Management") && (
                        <NavLink
                          to={"/employee-management"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Employee Management
                        </NavLink>
                      )}

                      {navItem.includes("Employee Salary") && (
                        <NavLink
                          to={"/employee-salary"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Employee Salary
                        </NavLink>
                      )}
                      {navItem.includes("Attendance Management") && (
                        <NavLink
                          to={"/employee-attendance"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Attendance Management
                        </NavLink>
                      )}

{navItem.includes("Email Marketing") && (
                        <NavLink
                          to={"/email-marketing"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Email Marketing
                        </NavLink>
                      )}
                      {navItem.includes("Internal FAQ") && (
                        <NavLink
                          to={"/internal-FAQ"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Internal FAQ
                        </NavLink>
                      )}
                    </div>
                  </li>
                )}
                {navItem.includes("Create") && (
                  <li
                    className={
                      activeNavItem === "Create"
                        ? "nav-item active dropdown"
                        : "nav-item dropdown"
                    }
                  >
                    <button
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span
                        id="k-internal"
                        className="nav-link-icon d-md-none d-lg-inline-block"
                      >
                        <BsPersonWorkspace size={18} />
                      </span>
                      <span className="nav-link-title">Create</span>
                    </button>
                    <div
                      onClick={() => {
                        document.getElementById("k-internal").click();
                        closeNavbar();
                      }}
                      className="dropdown-menu"
                    >
                      {navItem.includes("Post Jobs") && (
                        <NavLink
                          to={"/create-jobs"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Post Jobs
                        </NavLink>
                      )}
                      {navItem.includes("Post Contest") && (
                        <NavLink
                          to={"/post-contest"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Post Contest
                        </NavLink>
                      )}
                    </div>
                  </li>
                )}
                {navItem.includes("Actions") && (
                  <li
                    className={
                      activeNavItem === "Actions"
                        ? "nav-item active dropdown"
                        : "nav-item dropdown"
                    }
                  >
                    <button
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span
                        id="k-internal"
                        className="nav-link-icon d-md-none d-lg-inline-block"
                      >
                        <BsPersonWorkspace size={18} />
                      </span>
                      <span className="nav-link-title">Actions</span>
                    </button>
                    <div
                      onClick={() => {
                        document.getElementById("k-internal").click();
                        closeNavbar();
                      }}
                      className="dropdown-menu"
                    >
                      {navItem.includes("Onboard Hiring Partner") && (
                        <NavLink
                          to={"/onboard-hiring-partner"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Onboard Hiring Partner
                        </NavLink>
                      )}

                      {navItem.includes("All Shortlist Request") && (
                        <NavLink
                          to={"/shortlist-request"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Shortlist Request
                        </NavLink>
                      )}
                    </div>
                  </li>
                )}
                {navItem.includes("Data") && (
                  <li
                    className={
                      activeNavItem === "Data"
                        ? "nav-item active dropdown"
                        : "nav-item dropdown"
                    }
                  >
                    <button
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span
                        id="k-internal"
                        className="nav-link-icon d-md-none d-lg-inline-block"
                      >
                        <BsPersonWorkspace size={18} />
                      </span>
                      <span className="nav-link-title">Data</span>
                    </button>
                    <div
                      onClick={() => {
                        document.getElementById("k-internal").click();
                        closeNavbar();
                      }}
                      className="dropdown-menu"
                    >
                      {navItem.includes("All Candidates") && (
                        <NavLink
                          to={"/list-allcandidates"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          All Candidates
                        </NavLink>
                      )}

                      {navItem.includes("All Onboarding Request") && (
                        <NavLink
                          to={"/onboarding-request"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          All Onboarding Request
                        </NavLink>
                      )}
                      {navItem.includes("Applicants") && (
                        <NavLink
                          to={"/jobs-list"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Jobs List
                        </NavLink>
                      )}

                      {navItem.includes("Booked Demo List") && (
                        <NavLink
                          to={"/book-demo-list"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Booked Demo List
                        </NavLink>
                      )}
                      {navItem.includes("Contact Us List") && (
                        <NavLink
                          to={"/contactus-list"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Contact Us List
                        </NavLink>
                      )}

                      {navItem.includes("All Payments") && (
                        <NavLink
                          to={"/all-payments"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          All Payments
                        </NavLink>
                      )}
                      {navItem.includes("All Companies") && (
                        <NavLink
                          to={"/listed-companies"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          List All Companies
                        </NavLink>
                      )}
                    </div>
                  </li>
                )}

                {store?.user?.Role === company && (
                  <li className="nav-item ">
                    <button
                      onClick={() => {
                        openInNewTab("/top-hires");
                      }}
                      className="nav-link btn btn-pill border"
                      role="button"
                      aria-expanded="false"
                    >
                      Looking for job
                      <span>
                        <GoArrowUpRight fontSize={"16px"} />
                      </span>
                    </button>
                  </li>
                )}
                {(!store.isLoggedIn || store?.user?.Role === candidate) && (
                  <li className="nav-item ">
                    <button
                      onClick={() => {
                        openInNewTab("/employer-dashboard");
                      }}
                      className="nav-link btn btn-pill border"
                      role="button"
                      aria-expanded="false"
                    >
                      Looking for hire
                      <span>
                        <GoArrowUpRight fontSize={"16px"} />
                      </span>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
