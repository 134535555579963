import React, { useState } from "react";
import { Formik, Form } from "formik";
import { createTemplateSchema } from "../../../Schemas/Email Marketing/email_marketing_schema";
import { Toast } from "../../CustomComponent/toast";
import CustomInput from "../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import performRequest from "../../../APIs/request";
import { useNavigate } from "react-router";
const defaultTemplate =`
  <body style="margin: 0; padding: 0; font-family: 'Arial', sans-serif; background-color: #f4f4f8;">
  
    <table style="width: 100%; background-color: #f4f4f8; padding: 20px;">
      <tr>
        <td align="center">
          <!-- Main Container -->
          <table style="width: 600px; max-width: 100%; background-color: #ffffff; border-radius: 15px; box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); overflow: hidden;">
         
            <tr>
              <td style="background: linear-gradient(135deg, #1e90ff, #6a5acd); padding: 50px 20px; text-align: center; color: #ffffff;">
                <h1 style="margin: 0; font-size: 32px;">🌟 Welcome to Our Community</h1>
                <p style="margin: 10px 0 0; font-size: 18px;">Your next big step starts here</p>
              </td>
            </tr>
         
            <tr>
              <td align="center" style="padding: 20px;">
                <img src="https://via.placeholder.com/500x250/1e90ff/ffffff?text=Welcome+to+Your+Journey" alt="Welcome Image" style="width: 100%; border-radius: 8px;">
              </td>
            </tr>
           
            <tr>
              <td style="padding: 30px; color: #333333; text-align: left;">
                <h2 style="margin: 0; font-size: 24px; color: #1e90ff;">Hello,{{RecipientName}}</h2>
                <p style="margin: 20px 0; font-size: 16px; line-height: 1.6;">
                  Thank you for joining us! We're thrilled to have you on board and can't wait to show you all the fantastic things we have in store for you.
                </p>
                <p style="margin: 20px 0; font-size: 16px; line-height: 1.6;">
                  From exclusive content to personalized tools, your journey with us promises to be inspiring and rewarding. Let’s make your goals a reality—starting today!
                </p>
                <p style="margin: 20px 0; font-size: 16px; line-height: 1.6;">Warm regards,<br><strong>The Awesome Team</strong></p>
              </td>
            </tr>
            <!-- Call-to-Action -->
            <tr>
              <td align="center" style="padding: 20px;">
                <a href="https://example.com" style="display: inline-block; padding: 15px 30px; font-size: 16px; color: #ffffff; background-color: #6a5acd; text-decoration: none; border-radius: 25px; font-weight: bold; box-shadow: 0 4px 8px rgba(106, 90, 205, 0.4);">Get Started Now</a>
              </td>
            </tr>
           
            <tr>
              <td style="padding: 30px; background-color: #f9f9fb;">
                <table style="width: 100%; text-align: center;">
                  <tr>
                    <td style="padding: 15px; width: 33%;">
                      <img src="https://via.placeholder.com/80/1e90ff/ffffff?text=🌟" alt="Feature 1" style="margin-bottom: 10px;">
                      <p style="margin: 0; font-size: 14px; color: #444;">Exclusive Tools</p>
                    </td>
                    <td style="padding: 15px; width: 33%;">
                      <img src="https://via.placeholder.com/80/1e90ff/ffffff?text=💡" alt="Feature 2" style="margin-bottom: 10px;">
                      <p style="margin: 0; font-size: 14px; color: #444;">Creative Ideas</p>
                    </td>
                    <td style="padding: 15px; width: 33%;">
                      <img src="https://via.placeholder.com/80/1e90ff/ffffff?text=🚀" alt="Feature 3" style="margin-bottom: 10px;">
                      <p style="margin: 0; font-size: 14px; color: #444;">Unlimited Growth</p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
           
            <tr>
              <td style="padding: 20px; background-color: #1e90ff; color: #ffffff; text-align: center;">
                <p style="margin: 0; font-size: 14px;">You're receiving this email because you're awesome! ❤️</p>
                <p style="margin: 10px 0 0; font-size: 14px;">&copy; 2024 The Awesome Team. All Rights Reserved.</p>
                <a href="#" style="text-decoration: none; color: #ffffff;">Unsubscribe</a>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  
  </body>
  `;
  
const Createemailtemplate = () => {
  const [htmlContent, setHtmlContent] = useState("");
  // const [cssContent, setCssContent] = useState("");
  const navigate = useNavigate();
  const handleHtmlChange = (e) => {
    setHtmlContent(e.target.value);
  };

  // const handleCssChange = (e) => {
  //   setCssContent(e.target.value);
  // };

  const createTemplate = (values, props) => {

    let d = document.getElementById("getData");
    const requestData = {
      TemplateContent: {
        Html: d.innerHTML, // Replace with actual HTML content if available
        Subject: values.subjectPart, // Replace with actual subject
        Text: "", // Replace with actual text content
      },
      TemplateName: values.templateName, // Using template name from Formik values
    };
 

    if (d) {
      performRequest("POST", `/bulkemail/create-ses-template/`, requestData)
        .then((res) => {
          if (res.status === 200) {
            Toast("success", res.data.message);
            navigate("/email-marketing");
          }
        })
        .catch((err) => {
          console.error(err);
          Toast("error", err.response.data.error.message);
        });
    }
  };



  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-body">
            <div className="card-header"></div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">TemplateOne</div>
                <Formik
                  initialValues={{ templateName: "", subjectPart: "" }}
                  validationSchema={createTemplateSchema}
                  onSubmit={createTemplate}
                  enableReinitialize
                >
                  {(props) => (
                    <Form>
                      <div className="modal-body">
                        <div className="card-body">
                          {/* Template Name */}
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3 required">
                              Enter Template Name:
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="templateName" type="text" />
                              {props.touched.templateName &&
                                props.errors.templateName && (
                                  <p className="text-danger">
                                    {props.errors.templateName}
                                  </p>
                                )}
                            </div>
                          </div>

                          {/* Subject */}
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3 required">
                              Enter Subject:
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="subjectPart" type="text" />
                              {props.touched.subjectPart &&
                                props.errors.subjectPart && (
                                  <p className="text-danger">
                                    {props.errors.subjectPart}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Live HTML and CSS Editor */}
                      <div className="row col-12">
                        <div className="col-12 badge text-start text-black bg-lime p-2 rounded">
                          <span>&#x2139;</span> Please Add
                          &#123;&#123;RecipientName&#125;&#125; for Dynamic User
                          Name write same as it is mention
                        </div>
                        <div className="container-fluid vh-100 d-flex p-3">
                          {/* Editor Area */}
                          <div className="col-6 d-flex flex-column gap-3">
                            <textarea
                              className="form-control flex-grow-1"
                              value={htmlContent? htmlContent : defaultTemplate}
                              onChange={handleHtmlChange}
                              placeholder="Write your HTML here..."
                            />
                            {/* <textarea
                              className="form-control flex-grow-1"
                              value={cssContent}
                              onChange={handleCssChange}
                              placeholder="Write your CSS here..."
                            /> */}
                          </div>

                          {/* Preview Area */}
                          <div
                            id="getData"
                            className="col-6 border rounded p-3 bg-light overflow-auto"
                          >
                            {/* <style>{cssContent}</style> */}
                         {htmlContent ? (
                             <div
                             dangerouslySetInnerHTML={{ __html: htmlContent }}
                           ></div>
                         ): (
                          <div
                          dangerouslySetInnerHTML={{ __html: defaultTemplate }}
                        ></div>
                         )}
                          </div>
                        </div>
                      </div>

                      {/* Footer with Submit Button */}
                      <div className="modal-footer">
                        <FormSubmitButton buttonText="Create Template" />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Createemailtemplate;
