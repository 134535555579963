import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import performRequest from "../../../APIs/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
import { useSelector } from "react-redux";
import { CustomErrorResponse } from "../../CustomComponent/custom_error_response";
import { FcMoneyTransfer } from "react-icons/fc";
import DynamicFilterSelect from "../../../Components/layouts/filter.select";
import getFilteredURL from "../../../Components/Functions/filters.getUrl";
import DynamicFilterInput from "../../../Components/layouts/filter.inpit";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { FcBusinessman } from "react-icons/fc";
import Employeesalarytracking from "./employee_salary_tracking";
import { recruiter, superAdmin } from "../../../Config/config";

const Employeesalary = () => {
  const store = useSelector((store) => store.userLogin);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const getPreviousMonth = () => {
    const now = new Date();
    now.setMonth(now.getMonth() - 1);
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
  };

  const [months, setMonth] = useState(getPreviousMonth());
  const navigate = useNavigate();
  const [attendanceData, setAttendanceData] = useState();
  const [allEmployeeData, setAllEmployeeData] = useState();
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmplyeeData] = useState();
  const [employeeUserId, setEmployeeUserId] = useState("");
  const [allEmployeeAttendance, setAllEmployeeAttendance] = useState();

  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    employeeUserId: employeeUserId,
    month: getPreviousMonth(),
  });

  useEffect(() => {
    function getCompanyEmployeeNames() {
      performRequest("GET", "/tasks/companyemployees/")
        .then((res) => setAllEmployeeData(res?.data))
        .catch((err) => CustomErrorResponse(err));
    }
    if (store?.user?.Role === superAdmin) {
      getCompanyEmployeeNames();
    }
  }, []);

  useEffect(() => {
    function getEmployeeByAdmin() {
      setLoading(true);
      performRequest(
        "GET",
        getFilteredURL(
          `/employeemanagement/getallemployeesalarybynameandmonth`,
          page,
          filters
        )
      )
        .then((res) => {
          if (res.status === 200) {
            setAttendanceData(res.data);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {
          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    }

    if (store?.user?.Role === superAdmin) {
      getEmployeeByAdmin();
    }
  }, [refresh, page]);

  useEffect(() => {
    function getEmployeeSalary() {
      setLoading(true);
      performRequest(
        "GET",
        getFilteredURL(
          `/employeemanagement/getemployeesalarybyid/${store?.user?.UserId}`,
          page,
          filters
        )
      )
        .then((res) => {
          if (res.status === 200) {
            setAttendanceData(res.data);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {
          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    }

    if (store?.user?.isEmployee === true || store?.user?.Role === recruiter) {
      getEmployeeSalary();
    }
  }, [refresh, page]);

  useEffect(() => {
    function getEmployeeSalary() {
      setLoading(true);
      performRequest(
        "GET",
        getFilteredURL(`/attendance/getallattendance/`, page, filters)
      )
        .then((res) => {
          if (res.status === 200) {
            setAllEmployeeAttendance(res.data.Attendance);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {
          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    }

    if (store?.user?.Role === superAdmin) {
      getEmployeeSalary();
    }
  }, [refresh, page]);

  useEffect(() => {
    if (attendanceData?.employeeData?.id && store?.user?.isEmployee === true) {
      performRequest(
        "GET",
        `/employeemanagement/getemployeemanagementbyid/${store?.user?.UserId}`
      )
        .then((res) => setEmplyeeData(res?.data))
        .catch((err) => CustomErrorResponse(err))
        .finally(() => setLoading(false));
    }
  }, [attendanceData]);

  useEffect(() => {
    if (filters.month) {
      setMonth(filters.month);
    }
  }, [filters]);

  const handleClick = () => {
    navigate("/employee-payslip", {
      state: {
        myProp: {
          ...attendanceData,
          employeeUserId: employeeUserId,
          month: months,
          employeeData: employeeData,
        },
      },
    });
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none mb-4">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcMoneyTransfer className="fs-2 me-1" /> Employee Salary
                  Management
                </h2>
                <h4 className="text-muted">
                  Calculate & check employee salary
                </h4>
              </div>
              <div className="col-md-4 text-md-end">
                <button className="btn btn-primary" onClick={handleClick}>
                  <FaFileInvoiceDollar className="me-1" /> Payslip
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-2">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>
                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none p-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3 className="m-1">Filter result </h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          {store?.user?.Role === superAdmin && (
                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Search employee:"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  setFilters((prevFilters) => ({
                                    ...prevFilters,
                                    employeeUserId: value,
                                  }));
                                  setEmployeeUserId(value);
                                }}
                                value={filters.employeeUserId}
                              >
                                <option value="">select all</option>
                                {allEmployeeData &&
                                  allEmployeeData.map((state, index) => (
                                    <option key={index} value={state?.id}>
                                      {state?.fullName}
                                    </option>
                                  ))}
                              </DynamicFilterSelect>
                            </div>
                          )}

                          <div className="col-12">
                            <DynamicFilterInput
                              label="Month (YYYY-MM):"
                              value={filters.month}
                              onChange={(e) => {
                                const { value } = e.target;
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  month: value,
                                }));
                                setMonth(value);
                              }}
                              type="month"
                              max={new Date().toISOString().slice(0, 7)}
                            />
                          </div>
                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setFilters({
                                      startdate: "",
                                      enddate: "",
                                      userName: "",
                                      month: "",
                                    });
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>

                        {store?.user?.Role === superAdmin && (
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search employee:"
                              onChange={(e) => {
                                const { value } = e.target;
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  employeeUserId: value,
                                }));
                                setEmployeeUserId(value);
                              }}
                              value={filters.employeeUserId}
                            >
                              <option value="">select all</option>
                              {allEmployeeData &&
                                allEmployeeData.map((state, index) => (
                                  <option key={index} value={state?.id}>
                                    {state?.fullName}
                                  </option>
                                ))}
                            </DynamicFilterSelect>
                          </div>
                        )}
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Month (YYYY-MM):"
                            value={filters.month}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFilters((prevFilters) => ({
                                ...prevFilters,
                                month: value,
                              }));
                              setMonth(value);
                            }}
                            type="month"
                            max={new Date().toISOString().slice(0, 7)}
                          />
                        </div>

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                              <button
                                onClick={() => setRefresh(!refresh)}
                                className="btn btn-success w-100"
                              >
                                Apply Filter
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setFilters({
                                    startdate: "",
                                    enddate: "",
                                    userName: "",
                                    month: "",
                                  });
                                  setRefresh(!refresh);
                                }}
                                className="btn btn-secondary w-100"
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-8 col-sm-12 mb-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  {attendanceData && (
                    <>
                      <h3 className="card-title mb-3">
                        <FcBusinessman className="me-1 fs-2" />
                        Employee Data
                      </h3>
                      <table className="table table-sm table-borderless">
                        <tbody>
                          <tr>
                            <td>Employee Name</td>
                            <td>
                              {attendanceData?.employeeData?.firstName}{" "}
                              {attendanceData?.employeeData?.lastName}
                            </td>
                          </tr>
                          <tr>
                            <td>Employee Id</td>
                            <td>{attendanceData?.employeeData?.id || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Salary per month</td>
                            <td>
                              {attendanceData?.employeeData?.salaryPerMonth ||
                                "N/A"}
                            </td>
                          </tr>

                          <tr>
                            <td>Per day salary</td>
                            <td>
                              {attendanceData?.perDaySalary?.toFixed(2) ||
                                "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Half day salary</td>
                            <td>
                              {attendanceData?.halfDaySalary?.toFixed(2) ||
                                "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Total Work hours</td>
                            <td>
                              {attendanceData?.totalWorkHr?.toFixed(2) || "N/A"}{" "}
                              hrs
                            </td>
                          </tr>
                          <tr>
                            <td>Total days in month</td>
                            <td>
                              {attendanceData?.monthTotalDays || "0"} days
                            </td>
                          </tr>
                          <tr>
                            <td>Total full days (exc. sundays)</td>
                            <td>
                              {attendanceData?.totalFullDaysWithoutSunday ||
                                "0"}{" "}
                              days
                            </td>
                          </tr>

                          <tr>
                            <td>Total half days (exc. sundays)</td>
                            <td>
                              {attendanceData?.totalHalfDaysWithoutSunday ||
                                "0"}{" "}
                              days
                            </td>
                          </tr>
                          <tr>
                            <td>Total working sundays days</td>
                            <td>{attendanceData?.workingSundays || "0"} </td>
                          </tr>
                          <tr>
                            <td>Total holidays in month</td>
                            <td>{attendanceData?.totalWorkHolidays || "0"} </td>
                          </tr>
                          <tr>
                            <td>Total leaves in month</td>
                            <td>
                              {attendanceData?.totalLeavsDays || "0"} leaves
                            </td>
                          </tr>
                          <tr>
                            <td>Total sundays in month</td>
                            <td>{attendanceData?.totalSundays || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Salary (inc. sundays)</td>
                            <td>
                              ₹
                              {attendanceData?.totalSalary?.toFixed(2) || "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Incentive</td>
                            <td>
                              {"+  ₹"}
                              {attendanceData?.incentiveEmployee?.toFixed(2) ||
                                0}
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td>PF/PT Deductions</td>
                            <td>
                              {"-  ₹"}
                              {attendanceData?.employeeData?.pfptDeduction ||
                                "0"}
                            </td>
                          </tr>

                          <tr>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Total Salary (inc. sundays & PF/PT)</td>
                            <td>
                              ₹
                              {(
                                (parseInt(attendanceData?.totalSalary) || 0) +
                                // +(parseInt(attendanceData?.incentiveEmployee) || 0) - Uncomment if you need this
                                -(
                                  parseInt(
                                    attendanceData?.employeeData?.pfptDeduction
                                  ) || 0
                                )
                              ).toFixed(2) || "0"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>

              {attendanceData && filters.employeeUserId && (
                <div className="card shadow-sm mt-4">
                  <Employeesalarytracking
                    allEmployeeData={allEmployeeData}
                    attendanceData={
                      store?.user.Role === superAdmin
                        ? attendanceData?.calculatedData
                        : attendanceData?.calculatedData
                    }
                    fullName={`${attendanceData?.employeeData?.firstName} ${attendanceData?.employeeData?.lastName}`}
                  />
                </div>
              )}
              {attendanceData && filters.employeeUserId === "" && (
                <div className="card shadow-sm mt-4">
                  <Employeesalarytracking
                    allEmployeeData={allEmployeeData}
                    attendanceData={
                      store?.user.Role === superAdmin
                        ? allEmployeeAttendance
                        : attendanceData?.calculatedData
                    }
                    fullName={`${attendanceData?.employeeData?.firstName} ${attendanceData?.employeeData?.lastName}`}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Employeesalary;
