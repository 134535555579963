import React, { useEffect, useState } from "react";
import {
  FaBolt,
  FaMapMarker,
  FaMoneyBill,
  FaArrowAltCircleRight,
} from "react-icons/fa";
import { recruiter } from "../../../Config/config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import AccountLogin from "../../User/account-login";
import AccountSignup from "../../User/account-signup";
const JobsList = ({ jobData = [] }) => {
  const store = useSelector((store) => store?.userLogin);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const handelSkillsNavigate = (id) => {
    if (id) {
      navigate(`/get-skill-badges/${id}`);
    }
  };
  const [count, setCount] = useState(15);
  const [isPopUp, setIspopup] = useState(true);

  const handleLogin = () => {
    let btn = document.getElementById("modal-button");
    btn.click();
  };

  const handleModalClose = () => {
    let btn = document.getElementById("btn-close");
    btn.click();
    setIspopup(true);
  };
  return (
    <>
      {jobData?.map((comp, i) => {
        if (i < count) {
          return (
            <div
              key={i}
              className="card card-link card-link-pop bg-secondary-lt m-3 p-3"
            >
              {comp?.usermodel?.userRole === recruiter && (
                <div className="ribbon bg-red">
                  <FaBolt fontSize={"1em"} className="me-2" />
                  URGENT HIRING
                </div>
              )}

              <div className="row">
                <div className="col-lg-3 col-sm-12 col-md-3 text-center">
                  <div className="card">
                    <img src={comp.employeronboardingmodel.companyLogo} />
                  </div>
                  <div className="card bg-primary-lt mt-1">
                    {/* <p className="m-0 p-0">SKILL SCORE</p>
                    <h3 className="m-0 p-0">90%</h3> */}
                    <p className="m-0 p-0">Monthly Salary</p>
                    <h3 className="m-0 p-0">
                      ₹{comp?.salaryPerMonthMin} - ₹{comp?.salaryPerMonthMax}
                    </h3>
                  </div>
                </div>
                <div className="col-9">
                  <h3 className="m-0 p-0">{comp?.jobRole}</h3>
                  <p className="m-0 p-0">
                    at <strong>{comp?.companyName}</strong> in
                    <strong> {comp?.jobDepartment}</strong> department.
                  </p>
                  <span className="badge bg-cyan-lt me-1">POSITIONS: 1</span>

                  {comp?.additionalPerks?.map((data, i) => {
                    return (
                      <span
                        key={i}
                        className="badge badge-outline text-teal me-1 mt-1"
                      >
                        {data}
                      </span>
                    );
                  })}

                  <div className="row my-2">
                    <div className="col-12">
                      <p className="m-0 p-0">Required Skills:</p>
                      {comp?.skillsDetails?.map((data, i) => {
                        const skillIdentifier = data?.skillId || data?.skill;
                        return (
                          <div
                            onClick={() => handelSkillsNavigate(data?.id)}
                            title={data.skill}
                            data-bs-toggle="tooltip"
                            key={i}
                            className="avatar avatar-md me-1 cursor-pointer"
                            style={{ backgroundImage: `url(${data?.icon})` }}
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                  <hr className="m-0 p-0" />
                  <div className="row mt-2">
                    <div className="col-9">
                      <p className="text-secondary m-0 p-0">
                        <FaMapMarker fontSize={"1em"} className="me-2" />
                        {comp?.jobLocation}, {comp?.jobCity}, {comp?.jobState}
                      </p>
                      {/* <p className="text-secondary m-0 p-0">
                        <FaMoneyBill fontSize={"1em"} className="me-2" />₹
                        {comp?.salaryPerMonthMin} - ₹{comp?.salaryPerMonthMax}
                      </p> */}
                    </div>
                    <div className="col-3 text-end">
                      {
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            if (store?.isLoggedIn) {
                              openInNewTab(`/view-jobs/${comp.id}`);
                            } else {
                              handleLogin();
                            }
                          }}
                        >
                          {/* <FaArrowAltCircleRight
                            fontSize={"2em"}
                            className="me-2"
                          />{" "} */}
                          Apply Now
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
      {jobData.length > count && (
        <div className="text-center">
          <button
            onClick={() => setCount(count + 15)}
            className="btn btn-primary w-33"
          >
            Load More
          </button>
        </div>
      )}
      {/* modal */}
      <button
        className="btn btn-tertiory ms-2  mt-lg-2 mt-sm-2 mt-md-1 me-2 d-none"
        data-bs-toggle="modal"
        data-bs-target="#modal-team"
        type="button"
        id="modal-button"
        onClick={() => setIspopup(true)}
      ></button>

      <div
        className="modal modal-blur fade"
        id="modal-team"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Login/Register</h5>
              <button
                type="button"
                id={"btn-close"}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isPopUp ? (
              <AccountLogin
                handleModalClose={handleModalClose}
                isPopUp={isPopUp}
                setIspopup={setIspopup}
              />
            ) : (
              <AccountSignup
                handleModalClose={handleModalClose}
                isPopUp={isPopUp}
                info={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobsList;
