import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { createTemplateSchema } from "../../../Schemas/Email Marketing/email_marketing_schema";
import { Toast } from "../../CustomComponent/toast";
import CustomInput from "../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import performRequest from "../../../APIs/request";
import { useNavigate, useParams } from "react-router";
const Editemailtemplate = () => {

  const [htmlContent, setHtmlContent] = useState("");
  // const [cssContent, setCssContent] = useState("");
  const [initialValues, setInitialValues] = useState({ templateName: "", subjectPart: "" })
  const {id} = useParams()
  const navigate = useNavigate()
  const handleHtmlChange = (e) => {
    setHtmlContent(e.target.value);
  };

  // const handleCssChange = (e) => {
  //   setCssContent(e.target.value);
  // };

useEffect(()=>{
  performRequest("GET", `/bulkemail/get-email-template/${id}`)
  .then((res) => {
    console.log(res, "ths");
    if (res.status === 200) {
      console.log(res.data.Template, "fsdf")
      setInitialValues({
        templateName: res.data.Template.TemplateName || "",
        subjectPart: res.data.Template.SubjectPart || "",
      });
      setHtmlContent(res.data.Template.HtmlPart)
    }
  })
  .catch((err) => {
    console.error(err);
    Toast("error", err.response.data.error.statusText);
  });
},[])


  const EditTemplate = (values, props) => {
    let d = document.getElementById("getData");
    const requestData = {
      TemplateContent: {
        Html: d.innerHTML, // Replace with actual HTML content if available
        Subject: values.subjectPart, // Replace with actual subject
        Text: "", // Replace with actual text content
      },
      TemplateName: values.templateName, // Using template name from Formik values
    };

    if (d) {
      performRequest("PATCH", `/bulkemail/update-email-template/`, requestData)
        .then((res) => {
          console.log(res, "ths");
          if (res.status === 200) {
            Toast("success", res.data.message);
            navigate('/email-marketing')
          }
        })
        .catch((err) => {
          console.error(err);
          Toast("error", err.response.data.error.message);
        });
    }
  };



  return (
    <div className="page">
    <div className="page-wrapper">
      <div className="container-xl">
        <div className="page-body">
          <div className="card-header"></div>
          <div className="card">
            <div className="card-body">
              <div className="card-title">TemplateOne</div>
              <Formik
                initialValues={initialValues}
                validationSchema={createTemplateSchema}
                onSubmit={EditTemplate}
                enableReinitialize
              >
                {(props) => (
                  <Form>
                    <div className="modal-body">
                      <div className="card-body">
                        {/* Template Name */}
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Enter Template Name:
                          </label>
                          <div className="w-100 col">
                            <CustomInput  disabled name="templateName" type="text" />
                            {props.touched.templateName &&
                              props.errors.templateName && (
                                <p className="text-danger">
                                  {props.errors.templateName}
                                </p>
                              )}
                          </div>
                        </div>

                        {/* Subject */}
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Enter Subject:
                          </label>
                          <div className="w-100 col">
                            <CustomInput disabled name="subjectPart" type="text" />
                            {props.touched.subjectPart &&
                              props.errors.subjectPart && (
                                <p className="text-danger">
                                  {props.errors.subjectPart}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Live HTML and CSS Editor */}
                    <div className="row col-12">
                    <div class="col-12 badge text-start text-black bg-lime p-2 rounded">
  <span>&#x2139;</span> Please Add &#123;&#123;RecipientName&#125;&#125; for Dynamic User Name
</div>

                      <div className="container-fluid vh-100 d-flex p-3">
                        {/* Editor Area */}
                        <div className="col-6 d-flex flex-column gap-3">
                          <textarea
                            className="form-control flex-grow-1"
                            value={htmlContent}
                            onChange={handleHtmlChange}
                            placeholder="Write your HTML here..."
                          />
                          {/* <textarea
                            className="form-control flex-grow-1"
                            value={cssContent}
                            onChange={handleCssChange}
                            placeholder="Write your CSS here..."
                          /> */}
                        </div>

                        {/* Preview Area */}
                        <div
                          id="getData"
                          className="col-6 border rounded p-3 bg-light overflow-auto"
                        >
                          {/* <style>{cssContent}</style> */}
                          <div
                            dangerouslySetInnerHTML={{ __html: htmlContent }}
                          ></div>
                        </div>
                      </div>
                    </div>

                    {/* Footer with Submit Button */}
                    <div className="modal-footer">
                      <FormSubmitButton buttonText="Edit Template" />
                    </div>
                  </Form>
                )}
              </Formik>
              {/* madal opening */}
            </div>
            {/* <a href="#" class="btn" data-bs-toggle="modal" data-bs-target="#modal-small">
                    Small modal
                  </a>
                  
        <div class="modal modal-blur fade" id="modal-small" tabindex="-1" style={{display: "none"}} aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-title">Confirm </div>
            <div><span>&#x2139;</span> Please Add &#123;&#123;RecipientName&#125;&#125;  for Dynamic User Name , If Added Proceed further</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link link-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-success" data-bs-dismiss="modal">Proceed To Confirm</button>
          </div>
        </div>
      </div>
    </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Editemailtemplate;
