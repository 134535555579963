import React from "react";
import Footer from "../../Components/Footer/footer";
import CustomInput from "../CustomComponent/custom_input";

import { Form, Formik } from "formik";
import { ContactUsInfo } from "../../Schema Info/info";
import { ContactUsSchema } from "../../Schemas/Demo/contact_us_schema";
import CustomTextArea from "../CustomComponent/custom_textarea";
import CustomSelect from "../CustomComponent/custom_select";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import performRequest from "../../APIs/request";

const ContactUs = () => {
  const handelSubmit = (values) => {
    performRequest("POST", "bookdemo/createbookdemo", values)
      .then((res) => {
        if (res.status === 201) {
          alert("Your request taken successfully!");
        }
      })
      .catch((err) => console.error(err));
  };
  return (
    <>
      <div className="page-body">
        <div className="container-xxl">
          <div className="card card-lg">
            <div className="card-body ">
              <div className="row g-4">
                <div className="col-6">
                  <h1>Contact Us</h1>
                  <p>You Can Also Visit Our Office At</p>
                  <p>
                    M319, City Avenue, Opp. Pheonix-Mall of Millanium, Bangalore
                    Pune Highway, Wakad, Pune, Pimpri-Chinchwad, Maharashtra
                    411057
                  </p>
                  <hr />
                  <p>
                    Reach Us For Corporate Queries
                    <br />
                    corporatecare@hire48.com
                  </p>
                  <p>
                    For Job Seeker Queries
                    <br />
                    jobseeker@hire48.com
                  </p>
                  <p>
                    For Grievances
                    <br />
                    grievance@timesjobs.com
                  </p>

                  <hr />
                </div>
                <div className="col-6">
                  <div className="bg-primary-lt p-5">
                    <Formik
                      initialValues={ContactUsInfo}
                      validationSchema={ContactUsSchema}
                      onSubmit={handelSubmit}
                    >
                      {(props) => (
                        <Form>
                          <div className="modal-body bg-primary-lt ">
                            <div className="mb-3">
                              <label className="form-label required">
                                Contact As
                              </label>
                              <CustomSelect name="contactAs" type={"text"}>
                                <option value="">Select</option>
                                <option value="Employer">Employer</option>
                                <option value="Employee">Employee</option>
                              </CustomSelect>
                              {props.touched.contactAs &&
                                props.errors.contactAs && (
                                  <p className="text-danger">
                                    {props.errors.contactAs}
                                  </p>
                                )}
                            </div>
                            <div className="mb-3">
                              <label className="form-label required">
                                Full Name
                              </label>
                              <CustomInput name="fullName" type={"text"} />
                              {props.touched.fullName &&
                                props.errors.fullName && (
                                  <p className="text-danger">
                                    {props.errors.fullName}
                                  </p>
                                )}
                            </div>
                            <div className="mb-3">
                              <label className="form-label required">
                                Email
                              </label>
                              <CustomInput name="email" type={"email"} />
                              {props.touched.email && props.errors.email && (
                                <p className="text-danger">
                                  {props.errors.email}
                                </p>
                              )}
                            </div>

                            <div className="mb-3">
                              <label className="form-label required">
                                Contact Number
                              </label>
                              <CustomInput name="contactNumber" type={"text"} />
                              {props.touched.contactNumber &&
                                props.errors.contactNumber && (
                                  <p className="text-danger">
                                    {props.errors.contactNumber}
                                  </p>
                                )}
                            </div>
                            <div className="mb-3">
                              <label className="form-label required">
                                Message
                              </label>
                              <CustomTextArea
                                name="message"
                                type={"text"}
                                rows={8}
                              />
                              {props.touched.message &&
                                props.errors.message && (
                                  <p className="text-danger">
                                    {props.errors.message}
                                  </p>
                                )}
                            </div>
                          </div>
                          <FormSubmitButton buttonText={"Submit"} />
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContactUs;
