import React from "react";
import Footer from "../../Components/Footer/footer";
import CommingSoon from "../../Components/layouts/comming.soon";

const TermAndConditions = () => {
  return (
    <>
      <div className="page-body">
        <div className="container-xl">
          <div className="card card-lg">
            <div className="card-body ">
              <div className="row g-4">
                <div className="col-12 markdown">
                  <h1>Terms & Conditions</h1>
                  <h2>For Employee / Candidates / General Users</h2>
                  <h3>Usage of HIRE48</h3>
                  <p>
                    The HIRE48 Platform (including any mobile based
                    applications, website and web applications) is provided by
                    HIRE48 Inc. (&ldquo;HIRE48 Inc.&rdquo;) either directly or
                    through its affiliates including but not limited to Kryzen
                    Technologies Private Limited (&ldquo;HIRE48 &rdquo;). HIRE48
                    Inc. and HIRE48 India are collectively referred to as
                    &quot;HIRE48&quot;. Through the HIRE48 Platform any person
                    with a verified account can view and apply for jobs
                    (&ldquo;User&rdquo;) through the HIRE48 Platform, access and
                    participate in the services provided by HIRE48. Jobs are
                    posted by independent third parties not related to or
                    affiliated with HIRE48.
                  </p>
                  <p>
                    A User accessing the HIRE48 Platform shall be bound by these
                    Terms of Service, and all other rules, regulations and terms
                    of use referred to herein or provided by HIRE48 in relation
                    to any services provided via the HIRE48 Platform
                    (&ldquo;HIRE48 Services&rdquo;).
                  </p>
                  <p>
                    HIRE48 shall be entitled to modify these Terms of Service,
                    rules, regulations and terms of use referred to herein or
                    provided by HIRE48 in relation to any HIRE48 Services, at
                    any time, by posting the same on the HIRE48 Platform. Use of
                    the HIRE48 Platform and HIRE48 Services constitutes the
                    User's acceptance of such modified Terms of Service, rules,
                    regulations and terms of use referred to herein or provided
                    by HIRE48 in relation to any HIRE48 Services, as may be
                    amended from time to time. HIRE48 may, at its sole
                    discretion, also notify the User of any change or
                    modification in these Terms of Service, rules, regulations
                    and terms of use referred to herein or provided by HIRE48,
                    by way of sending an email to the User's registered email
                    address or posting notifications in the User accounts or
                    through any other mode of communication. The User may then
                    exercise the options provided in such an email or
                    notification to indicate non-acceptance of the modified
                    Terms of Service, rules, regulations and terms of use
                    referred to herein or provided by HIRE48. If such options
                    are not exercised by the User within the time frame
                    prescribed in the email or notification, the User will be
                    deemed to have accepted the modified Terms of Service,
                    rules, regulations and terms of use referred to herein or
                    provided by HIRE48.
                  </p>
                  <p>
                    Certain HIRE48 Services being provided on HIRE48 may be
                    subject to additional rules and regulations set down in that
                    respect. To the extent that these Terms of Service are
                    inconsistent with the additional conditions set down, the
                    additional conditions shall prevail.
                  </p>
                  <p>HIRE48 may, at its sole and absolute discretion:</p>
                  <ol>
                    <li>
                      Restrict, suspend, or terminate any User’s access to all
                      or any part of the HIRE48 Platform or HIRE48 Services;
                    </li>
                    <li>
                      {" "}
                      Change, suspend, or discontinue all or any part of the
                      HIRE48 Platform or HIRE48 Services;
                    </li>
                    <li>
                      Reject, move, or remove any material that may be submitted
                      by a User;
                    </li>
                    <li>
                      Move or remove any content that is available on the HIRE48
                      Platform;
                    </li>
                    <li>
                      Deactivate or delete a User’s account and all related
                      information and files on the account;
                    </li>
                    <li>
                      Establish general practices and limits concerning use of
                      HIRE48 Platform;
                    </li>
                    <li>
                      Assign its rights and liabilities to all User accounts
                      hereunder to any entity (post such assignment intimation
                      of such assignment shall be sent to all Users to their
                      registered email ids).
                    </li>
                  </ol>
                  <p>
                    In the event any User breaches, or HIRE48 reasonably
                    believes that such User has breached these Terms of Service,
                    or has illegally or improperly used the HIRE48 Platform or
                    HIRE48 Services, HIRE48 may, at its sole and absolute
                    discretion, and without any notice to the User, restrict,
                    suspend or terminate such User's access to all or any part
                    of the HIRE48 Platform, deactivate or delete the User's
                    account and all related information on the account, delete
                    any content posted by the User on HIRE48 and further, take
                    technical and legal steps as it deems necessary.
                  </p>
                  <p>
                    If HIRE48 charges its Users a platform fee in advance in
                    respect of any HIRE48 Services, HIRE48 shall, without delay,
                    repay such platform fee in the event of suspension or
                    removal of the User's account or HIRE48 Services on account
                    of any negligence or deficiency on the part of HIRE48, but
                    not if such suspension or removal is effected due to:
                  </p>
                  <p>
                    {" "}
                    any breach or inadequate performance by the User of any of
                    these Terms of Service; or any circumstances beyond the
                    reasonable control of HIRE48.
                  </p>
                  <p>
                    By accepting these Terms of Service Users are providing
                    their consent to receiving communications such as
                    announcements, administrative messages and advertisements
                    from HIRE48 or any of its partners, licensors or associates.
                  </p>
                  <h3>Participation:</h3>
                  <p>
                    When accessing and interacting with the HIRE48 Platform and
                    HIRE48 Services a User will be able to view and apply for
                    jobs posted by potential employers for their respective
                    organizations.
                  </p>
                  <p>
                    {" "}
                    To view and apply for a job a User shall be required to
                    provide information about the User&rsquo;s education,
                    qualifications, past experience and skills. While HIRE48
                    does not tolerate or allow for discrimination on the basis
                    of gender, certain jobs might be gender specific and might
                    be available only to persons of a certain gender. The User
                    understands and acknowledges that such stipulations as to
                    gender specifications for a certain job are not mandated by
                    HIRE48 and that such stipulation is made by the job poster.
                  </p>
                  <p>
                    {" "}
                    By agreeing to these Terms of Service and while applying for
                    a job through the HIRE48 Platform, Users undertake that all
                    information shared will at all times be accurate and not be
                    misleading. The User understands and acknowledges that any
                    incorrect information or misrepresentations made by the User
                    will affect the efficacy of the HIRE48 Platform and HIRE48
                    Services and that HIRE48 shall have the right to suspend the
                    User&rsquo;s account if it is found that the information
                    shared by the User is false or misleading.
                  </p>
                  <p>
                    {" "}
                    The job applications by the Users on the HIRE48 Platform
                    shall remain active only for a period of 60 (sixty) days
                    from the date of application to the job posts and upon the
                    expiry of the said period of 60 (sixty) days, such job
                    applications shall be archived (&quot;Archived Job
                    Applications&quot;). The potential employers shall not have
                    access to the list of such Users or to the Archived Job
                    Applications upon the expiry of 60 (sixty) days. The Users,
                    however, may re-apply to a job post (if still active) after
                    the expiry of 60 (sixty) days from the date such User made
                    their first application to the same job post.
                  </p>
                  <p>
                    {" "}
                    Users agree that they shall at all times be bound by and
                    adhere to theCode of Conductwhile accessing the HIRE48
                    Platform and while using the HIRE48 Services.
                  </p>
                  <h3>Intellectual Property</h3>
                  <p>
                    The intellectual property rights(&quot;Intellectual Property
                    Rights&quot;) in all software underlying the HIRE48 Platform
                    and the HIRE48 Services and material published on the HIRE48
                    Platform, including (but not limited to) software,
                    advertisements, content (whether written, audio and/or
                    visual), photographs, graphics, images, illustrations,
                    graphs, charts, marks, logos, audio or video clippings,
                    animations etc. is owned by HIRE48, its affiliates,
                    partners, licensors and/or associates. Users may not modify,
                    publish, transmit, participate in the transfer or sale of,
                    reproduce, create derivative works of, distribute, publicly
                    perform, publicly display, or in any way exploit any of the
                    materials or content on HIRE48 either in whole or in part
                    without express written license from HIRE48
                  </p>
                  <p>
                    {" "}
                    Users are solely responsible for all materials (whether
                    publicly posted or privately transmitted) that they upload,
                    post, e-mail, transmit, or otherwise make available via the
                    HIRE48 Platform (&quot;User&rsquo;s Content&quot;). Each
                    User represents and warrants that they own all Intellectual
                    Property Rights in the User&rsquo;s Content and that no part
                    of the User's Content infringes any third-party rights.
                    Users further confirm and undertake to not display or use of
                    the names, logos, marks, labels, trademarks, copyrights or
                    intellectual and proprietary rights of any third party on
                    the HIRE48 Platform, without written authorization from such
                    third party. Users agree to indemnify and hold harmless
                    HIRE48, its directors, employees, affiliates and assigns
                    against all costs, damages, loss and harm including towards
                    litigation costs and counsel fees, in respect of any third
                    party claims that may be initiated including for
                    infringement of Intellectual Property Rights arising out of
                    such display or use of the names, logos, marks, labels,
                    trademarks, copyrights or intellectual and proprietary
                    rights on the HIRE48 Platform, by such User or through the
                    User's commissions or omissions
                  </p>
                  <p>
                    {" "}
                    Users hereby grant to HIRE48 and its affiliates, partners,
                    licensors and associates a worldwide, irrevocable,
                    royalty-free, non-exclusive, sub-licensable license to use,
                    reproduce, create derivative works of, distribute, publicly
                    perform, publicly display, transfer, transmit, and/or
                    publish User's Content for any of the following purposes:
                    displaying User&rsquo;s Content on HIRE48 distributing
                    User&rsquo;s Content, either electronically or via other
                    media, to potential candidates, and/or storing User&rsquo;s
                    Content in a remote database accessible by end users, for a
                    charge. This license shall apply to the distribution and the
                    storage of User&rsquo;s Content in any form, medium, or
                    technology.
                  </p>
                  <p>
                    {" "}
                    All names, logos, marks, labels, trademarks, copyrights or
                    intellectual and proprietary rights on the HIRE48 Platform
                    belonging to any person (including a User), entity or third
                    party are recognized as proprietary to the respective owners
                    and any claims, controversy or issues against these names,
                    logos, marks, labels, trademarks, copyrights or intellectual
                    and proprietary rights must be directly addressed to the
                    respective parties under notice to HIRE48.
                  </p>
                  <h3>Third Party Sites, Services and Products</h3>
                  <p>
                    Links to other Internet sites or mobile applications owned
                    and operated by third parties may be provided via the HIRE48
                    Platform. The User’s use of each of those sites is subject
                    to the conditions, if any, posted by those sites. HIRE48
                    does not exercise control over any Internet sites or mobile
                    applications apart from the HIRE48 Platform and cannot be
                    held responsible for any content residing in any third-party
                    Internet site or mobile application. HIRE48's inclusion of
                    third-party content or links to third-party Internet sites
                    or mobile applications is not an endorsement by HIRE48 of
                    such third-party Internet site or mobile application.
                  </p>
                  <p>
                    A User’s correspondence, transactions/offers or related
                    activities with third parties including but not limited to
                    potential employers, payment providers and verification
                    service providers, are solely between the User and that
                    third party. A User’s correspondence, transactions and usage
                    of the services/offers of such third party shall be subject
                    to the terms and conditions, policies and other service
                    terms adopted/implemented by such third party, and the User
                    shall be solely responsible for reviewing the same prior to
                    transacting or availing of the services/offers of such third
                    party. The User agrees that HIRE48 will not be responsible
                    or liable for any loss or damage of any sort incurred as a
                    result of any such transactions/offers with third parties.
                    Any questions, complaints, or claims related to any
                    third-party product or service should be directed to the
                    appropriate vendor.
                  </p>
                  <p>
                    The HIRE48 Platform contains content that is created by
                    HIRE48 as well as content provided by third parties
                    (including potential candidates). HIRE48 does not guarantee
                    the accuracy, integrity, quality of the content provided by
                    third parties and such content may not relied upon by the
                    Users in utilizing the HIRE48 Services provided on the
                    HIRE48 Platform.
                  </p>
                  <h3>Privacy Policy</h3>
                  <p>
                    All information collected from Users, such as registration
                    (including but not limited to email addresses, mobile phone
                    numbers, government identity documentation) and payment
                    information, is subject to HIRE48's Privacy Policy which is
                    available atPrivacy Policy.
                  </p>

                  <p>
                    {" "}
                    We do not share personal information of any individual with
                    other companies/entities without obtaining permission. We
                    may share all such information that we have in our
                    possession in accordance with our Privacy Policy
                  </p>

                  <p>
                    {" "}
                    Once the personal information has been shared with you, you
                    shall, at all times, be responsible to secure such
                    information.
                  </p>

                  <p>
                    {" "}
                    You warrant and represent that you shall not disclose or
                    transfer personal information shared by us to any
                    sub-processors without ensuring that adequate and equivalent
                    safeguards to the personal information.
                  </p>

                  <p>
                    {" "}
                    You, hereby agree and acknowledge that you will use the
                    information shared with you only for the purpose of availing
                    the Services. You shall not use such information for any
                    personal or other business purposes. In the event you are
                    found to be misusing the information shared with you, we
                    shall, at our sole discretion, delete your account with
                    immediate effect and you will be blocked from using/
                    accessing HIRE48 Platform in future.
                  </p>
                  <h3>User Conduct</h3>
                  <p>
                    Users agree to abide by these Terms of Service and all other
                    rules, regulations and terms of use of the HIRE48 Platform
                    and HIRE48 Services. In the event User does not abide by
                    these Terms of Service and all other rules, regulations and
                    terms of use, HIRE48 may, at its sole and absolute
                    discretion, take necessary remedial action, including but
                    not limited to:
                  </p>
                  <p>
                    {" "}
                    restricting, suspending, or terminating any User's access to
                    all or any part of the HIRE48 Platform and HIRE48 Services;
                  </p>
                  <p>
                    deactivating or deleting a User's account and all related
                    information and files on the account. Any amount remaining
                    unused in the User's account on the date of deactivation or
                    deletion shall be transferred to the User's bank account on
                    record with HIRE48 subject to a processing fee (if any)
                    applicable on such transfers as set out herein.
                  </p>
                  <p>
                    Users agree to provide true, accurate, current and complete
                    information at the time of registration and at all other
                    times (as required by HIRE48). Users further agree to update
                    and keep updated their registration information and other
                    information as may be required by HIRE48.
                  </p>

                  <p>
                    A User shall not register or operate more than one User
                    account with HIRE48.
                  </p>

                  <p>
                    Users agree to ensure that they can receive all
                    communication from HIRE48 either by email, SMS, Whatsapp or
                    any other mode of communication from HIRE48. HIRE48 shall
                    not be held liable if any any communication sent to the User
                    by HIRE48 remains unread by the User.
                  </p>

                  <p>
                    Any password issued by HIRE48 to a User may not be revealed
                    to anyone else. Users may not use anyone else's password.
                    Users are responsible for maintaining the confidentiality of
                    their accounts and passwords. Users agree to immediately
                    notify HIRE48 of any unauthorized use of their passwords or
                    accounts or any other breach of security.
                  </p>

                  <p>
                    Users agree to exit/log-out of their accounts at the end of
                    each session. HIRE48 shall not be responsible for any loss
                    or damage that may result if the User fails to comply with
                    these requirements.
                  </p>

                  <p>
                    Users agree not to use cheats, exploits, automation,
                    software, bots, hacks or any unauthorized third-party
                    software designed to modify or interfere with the HIRE48
                    Services and/or HIRE48 experience or assist in such
                    activity.
                  </p>

                  <p>
                    Users agree not to copy, modify, rent, lease, loan, sell,
                    assign, distribute, reverse engineer, grant a security
                    interest in, or otherwise transfer any right to the
                    technology or software underlying the HIRE48 Platform or
                    HIRE48&rsquo;s Services.
                  </p>

                  <p>
                    Users agree that without HIRE48's express written consent,
                    they shall not modify or cause to be modified any files or
                    software that are part of HIRE48's Services or the HIRE48
                    Platform.
                  </p>

                  <p>
                    Users agree not to disrupt, overburden, or aid or assist in
                    the disruption or overburdening of (a) any computer or
                    server used to offer or support the HIRE48 Platform or
                    HIRE48&rsquo;s Services (each a &quot;Server&quot;); or (2)
                    the enjoyment of HIRE48 Services by any other User or
                    person.
                  </p>

                  <p>
                    Users agree not to institute, assist or become involved in
                    any type of attack, including without limitation to
                    distribution of a virus, denial of service, or other
                    attempts to disrupt HIRE48 Services or any other person's
                    use or enjoyment of HIRE48 Services.
                  </p>

                  <p>
                    Users shall not attempt to gain unauthorised access to User
                    accounts, Servers or networks connected to the HIRE48
                    Platform or HIRE48 Services by any means other than the User
                    interface provided by HIRE48, including but not limited to,
                    by circumventing or modifying, attempting to circumvent or
                    modify, or encouraging or assisting any other person to
                    circumvent or modify, any security, technology, device, or
                    software that underlies or is part of the HIRE48 Platform or
                    HIRE48 Services.
                  </p>

                  <p>
                    A User shall not publish any content that is patently false
                    and untrue, and is written or published in any form, with
                    the intent to mislead or harass a person, entity or agency
                    for financial gain or to cause any injury to any person.
                  </p>
                  <p>
                    Without limiting the foregoing, Users agree not to use
                    HIRE48 for any of the following:
                  </p>
                  <ol>
                    <li>
                      To engage in any obscene, offensive, indecent, racial,
                      communal, anti-national, objectionable, defamatory or
                      abusive action or communication;
                    </li>
                    <li>
                      To harass, stalk, threaten, or otherwise violate any legal
                      rights of other individuals;
                    </li>
                    <li>
                      {" "}
                      To publish, post, upload, e-mail, distribute, or
                      disseminate (collectively, "Transmit") any inappropriate,
                      profane, defamatory, infringing, obscene, indecent, or
                      unlawful content;
                    </li>
                    <li>
                      To Transmit files that contain viruses, corrupted files,
                      or any other similar software or programs that may damage
                      or adversely affect the operation of another person's
                      computer, HIRE48, any software, hardware, or
                      telecommunications equipment;
                    </li>
                    <li>
                      To advertise, offer or sell any goods or services for any
                      commercial purpose on HIRE48 including but not limited to
                      multi-level marketing for a third party, promoting
                      business of a third party, selling financial products such
                      as loans, insurance, promoting demat account openings,
                      without the express written consent of HIRE48;
                    </li>
                    <li>
                      {" "}
                      To download any file, recompile or disassemble or
                      otherwise affect our products that you know or reasonably
                      should know cannot be legally obtained in such manner;
                    </li>
                    <li>
                      To falsify or delete any author attributions, legal or
                      other proper notices or proprietary designations or labels
                      of the origin or the source of software or other material;
                    </li>
                    <li>
                      To restrict or inhibit any other User from using and
                      enjoying any public area within our sites;
                    </li>
                    <li>
                      To collect or store personal information about other
                      Users;
                    </li>
                    <li>
                      To collect or store information about potential
                      candidates;
                    </li>
                    <li>
                      To mine information relating to potential candidates with
                      the aim of creating a database of potential candidates
                      whether or not such database is used or meant to be used
                      by the User or any third party associated with the User or
                      to whom such User makes such mined information available,
                      for either a commercial purpose of for the User’s own use
                      at a future date;
                    </li>
                    <li>
                      To interfere with or disrupt the HIRE48 and/or the HIRE48
                      Platform, HIRE48 servers, or HIRE48 networks;
                    </li>
                    <li>
                      To impersonate any person or entity, including, but not
                      limited to, a representative of HIRE48, or falsely state
                      or otherwise misrepresent User's affiliation with a person
                      or entity;
                    </li>
                    <li>
                      To forge headers or manipulate identifiers or other data
                      in order to disguise the origin of any content transmitted
                      through HIRE48 or to manipulate User's presence on the
                      HIRE48 Platform;
                    </li>
                    <li>
                      To take any action that imposes an unreasonably or
                      disproportionately large load on HIRE48’s infrastructure;
                    </li>
                    <li>To engage in any illegal activities.</li>
                    <li>
                      To engage in any action that threatens the unity,
                      integrity, defence, security or sovereignty of India,
                      friendly relations with foreign States, or public order,
                      or causes incitement to the commission of any cognisable
                      offence or prevents investigation of any offence or is
                      insulting other nation.
                    </li>
                  </ol>
                  <p>
                    If a User chooses a username that, in HIRE48's considered
                    opinion is obscene, indecent, abusive or that might subject
                    HIRE48 to public disparagement or scorn, or a name which is
                    an official team/league/franchise names and/or name of any
                    sporting personality, as the case may be, HIRE48 reserves
                    the right, without prior notice to the User, to restrict
                    usage of such names, which in HIRE48&rsquo;s opinion fall
                    within any of the said categories and/or change such
                    username and intimate the User or delete such username and
                    posts from HIRE48, deny such User access to HIRE48, or any
                    combination of these options.
                  </p>

                  <p>
                    Unauthorized access to the HIRE48 Platform is a breach of
                    these Terms of Service, and a violation of the law. Users
                    agree not to access the HIRE48 Platform by any means other
                    than through the interface that is provided by HIRE48 via
                    the HIRE48 Platform for use in accessing the HIRE48
                    Platform. Users agree not to use any automated means,
                    including, without limitation, agents, robots, scripts, or
                    spiders, to access, monitor, or copy any part of the HIRE48
                    Platform, HIRE48 Services or any information available for
                    access through the HIRE48 Platform or HIRE48 Services,
                    except those automated means that HIRE48 has approved in
                    advance and in writing.
                  </p>

                  <p>
                    Use of the HIRE48 Platform is subject to existing laws and
                    legal processes. Nothing contained in these Terms of Service
                    shall limit HIRE48's right to comply with governmental,
                    court, and law-enforcement requests or requirements relating
                    to Users' use of HIRE48.
                  </p>

                  <p>
                    Persons below the age of eighteen (18) years are not allowed
                    to register with the HIRE48 Platform. All persons interested
                    in becoming HIRE48 Users might be required by HIRE48 to
                    disclose their age at the time of getting access to the
                    HIRE48 Platform. If a person declares a false age, HIRE48
                    shall not be held responsible and such person shall, in
                    addition to forfeiting any and all rights over their HIRE48
                    account, shall indemnify and hold HIRE48, its Directors,
                    officers, employees, agents, affiliates harmless of any and
                    all losses that may be suffered by HIRE48 its Directors,
                    officers, employees, agents, affiliates by virtue of such
                    false declaration being made. In case the person making the
                    false declaration is below the age of 18 such person&rsquo;s
                    legal guardians shall indemnify and hold HIRE48, its
                    Directors, officers, employees, agents, affiliates harmless
                    of any and all losses that may be suffered by HIRE48 its
                    Directors, officers, employees, agents, affiliates by virtue
                    of such false declaration having been made by said person.
                  </p>

                  <p>
                    HIRE48 may not be held responsible for any content
                    contributed by Users on the HIRE48 Platform.
                  </p>
                  <h3>Eligibility</h3>
                  <p>
                    The HIRE48 Platform is open only to persons above the age of
                    18 years. The HIRE48 Platform is open only to persons,
                    currently residing in India. Persons who wish to participate
                    must have a valid email address and/or mobile phone number.
                    HIRE48 may on receipt of information bar a person from
                    accessing their HIRE48 account if such person is found to be
                    in violation of any part of these Terms of Service or
                    theCode of Conduct. Only those Users who have successfully
                    registered on the HIRE48 Platform shall be eligible to Post
                    view and/or apply for jobs via the HIRE48 Platform.
                  </p>
                  <h3>Dispute and Dispute Resolution</h3>
                  <p>
                    If any dispute arising out of, or in connection with, the
                    HIRE48 Services provided by HIRE48 via the HIRE48 Platform,
                    the construction, validity, interpretation and
                    enforceability of these Terms of Service, or the rights and
                    obligations of the User(s) or HIRE48, as well as the
                    exclusive jurisdiction to grant interim or preliminary
                    relief in case of any dispute referred to arbitration as
                    given below arises between the User(s) and HIRE48
                    (&ldquo;Dispute&rdquo;), the disputing parties hereto shall
                    endeavor to settle such Dispute amicably. The attempt to
                    bring about an amicable settlement shall be considered to
                    have failed if not resolved within 30 (thirty) days from the
                    date of communicating the Dispute in writing.
                  </p>

                  <p>
                    {" "}
                    If the parties are unable to amicably settle the Dispute as
                    mentioned above, any party to the Dispute shall be entitled
                    to serve a notice invoking Arbitration. The Dispute shall be
                    referred to and finally resolved by arbitration. The
                    Arbitration shall be conducted by an Arbitral Tribunal
                    consisting of a sole arbitrator in accordance with the Rules
                    of the Delhi International Arbitration Centre (&ldquo;DIAC
                    Rules&rdquo;), which rules are deemed to be incorporated by
                    reference in this clause. The seat of the arbitration shall
                    be New Delhi. The Tribunal shall consist of one arbitrator.
                    The language of the arbitration shall be English. The law
                    governing the arbitration agreement shall be Indian Law.
                  </p>

                  <p>
                    {" "}
                    Nothing shall preclude any Party from seeking interim or
                    permanent equitable or injunctive relief, or both, from the
                    competent courts at New Delhi, having jurisdiction to grant
                    relief on any Disputes. The pursuit of equitable or
                    injunctive relief shall not be a waiver of the duty of the
                    Parties to pursue any remedy (including for monetary
                    damages) through the arbitration described herein.
                  </p>

                  <p>
                    {" "}
                    The arbitration award will be final and binding on the
                    Parties.
                  </p>
                  <h3>Release and Limitations of Liability</h3>
                  <p>
                    Users shall access the HIRE48 Services provided via the
                    HIRE48 Platform voluntarily and at their own risk. HIRE48
                    shall, under no circumstances be held responsible or liable
                    on account of any loss or damage sustained by Users or any
                    other person or entity during the course of access to the
                    HIRE48 Platform.
                  </p>

                  <p>
                    {" "}
                    By accessing the HIRE48 Platform and HIRE48 Services
                    provided therein, Users hereby release from and agree to
                    indemnify HIRE48, and/or any of its directors, employees,
                    partners, associates and licensors, from and against all
                    liability, cost, loss or expense arising out their access of
                    the HIRE48 Platform and the HIRE48 Services including (but
                    not limited to) personal injury and damage to property and
                    whether direct, indirect, consequential, foreseeable, due to
                    some negligent act or omission on their part, or otherwise.
                  </p>

                  <p>
                    {" "}
                    HIRE48 accepts no liability, whether jointly or severally,
                    for any errors or omissions, whether on behalf of itself or
                    third parties in relation to the data/information collated
                    and published on the HIRE48 Platform.
                  </p>

                  <p>
                    {" "}
                    Users shall be solely responsible for any consequences which
                    may arise due to their access of HIRE48 Services by
                    conducting an illegal act or due to non-conformity with
                    these Terms of Service and other rules and regulations in
                    relation to HIRE48 Services, including provision of
                    incorrect personal details. Users also undertake to
                    indemnify HIRE48 and their respective officers, directors,
                    employees and agents on the happening of such an event
                    (including without limitation cost of attorney, legal
                    charges etc.) on full indemnity basis for any loss/damage
                    suffered by HIRE48 on account of such act on the part of the
                    Users.
                  </p>

                  <p>
                    {" "}
                    Users shall indemnify, defend, and hold HIRE48 harmless from
                    any third party/entity/organization claims arising from or
                    related to such User&amp;apso;s engagement with the HIRE48
                    Platform. In no event shall HIRE48 be liable to any User for
                    acts or omissions arising out of or related to User's
                    engagement with the HIRE48 Platform.
                  </p>

                  <p>
                    {" "}
                    In consideration of HIRE48 allowing Users to access the
                    HIRE48 Platform, to the maximum extent permitted by law, the
                    Users waive and release each and every right or claim, all
                    actions, causes of actions (present or future) each of them
                    has or may have against HIRE48, its respective agents,
                    directors, officers, business associates, group companies,
                    sponsors, employees, or representatives for all and any
                    injuries, accidents, or mishaps (whether known or unknown)
                    or (whether anticipated or unanticipated) arising out of the
                    provision of HIRE48 Services.
                  </p>
                  <h3>Disclaimers</h3>
                  <p>
                    To the extent permitted under law, neither HIRE48 nor its
                    parent/holding company, subsidiaries, affiliates, directors,
                    officers, professional advisors, employees shall be
                    responsible for the deletion, the failure to store, the
                    mis-delivery, or the untimely delivery of any information or
                    material.
                  </p>

                  <p>
                    {" "}
                    To the extent permitted under law, HIRE48 shall not be
                    responsible for any harm resulting from downloading or
                    accessing any information or material, the quality of
                    servers, products, HIRE48 Services or sites.
                  </p>

                  <p>
                    {" "}
                    Any material accessed, downloaded or otherwise obtained
                    through the HIRE48 Platform is done at the User&amp;apso;s
                    discretion, competence, acceptance and risk, and the User
                    will be solely responsible for any potential damage to
                    User's computer system or loss of data that results from a
                    User's download of any such material.
                  </p>

                  <p>
                    {" "}
                    HIRE48 shall make best endeavours to ensure that the
                    HIRE48(s) is error-free and secure, however, neither HIRE48
                    nor any of its partners, licensors or associates makes any
                    warranty that: the HIRE48 Platform will meet Users'
                    requirements, HIRE48 Platform will be uninterrupted, timely,
                    secure, or error free the results that may be obtained from
                    the use of HIRE48 Platform will be accurate or reliable; and
                    the quality of any products, HIRE48 Services, information,
                    or other material that Users purchase or obtain through the
                    HIRE48 Platform will meet Users' expectations.
                  </p>

                  <p>
                    {" "}
                    In case HIRE48 discovers any error, HIRE48 reserves the
                    right (exercisable at its discretion) to rectify the error
                    in such manner as it deems fit, including through a set-off
                    of the erroneous payment from amounts due to the User or
                    deduction from the User's account of the amount of erroneous
                    payment. In case of exercise of remedies in accordance with
                    this clause, HIRE48 agrees to notify the User of the error
                    and of the exercise of the remedy(ies) to rectify the same.
                  </p>

                  <p>
                    {" "}
                    To the extent permitted under law, neither HIRE48 nor its
                    partners, licensors or associates shall be liable for any
                    direct, indirect, incidental, special, or consequential
                    damages arising out of the use of or inability to use our
                    sites, even if HIRE48 has been advised of the possibility of
                    such damages.
                  </p>

                  <p>
                    {" "}
                    Any HIRE48 Services being hosted or provided, or intended to
                    be hosted on the HIRE48 Platform and requiring specific
                    permission or authority from any statutory authority or any
                    state or the central government, or the board of directors
                    shall be deemed cancelled or terminated, if such permission
                    or authority is either not obtained or denied either before
                    or after the availability of the relevant HIRE48 Services
                    are hosted or provided.
                  </p>

                  <p>
                    {" "}
                    To the extent permitted under law, in the event of
                    suspension or closure of any HIRE48 Services Users shall not
                    be entitled to make any demands, claims, on any nature
                    whatsoever.
                  </p>
                  <h3>Grievance Redressal Mechanism</h3>
                  <p>
                    In case a User has any complaints or grievance pertaining to
                    <ol>
                      <li>
                        any Content that a User believes violates these Terms
                        (other than an infringement of Intellectual Property
                        Rights),{" "}
                      </li>
                      <li>Users’ access to the HIRE48 Platform or</li>
                      <li>
                        any Content which a User believes is, prima facie,
                      </li>
                    </ol>
                    in the nature of any material which is obscene, defamatory
                    towards the complainant or any person on whose behalf such
                    User is making the complaint, or is in the nature of
                    impersonation in an electronic form, including artificially
                    morphed images of such individual, please share the same
                    with us by writing to:101.grievance@hire48.com
                  </p>
                  <p>
                    In the complaint or grievance, the User shall include the
                    following information:
                  </p>

                  <p>
                    {" "}
                    Name and contact details: name, address, contact number and
                    email address;
                  </p>

                  <p>
                    {" "}
                    Relation to the subject matter of the complaint, i.e.
                    complainant or person acting on behalf of an affected
                    person;
                  </p>

                  <p>
                    {" "}
                    The name and age of the person aggrieved or affected by the
                    subject matter of the complaint, in case the User is acting
                    on behalf of such person and a statement that the User is
                    authorised to act on behalf of such person and to provide
                    such person's personal information to HIRE48 in relation to
                    the complaint/grievance;
                  </p>

                  <p>
                    Description of the complaint or grievance with clear
                    identification of the Content in relation to which such
                    complaint or grievance is made;
                  </p>

                  <p>
                    {" "}
                    A statement that the User believes, in good faith, that the
                    Content violates these Terms and Conditions;
                  </p>

                  <p>
                    {" "}
                    A statement that the information provided in the complaint
                    or grievance is accurate.
                  </p>

                  <p>
                    HIRE48 respects the Intellectual Property Rights of others.
                    All names, logos, marks, labels, trademarks, copyrights or
                    intellectual and proprietary rights on the HIRE48 Platform
                    belonging to any person (including User), entity or third
                    party are recognized as proprietary to the respective
                    owners. Users are requested to send HIRE48 a written notice/
                    intimation if Users notice any act of infringement on the
                    HIRE48 Platform, which must include the following
                    information:
                  </p>

                  <p>
                    {" "}
                    A clear identification of the copyrighted work allegedly
                    infringed; A clear identification of the allegedly
                    infringing material on the HIRE48 Platform;
                  </p>

                  <p>
                    {" "}
                    Contact details: name, address, e-mail address and phone
                    number;
                  </p>

                  <p>
                    {" "}
                    A statement that the User believes, in good faith, that the
                    use of the copyrighted material allegedly infringed on the
                    HIRE48 Platform is not authorized by the User&rsquo;s agent
                    or the law;
                  </p>

                  <p>
                    {" "}
                    A statement that the information provided in the notice is
                    accurate and that the signatory is authorized to act on
                    behalf of the owner of an exclusive copyright right that is
                    allegedly infringed;
                  </p>

                  <p>
                    {" "}
                    User&rsquo;s signature or a signature of the User&rsquo;s
                    authorized agent.
                  </p>

                  <p>
                    The aforesaid notices can be sent to the Company by email at
                    101.grievance@hire48.com
                  </p>

                  <p>
                    On receiving such complaint, grievance or notice, HIRE48
                    reserves the right to investigate and/or take such action as
                    HIRE48 may deem appropriate. HIRE48 may reach out to the
                    User to seek further clarification or assistance with the
                    investigation, or verify the statements made in the
                    complaint, grievance or notice, and the User acknowledges
                    that timely assistance with the investigation would
                    facilitate the redressal of the same.
                  </p>

                  <p>
                    The name and title of the Grievance Redressal Officer is as
                    follows:
                  </p>

                  <p>Name: Priya</p>

                  <p>Email:101.grievance@hire48.com</p>

                  <p>
                    The Grievance Officer identified above pursuant to the
                    provisions of applicable laws including but not limited to
                    the Information Technology Act, 2000 and the Consumer
                    Protection Act, 2019, and the Rules enacted under those
                    laws. The Company reserves the right to replace the
                    Grievance Redressal Officer at its discretion through
                    publication of the name and title of such replacement on the
                    website, which replacement shall come into effect
                    immediately upon publication.
                  </p>
                  <hr />

                  <h2>For employer / Recruitement agency / Job poster</h2>
                  <h3>Usage of HIRE48</h3>
                  <p>
                    The Hire48 Platform (including any mobile based
                    applications, website and web applications) is provided by
                    HIRE48Inc. (&quot;HIRE48Time&quot;) either directly or
                    through its affiliates including but not limited to Kryzen
                    Technologies Private Limited (&quot;Hire48 India&quot;).
                    Through the Hire48 Platform any person with a verified
                    account can post jobs (&quot;Job Poster&quot;) to the Hire48
                    Platform, access and participate in the services provided by
                    Hire48 . By using Hire48 Platform, you consent to the terms
                    of the Terms of Service in addition to our Privacy Policy.
                  </p>

                  <p>
                    A Job Poster accessing the Hire48 Platform shall be bound by
                    these Terms of Service, and all other rules, regulations and
                    terms of use referred to herein or provided by Hire48 in
                    relation to any services provided via the Hire48 Platform
                    (&quot;HIRE48Services&quot;).
                  </p>

                  <p>
                    HIRE48shall be entitled to modify these Terms of Service,
                    rules, regulations and terms of use referred to herein or
                    provided by Hire48 in relation to any HIRE48Services, at any
                    time, by posting the same on the Hire48 Platform. Use of
                    Hire48 constitutes the Job Poster's acceptance of such
                    modified Terms of Service, rules, regulations and terms of
                    use referred to to herein or provided by Hire48 in relation
                    to HIRE48Services, as may be amended from time to time.
                    Hire48 may, at its sole discretion, also notify the Job
                    Poster of any change or modification in these Terms of
                    Service, rules, regulations and terms of use referred to
                    herein or provided by Hire48 , by way of sending an email to
                    the Job Poster's registered email address or posting
                    notifications in the Job Poster accounts. The Job Poster may
                    then exercise the options provided in such an email or
                    notification to indicate non-acceptance of the modified
                    Terms of Service, rules, regulations and terms of use
                    referred to herein or provided by Hire48 . If such options
                    are not exercised by the Job Poster within the time frame
                    prescribed in the email or notification, the Job Poster will
                    be deemed to have accepted the modified Terms of Service,
                    rules, regulations and terms of use referred to herein or
                    provided by Hire48 .
                  </p>

                  <p>
                    Certain HIRE48Services being provided on HIRE48may be
                    subject to additional rules and regulations set down in that
                    respect. To the extent that these Terms of Service are
                    inconsistent with the additional conditions set down, the
                    additional conditions shall prevail. HIRE48may, at its sole
                    and absolute discretion:
                  </p>
                  <ul>
                    <li>
                      Restrict, suspend, or terminate any Job Poster's access to
                      all or any part of the Hire48 Platform or HIRE48Services;
                    </li>
                    <li>
                      Change, suspend, or discontinue all or any part of the
                      Hire48 Platform or HIRE48Services;
                    </li>
                    <li>
                      Reject, move, or remove any material that may be submitted
                      by a Job Poster;
                    </li>
                    <li>
                      Move or remove any content that is available on the Hire48
                      Platform;
                    </li>
                    <li>
                      Deactivate or delete a Job Poster's account and all
                      related information and files on the account;
                    </li>
                    <li>
                      Establish general practices and limits concerning use of
                      Hire48 Platform;
                    </li>
                    <li>
                      Assign its rights and liabilities to all Job Poster
                      accounts hereunder to any entity (post such assignment
                      intimation of such assignment shall be sent to all Job
                      Posters to their registered email ids).
                    </li>
                  </ul>
                  <p>
                    In the event any Job Poster breaches, or HIRE48reasonably
                    believes that such Job Poster has breached these Terms of
                    Service, or has illegally or improperly used the Hire48
                    Platform or HIRE48Services, HIRE48may, at its sole and
                    absolute discretion, and without any notice to the Job
                    Poster, restrict, suspend or terminate such Job Poster's
                    access to all or any part of the Hire48 Platform, deactivate
                    or delete the Job Poster's account and all related
                    information on the account, delete any content posted by the
                    Job Poster on HIRE48and further, take technical and legal
                    steps as it deems necessary.
                  </p>
                  <p>
                    If HIRE48charges its Job Posters a platform fee in respect
                    of any HIRE48Services, HIRE48shall, without delay, repay
                    such platform fee in the event of suspension or removal of
                    the Job Poster's account or HIRE48Services on account of any
                    negligence or deficiency on the part of Hire48 , but not if
                    such suspension or removal is affected due to:
                  </p>
                  <ol>
                    {" "}
                    <li>
                      any breach or inadequate performance by the Job Poster of
                      any of these Terms of Service; or
                    </li>
                    <li>
                      any circumstances beyond the reasonable control of Hire48
                      .
                    </li>{" "}
                  </ol>
                  <p>
                    By accepting these Terms of Service Job Posters are
                    providing their consent to receiving communications such as
                    announcements, administrative messages and advertisements
                    from HIRE48or any of its partners, licensors or associates.
                  </p>
                  <h3>Participation</h3>
                  <p>
                    When accessing and interacting with the Hire48 Platform and
                    HIRE48Services a Job Poster will be able to post jobs to
                    source and hire potential candidates and employees for the
                    Job Poster's organization, view the profiles of suitable
                    candidates who have the requisite skills and experience for
                    the particular job(s) that the Job Poster is hiring for and
                    schedule interviews with potential candidates/employees.
                  </p>

                  <p>
                    {" "}
                    To post a job a Job Poster shall be required to provide
                    information about the job such as the job role, whether the
                    job is part-time or full time, the days of work, work
                    timings, the salary range for the job, the number of
                    openings for the said job the minimum education level and
                    the level of experience required for the job. While Hire48
                    does not tolerate or allow for discrimination on the basis
                    of gender, HIRE48also recognizes that certain jobs might be
                    gender specific and Job Posters have the option of
                    specifying whether a job is gender specific. By agreeing to
                    these Terms of Service and while posting a job on the Hire48
                    Platform, Job Posters undertake that all information posted
                    about a job will at all times be accurate, not be misleading
                    that the Job Poster is not indulging in any discriminatory
                    practices. The Job Poster understands and acknowledges that
                    any incorrect information or misrepresentations about a job
                    will affect the efficacy of the Hire48 Platform and Hire48
                    Services and that HIRE48shall have the right to remove a job
                    posting or suspend the Job Poster's account.
                  </p>

                  <p>
                    {" "}
                    The job applications by the candidates on the Hire48
                    Platform shall remain active only for a period of 60 (sixty)
                    days from the date of application to the job posts and upon
                    the expiry of the said period of 60 (sixty) days, such job
                    applications shall be archived ( &quot; Archived Job
                    Applications &quot;). The Job Posters shall not have access
                    to the list of such candidates or to the Archived Job
                    Applications upon the expiry of 60 (sixty) days. The
                    candidates, however, may re-apply to a job post (if still
                    active) after the expiry of 60 (sixty) days from the date
                    such candidate made their first application to the same job
                    post.
                  </p>

                  <p>
                    {" "}
                    Job Posters agree that they shall at all times be bound by
                    and adhere to theCode of Conductwhile posting jobs through
                    the Hire48 Platform and while using the HIRE48Services.
                  </p>
                  <h3>Intellectual Property</h3>
                  <p>
                    The intellectual property rights (&quot;Intellectual
                    Property Rights&quot;)in all software underlying the Hire48
                    Platform and the HIRE48Services and material published on
                    the Hire48 Platform, including (but not limited to)
                    software, advertisements, content (whether written, audio
                    and/or visual), photographs, graphics, images,
                    illustrations, graphs, charts, marks, logos, audio or video
                    clippings, animations etc. is owned by Hire48 , its
                    affiliates, partners, licensors and/or associates. Job
                    Posters may not modify, publish, transmit, participate in
                    the transfer or sale of, reproduce, create derivative works
                    of, distribute, publicly perform, publicly display, or in
                    any way exploit any of the materials or content on
                    HIRE48either in whole or in part without express written
                    license from Hire48
                  </p>

                  <p>
                    Job Posters are solely responsible for all materials
                    (whether publicly posted or privately transmitted) that they
                    upload, post, e-mail, transmit, or otherwise make available
                    via the Hire48 Platform (&quot;Job Posters' Content&quot;).
                    Each Job Poster represents and warrants that they own all
                    Intellectual Property Rights in the Job Posters&rsquo;
                    Content and that no part of the Job Poster's Content
                    infringes any third-party rights. Job Posters further
                    confirm and undertake to not display or use of the names,
                    logos, marks, labels, trademarks, copyrights or intellectual
                    and proprietary rights of any third party on the Hire48
                    Platform, without written authorization from such third
                    party. Job Posters agree to indemnify and hold harmless
                    Hire48 , its directors, employees, affiliates and assigns
                    against all costs, damages, loss and harm including towards
                    litigation costs and counsel fees, in respect of any third
                    party claims that may be initiated including for
                    infringement of Intellectual Property Rights arising out of
                    such display or use of the names, logos, marks, labels,
                    trademarks, copyrights or intellectual and proprietary
                    rights on the Hire48 Platform, by such Job Poster or through
                    the Job Poster's commissions or omissions
                  </p>

                  <p>
                    Job Posters hereby grant to HIRE48and its affiliates,
                    partners, licensors and associates a worldwide, irrevocable,
                    royalty-free, non-exclusive, sub-licensable license to use,
                    reproduce, create derivative works of, distribute, publicly
                    perform, publicly display, transfer, transmit, and/or
                    publish Job Posters' Content for any of the following
                    purposes:
                  </p>

                  <ul>
                    <li> displaying Job Posters' Content on Hire48 </li>
                    <li>
                      distributing Job Posters' Content, either electronically
                      or via other media, to potential candidates, and/or
                    </li>
                    <li>
                      storing Job Posters' Content in a remote database
                      accessible by end users, for a charge.
                    </li>
                    <li>
                      This license shall apply to the distribution and the
                      storage of Job Posters' Content in any form, medium, or
                      technology.
                    </li>
                  </ul>
                  <p>
                    All names, logos, marks, labels, trademarks, copyrights or
                    intellectual and proprietary rights on the Hire48 Platform
                    belonging to any person (including a Job Poster), entity or
                    third party are recognized as proprietary to the respective
                    owners and any claims, controversy or issues against these
                    names, logos, marks, labels, trademarks, copyrights or
                    intellectual and proprietary rights must be directly
                    addressed to the respective parties under notice to Hire48 .
                  </p>
                  <h3>Third Party Sites, Services and Products</h3>
                  <p>
                    Links to other Internet sites or mobile applications owned
                    and operated by third parties may be provided via the Hire48
                    Platform. Job Posters' use of each of those sites is subject
                    to the conditions, if any, posted by those sites. HIRE48does
                    not exercise control over any Internet sites or mobile
                    applications apart from the Hire48 Platform and cannot be
                    held responsible for any content residing in any third-party
                    Internet site or mobile application. Hire48 's inclusion of
                    third-party content or links to third-party Internet sites
                    or mobile applications is not an endorsement by HIRE48of
                    such third-party Internet site or mobile application.
                  </p>
                  <p>
                    Job Posters' correspondence, transactions/offers or related
                    activities with third parties including but not limited to
                    potential candidates, payment providers and verification
                    service providers, are solely between the Job Poster and
                    that third party. Job Posters' correspondence, transactions
                    and usage of the services/offers of such third party shall
                    be subject to the terms and conditions, policies and other
                    service terms adopted/implemented by such third party, and
                    the Job Poster shall be solely responsible for reviewing the
                    same prior to transacting or availing of the services/offers
                    of such third party. The Job Poster agrees that HIRE48will
                    not be responsible or liable for any loss or damage of any
                    sort incurred as a result of any such transactions/offers
                    with third parties. Any questions, complaints, or claims
                    related to any third-party product or service should be
                    directed to the appropriate vendor.
                  </p>
                  <p>
                    The Hire48 Platform contains content that is created by
                    Hire48 as well as content provided by third parties
                    (including potential candidates). HIRE48does not guarantee
                    the accuracy, integrity, quality of the content provided by
                    third parties and such content may not relied upon by the
                    Job Posters in utilizing the HIRE48Services provided on the
                    Hire48 Platform.
                  </p>
                  <h3>Participation</h3>
                  <p>
                    All information collected from Job Posters, such as
                    registration (including but not limited to email addresses,
                    mobile phone numbers, government identity documentation) and
                    payment information, is subject to Hire48 's Privacy Policy
                    which is available at Privacy Policy.
                  </p>

                  <p>
                    {" "}
                    We do not share personal information of any individual with
                    other companies/entities without obtaining permission. We
                    may share all such information that we have in our
                    possession in accordance with our Privacy Policy
                  </p>

                  <p>
                    {" "}
                    Once the personal information has been shared with you, you
                    shall, at all times, be responsible to secure such
                    information.
                  </p>

                  <p>
                    {" "}
                    You warrant and represent that you shall not disclose or
                    transfer personal information shared by us to any
                    sub-processors without ensuring that adequate and equivalent
                    safeguards to the personal information.
                  </p>

                  <p>
                    {" "}
                    You, hereby agree and acknowledge that you will use the
                    information shared with you only for the purpose of the
                    Services. You shall not use such information for any
                    personal or other business purposes. In the event you are
                    found to be misusing the information shared with you, we
                    shall, at our sole discretion, delete your account with
                    immediate effect and you will be blocked from using/
                    accessing Hire48 Platform in future.
                  </p>
                  <h3>Job Poster Conduct</h3>
                  <p>
                    Job Posters agree to abide by these Terms of Service and all
                    other rules, regulations and terms of use of the Website. In
                    the event Job Poster does not abide by these Terms of
                    Service and all other rules, regulations and terms of use,
                    HIRE48may, at its sole and absolute discretion, take
                    necessary remedial action, including but not limited to:
                  </p>

                  <p>
                    {" "}
                    restricting, suspending, or terminating any Job Poster's
                    access to all or any part of HIRE48Services; deactivating or
                    deleting a Job Poster's account and all related information
                    and files on the account. Any amount remaining unused in the
                    Job Poster's account on the date of deactivation or deletion
                    shall be transferred to the Job Poster's bank account on
                    record with HIRE48subject to a processing fee (if any)
                    applicable on such transfers as set out herein.
                  </p>

                  <p>
                    Job Posters agree to provide true, accurate, current and
                    complete information at the time of registration and at all
                    other times (as required by Hire48 ). Job Posters further
                    agree to update and keep updated their registration
                    information and information related to job posts made on the
                    Hire48 Platform.
                  </p>

                  <p>
                    A Job Poster shall not register or operate more than one Job
                    Poster account with Hire48 .
                  </p>

                  <p>
                    Job Posters agree to ensure that they can receive all
                    communication from HIRE48by marking e-mails or sending SMSs
                    from Hire48 . HIRE48shall not be held liable if any
                    e-mail/SMS remains unread by a Job Poster as a result of
                    such e-mail getting delivered to the Job Poster's junk or
                    spam folder.
                  </p>

                  <p>
                    Any password issued by HIRE48to a Job Poster may not be
                    revealed to anyone else. Job Posters may not use anyone
                    else's password. Job Posters are responsible for maintaining
                    the confidentiality of their accounts and passwords. Job
                    Posters agree to immediately notify HIRE48of any
                    unauthorized use of their passwords or accounts or any other
                    breach of security.
                  </p>

                  <p>
                    Job Posters agree to exit/log-out of their accounts at the
                    end of each session. HIRE48shall not be responsible for any
                    loss or damage that may result if the Job Poster fails to
                    comply with these requirements.
                  </p>

                  <p>
                    Job Posters agree not to use cheats, exploits, automation,
                    software, bots, hacks or any unauthorized third-party
                    software designed to modify or interfere with the Hire48
                    Services and/or HIRE48experience or assist in such activity.
                  </p>

                  <p>
                    Job Posters agree not to copy, modify, rent, lease, loan,
                    sell, assign, distribute, reverse engineer, grant a security
                    interest in, or otherwise transfer any right to the
                    technology or software underlying HIRE48or Hire48 &rsquo;s
                    Services.
                  </p>

                  <p>
                    Job Posters agree that without Hire48 's express written
                    consent, they shall not modify or cause to be modified any
                    files or software that are part of Hire48 's Services or the
                    Hire48 Platform.
                  </p>

                  <p>
                    Job Posters agree not to disrupt, overburden, or aid or
                    assist in the disruption or overburdening of (a) any
                    computer or server used to offer or support HIRE48or the
                    Hire48 &rsquo;s Services (each a &quot;Server&quot;); or (2)
                    the enjoyment of HIRE48Services by any other Job Poster or
                    person.
                  </p>

                  <p>
                    Job Posters agree not to institute, assist or become
                    involved in any type of attack, including without limitation
                    to distribution of a virus, denial of service, or other
                    attempts to disrupt HIRE48Services or any other person's use
                    or enjoyment of HIRE48Services.
                  </p>

                  <p>
                    Job Posters shall not attempt to gain unauthorised access to
                    Job Poster accounts, Servers or networks connected to Hire48
                    Services by any means other than the Job Poster interface
                    provided by Hire48 , including but not limited to, by
                    circumventing or modifying, attempting to circumvent or
                    modify, or encouraging or assisting any other person to
                    circumvent or modify, any security, technology, device, or
                    software that underlies or is part of HIRE48Services.
                  </p>

                  <p>
                    A Job Poster shall not publish any content that is patently
                    false and untrue, and is written or published in any form,
                    with the intent to mislead or harass a person, entity or
                    agency for financial gain or to cause any injury to any
                    person.
                  </p>
                  <p>
                    Without limiting the foregoing, Job Posters agree not to use
                    HIRE48for any of the following:
                  </p>
                  <ul>
                    <li>
                      {" "}
                      To engage in any obscene, offensive, indecent, racial,
                      communal, anti-national, objectionable, defamatory or
                      abusive action or communication;
                    </li>
                    <li>
                      To harass, stalk, threaten, or otherwise violate any legal
                      rights of other individuals;
                    </li>
                    <li>
                      To publish, post, upload, e-mail, distribute, or
                      disseminate (collectively, "Transmit") any inappropriate,
                      profane, defamatory, infringing, obscene, indecent, or
                      unlawful content;
                    </li>
                    <li>
                      To Transmit files that contain viruses, corrupted files,
                      or any other similar software or programs that may damage
                      or adversely affect the operation of another person's
                      computer, Hire48 , any software, hardware, or
                      telecommunications equipment;
                    </li>
                    <li>
                      To advertise, offer or sell any goods or services for any
                      commercial purpose on Hire48 including but not limited to
                      multi-level marketing for a third party, promoting
                      business of a third party, selling financial products such
                      as loans, insurance, promoting demat account openings,
                      without the express written consent of Hire48 ;
                    </li>
                    <li>
                      To download any file, recompile or disassemble or
                      otherwise affect our products that you know or reasonably
                      should know cannot be legally obtained in such manner;
                    </li>
                    <li>
                      To falsify or delete any author attributions, legal or
                      other proper notices or proprietary designations or labels
                      of the origin or the source of software or other material;
                    </li>
                    <li>
                      To restrict or inhibit any other Job Poster from using and
                      enjoying any public area within our sites;
                    </li>
                    <li>
                      To collect or store personal information about other Job
                      Posters;
                    </li>
                    <li>
                      To collect or store information about potential
                      candidates;
                    </li>
                    <li>
                      To mine information relating to potential candidates with
                      the aim of creating a database of potential candidates
                      whether or not such database is used or meant to be used
                      by the Job Poster or any third party associated with the
                      Job Poster or to whom such Job Poster makes such mined
                      information available, for either a commercial purpose or
                      for the Job Poster’s own use at a future date;
                    </li>
                    <li>
                      To interfere with or disrupt the HIRE48and/or the Hire48
                      Platform, HIRE48servers, or HIRE48networks;
                    </li>
                    <li>
                      To impersonate any person or entity, including, but not
                      limited to, a representative of Hire48 , or falsely state
                      or otherwise misrepresent Job Poster's affiliation with a
                      person or entity;
                    </li>
                    <li>
                      To forge headers or manipulate identifiers or other data
                      in order to disguise the origin of any content transmitted
                      through HIRE48or to manipulate Job Poster's presence on
                      the Hire48 Platform;
                    </li>
                    <li>
                      To take any action that imposes an unreasonably or
                      disproportionately large load on Hire48 ’s infrastructure;
                    </li>
                    <li>To engage in any illegal activities.</li>
                    <li>
                      To engage in any action that threatens the unity,
                      integrity, defence, security or sovereignty of India,
                      friendly relations with foreign States, or public order,
                      or causes incitement to the commission of any cognisable
                      offence or prevents investigation of any offence or is
                      insulting other nation.
                    </li>
                  </ul>
                  <p>
                    If a Job Poster chooses a username that, in Hire48 's
                    considered opinion is obscene, indecent, abusive or that
                    might subject HIRE48to public disparagement or scorn, or a
                    name which is an official team/league/franchise names and/or
                    name of any sporting personality, as the case may be, Hire48
                    reserves the right, without prior notice to the Job Poster,
                    to restrict usage of such names, which in Hire48 &rsquo;s
                    opinion fall within any of the said categories and/or change
                    such username and intimate the Job Poster or delete such
                    username and posts from Hire48 , deny such Job Poster access
                    to Hire48 , or any combination of these options.
                  </p>

                  <p>
                    Unauthorized access to the Hire48 Platform is a breach of
                    these Terms of Service, and a violation of the law. Job
                    Posters agree not to access the Hire48 Platform by any means
                    other than through the interface that is provided by Hire48
                    via the Hire48 Platform for use in accessing the Hire48
                    Platform. Job Posters agree not to use any automated means,
                    including, without limitation, agents, robots, scripts, or
                    spiders, to access, monitor, or copy any part of the Hire48
                    Platform, HIRE48Services or any information available for
                    access through the Hire48 Platform or HIRE48Services, except
                    those automated means that HIRE48has approved in advance and
                    in writing.
                  </p>

                  <p>
                    Use of the Hire48 Platform is subject to existing laws and
                    legal processes. Nothing contained in these Terms of Service
                    shall limit Hire48 's right to comply with governmental,
                    court, and law-enforcement requests or requirements relating
                    to Job Posters' use of Hire48 .
                  </p>

                  <p>
                    Persons below the age of eighteen (18) years are not allowed
                    to register with the Hire48 Platform. All persons interested
                    in becoming HIRE48Job Posters might be required by HIRE48to
                    disclose their age at the time of getting access to the
                    Hire48 Platform. If a person declares a false age,
                    HIRE48shall not be held responsible and such person shall,
                    in addition to forfeiting any and all rights over their
                    HIRE48account, shall indemnify and hold Hire48 , its
                    Directors, officers, employees, agents, affiliates harmless
                    of any and all losses that may be suffered by HIRE48its
                    Directors, officers, employees, agents, affiliates by virtue
                    of such false declaration being made. In case the person
                    making the false declaration is below the age of 18 such
                    person&rsquo;s legal guardians shall indemnify and hold
                    Hire48 , its Directors, officers, employees, agents,
                    affiliates harmless of any and all losses that may be
                    suffered by HIRE48its Directors, officers, employees,
                    agents, affiliates by virtue of such false declaration
                    having been made by said person.
                  </p>

                  <p>
                    HIRE48may not be held responsible for any content
                    contributed by Job Posters on the Hire48 Platform.
                  </p>

                  <h3>Eligibility</h3>
                  <p>
                    The Hire48 Platform is open only to persons above the age of
                    18 years. The Hire48 Platform is open only to persons,
                    currently residing in India.
                  </p>

                  <p>
                    {" "}
                    Persons who wish to participate must have a valid email
                    address and/or mobile phone number.
                  </p>

                  <p>
                    {" "}
                    HIRE48may on receipt of information bar a person from
                    accessing their HIRE48account if such person is found to be
                    in violation of any part of these Terms of Service or
                    theCode of Conduct.
                  </p>

                  <p>
                    {" "}
                    Only those Job Posters who have successfully registered on
                    the Hire48 Platform shall be eligible to Post Jobs.
                  </p>
                  <h3>Dispute and Dispute Resolution</h3>

                  <p>
                    If any dispute arising out of, or in connection with, the
                    HIRE48Services provided by HIRE48via the Hire48 Platform,
                    the construction, validity, interpretation and
                    enforceability of these Terms of Service, or the rights and
                    obligations of the Job Poster(s) or Hire48 , as well as the
                    exclusive jurisdiction to grant interim or preliminary
                    relief in case of any dispute referred to arbitration as
                    given below arises between the Job Poster(s) and
                    HIRE48(&quot;Dispute&quot;), the disputing parties hereto
                    shall endeavor to settle such Dispute amicably. The attempt
                    to bring about an amicable settlement shall be considered to
                    have failed if not resolved within 30 (thirty) days from the
                    date of communicating the Dispute in writing.
                  </p>

                  <p>
                    {" "}
                    If the parties are unable to amicably settle the Dispute as
                    mentioned above, any party to the Dispute shall be entitled
                    to serve a notice invoking Arbitration. The Dispute shall be
                    referred to and finally resolved by arbitration. The
                    Arbitration shall be conducted by an Arbitral Tribunal
                    consisting of a sole arbitrator in accordance with the Rules
                    of the Delhi International Arbitration Centre (&quot;DIAC
                    Rules&quot;), which rules are deemed to be incorporated by
                    reference in this clause. The seat of the arbitration shall
                    be New Delhi. The language of the arbitration shall be
                    English. The law governing the arbitration agreement shall
                    be Indian Law.
                  </p>

                  <p>
                    {" "}
                    Nothing shall preclude any Party from seeking interim or
                    permanent equitable or injunctive relief, or both, from the
                    competent courts at New Delhi, having jurisdiction to grant
                    relief on any Disputes. The pursuit of equitable or
                    injunctive relief shall not be a waiver of the duty of the
                    Parties to pursue any remedy (including for monetary
                    damages) through the arbitration described herein.
                  </p>

                  <p>
                    {" "}
                    The arbitration award will be final and binding on the
                    Parties.
                  </p>

                  <h3>Release and Limitations of Liability</h3>
                  <p>
                    Job Posters shall access the HIRE48Services provided via the
                    Hire48 Platform voluntarily and at their own risk.
                    HIRE48shall, under no circumstances be held responsible or
                    liable on account of any loss or damage sustained by Job
                    Posters or any other person or entity during the course of
                    access to the Hire48 Platform.
                  </p>

                  <p>
                    {" "}
                    By accessing the Hire48 Platform and HIRE48Services provided
                    therein, Job Posters hereby release from and agree to
                    indemnify Hire48 , and/or any of its directors, employees,
                    partners, associates and licensors, from and against all
                    liability, cost, loss or expense arising out their access of
                    the Hire48 Platform and the HIRE48Services including (but
                    not limited to) personal injury and damage to property and
                    whether direct, indirect, consequential, foreseeable, due to
                    some negligent act or omission on their part, or otherwise.
                  </p>

                  <p>
                    {" "}
                    HIRE48accepts no liability, whether jointly or severally,
                    for any errors or omissions, whether on behalf of itself or
                    third parties in relation to the data/information collated
                    and published on the Hire48 Platform.
                  </p>

                  <p>
                    {" "}
                    Job Posters shall be solely responsible for any consequences
                    which may arise due to their access of HIRE48Services by
                    conducting an illegal act or due to non-conformity with
                    these Terms of Service and other rules and regulations in
                    relation to HIRE48Services, including provision of incorrect
                    personal details. Job Posters also undertake to indemnify
                    HIRE48and their respective officers, directors, employees
                    and agents on the happening of such an event (including
                    without limitation cost of attorney, legal charges etc.) on
                    full indemnity basis for any loss/damage suffered by
                    HIRE48on account of such act on the part of the Job Posters.
                  </p>

                  <p>
                    {" "}
                    Job Posters shall indemnify, defend, and hold HIRE48harmless
                    from any third party/entity/organization claims arising from
                    or related to such Job Poster's engagement with the Hire48
                    Platform. In no event shall HIRE48be liable to any Job
                    Poster for acts or omissions arising out of or related to
                    Job Poster's engagement with the Hire48 Platform.
                  </p>

                  <p>
                    {" "}
                    In consideration of Hire48 allowing Job Posters to access
                    the Hire48 Platform, to the maximum extent permitted by law,
                    the Job Posters waive and release each and every right or
                    claim, all actions, causes of actions (present or future)
                    each of them has or may have against Hire48 , its respective
                    agents, directors, officers, business associates, group
                    companies, sponsors, employees, or representatives for all
                    and any injuries, accidents, or mishaps (whether known or
                    unknown) or (whether anticipated or unanticipated) arising
                    out of the provision of HIRE48Services.
                  </p>

                  <h3>Disclaimers</h3>
                  <p>
                    To the extent permitted under law, neither Hire48 nor its
                    parent/holding company, subsidiaries, affiliates, directors,
                    officers, professional advisors, employees shall be
                    responsible for the deletion, the failure to store, the
                    mis-delivery, or the untimely delivery of any information or
                    material.
                  </p>

                  <p>
                    To the extent permitted under law, neither Hire48 nor its
                    parent/holding company, subsidiaries, affiliates, directors,
                    officers, professional advisors, employees shall be
                    responsible for the deletion, the failure to store, the
                    mis-delivery, or the untimely delivery of any information or
                    material.
                  </p>

                  <p>
                    To the extent permitted under law, HIRE48shall not be
                    responsible for any harm resulting from downloading or
                    accessing any information or material, the quality of
                    servers, products, HIRE48Services or sites.
                  </p>

                  <p>
                    Any material accessed, downloaded or otherwise obtained
                    through the Hire48 Platform is done at the Job Poster's
                    discretion, competence, acceptance and risk, and the Job
                    Poster will be solely responsible for any potential damage
                    to Job Poster's computer system or loss of data that results
                    from a Job Poster's download of any such material.
                  </p>

                  <p>
                    HIRE48shall make best endeavours to ensure that the Hire48
                    (s) is error-free and secure, however, neither Hire48 nor
                    any of its partners, licensors or associates makes any
                    warranty that:
                  </p>
                  <ul>
                    <li>
                      {" "}
                      the Hire48 Platform will meet Job Posters' requirements,
                    </li>
                    <li>
                      Hire48 Platform will be uninterrupted, timely, secure, or
                      error free
                    </li>
                    <li>
                      the results that may be obtained from the use of Hire48
                      Platform will be accurate or reliable; and
                    </li>
                    <li>
                      the quality of any products, HIRE48Services, information,
                      or other material that Job Posters purchase or obtain
                      through the Hire48 Platform will meet Job Posters'
                      expectations.
                    </li>
                  </ul>
                  <p>
                    In case HIRE48discovers any error, HIRE48reserves the right
                    (exercisable at its discretion) to rectify the error in such
                    manner as it deems fit, including through a set-off of the
                    erroneous payment from amounts due to the Job Poster or
                    deduction from the Job Poster's account of the amount of
                    erroneous payment. In case of exercise of remedies in
                    accordance with this clause, HIRE48agrees to notify the Job
                    Poster of the error and of the exercise of the remedy(ies)
                    to rectify the same.
                  </p>

                  <p>
                    To the extent permitted under law, neither Hire48 nor its
                    partners, licensors or associates shall be liable for any
                    direct, indirect, incidental, special, or consequential
                    damages arising out of the use of or inability to use our
                    sites, even if we have been advised of the possibility of
                    such damages.
                  </p>

                  <p>
                    Any HIRE48Services being hosted or provided, or intended to
                    be hosted on the HIRE48platform and requiring specific
                    permission or authority from any statutory authority or any
                    state or the central government, or the board of directors
                    shall be deemed cancelled or terminated, if such permission
                    or authority is either not obtained or denied either before
                    or after the availability of the relevant HIRE48Services are
                    hosted or provided.
                  </p>

                  <p>
                    To the extent permitted under law, in the event of
                    suspension or closure of any HIRE48Services Job Posters
                    shall not be entitled to make any demands, claims, of any
                    nature whatsoever.
                  </p>

                  <h3>Grievance Redressal Mechanism</h3>
                  <p>
                    In case a User has any complaints or grievance pertaining to
                    <ol>
                      <li>
                        any Content that a User believes violates these Terms
                        (other than an infringement of Intellectual Property
                        Rights),{" "}
                      </li>
                      <li>Users’ access to the HIRE48 Platform or</li>
                      <li>
                        any Content which a User believes is, prima facie,
                      </li>
                    </ol>
                    in the nature of any material which is obscene, defamatory
                    towards the complainant or any person on whose behalf such
                    User is making the complaint, or is in the nature of
                    impersonation in an electronic form, including artificially
                    morphed images of such individual, please share the same
                    with us by writing to:101.grievance@hire48.com
                  </p>
                  <p>
                    In the complaint or grievance, the User shall include the
                    following information:
                  </p>

                  <p>
                    {" "}
                    Name and contact details: name, address, contact number and
                    email address;
                  </p>

                  <p>
                    {" "}
                    Relation to the subject matter of the complaint, i.e.
                    complainant or person acting on behalf of an affected
                    person;
                  </p>

                  <p>
                    {" "}
                    The name and age of the person aggrieved or affected by the
                    subject matter of the complaint, in case the User is acting
                    on behalf of such person and a statement that the User is
                    authorised to act on behalf of such person and to provide
                    such person's personal information to HIRE48 in relation to
                    the complaint/grievance;
                  </p>

                  <p>
                    Description of the complaint or grievance with clear
                    identification of the Content in relation to which such
                    complaint or grievance is made;
                  </p>

                  <p>
                    {" "}
                    A statement that the User believes, in good faith, that the
                    Content violates these Terms and Conditions;
                  </p>

                  <p>
                    {" "}
                    A statement that the information provided in the complaint
                    or grievance is accurate.
                  </p>

                  <p>
                    HIRE48 respects the Intellectual Property Rights of others.
                    All names, logos, marks, labels, trademarks, copyrights or
                    intellectual and proprietary rights on the HIRE48 Platform
                    belonging to any person (including User), entity or third
                    party are recognized as proprietary to the respective
                    owners. Users are requested to send HIRE48 a written notice/
                    intimation if Users notice any act of infringement on the
                    HIRE48 Platform, which must include the following
                    information:
                  </p>

                  <p>
                    {" "}
                    A clear identification of the copyrighted work allegedly
                    infringed; A clear identification of the allegedly
                    infringing material on the HIRE48 Platform;
                  </p>

                  <p>
                    {" "}
                    Contact details: name, address, e-mail address and phone
                    number;
                  </p>

                  <p>
                    {" "}
                    A statement that the User believes, in good faith, that the
                    use of the copyrighted material allegedly infringed on the
                    HIRE48 Platform is not authorized by the User&rsquo;s agent
                    or the law;
                  </p>

                  <p>
                    {" "}
                    A statement that the information provided in the notice is
                    accurate and that the signatory is authorized to act on
                    behalf of the owner of an exclusive copyright right that is
                    allegedly infringed;
                  </p>

                  <p>
                    {" "}
                    User&rsquo;s signature or a signature of the User&rsquo;s
                    authorized agent.
                  </p>

                  <p>
                    The aforesaid notices can be sent to the Company by email at
                    101.grievance@hire48.com
                  </p>

                  <p>
                    On receiving such complaint, grievance or notice, HIRE48
                    reserves the right to investigate and/or take such action as
                    HIRE48 may deem appropriate. HIRE48 may reach out to the
                    User to seek further clarification or assistance with the
                    investigation, or verify the statements made in the
                    complaint, grievance or notice, and the User acknowledges
                    that timely assistance with the investigation would
                    facilitate the redressal of the same.
                  </p>

                  <p>
                    The name and title of the Grievance Redressal Officer is as
                    follows:
                  </p>

                  <p>Name: Priya</p>

                  <p>Email:101.grievance@hire48.com</p>

                  <p>
                    The Grievance Officer identified above pursuant to the
                    provisions of applicable laws including but not limited to
                    the Information Technology Act, 2000 and the Consumer
                    Protection Act, 2019, and the Rules enacted under those
                    laws. The Company reserves the right to replace the
                    Grievance Redressal Officer at its discretion through
                    publication of the name and title of such replacement on the
                    website, which replacement shall come into effect
                    immediately upon publication.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TermAndConditions;
