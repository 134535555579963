import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { useRef } from "react";
import axios from "axios";

import {
  additionalInfoSchema,
  awardAndCertificateSchema,
  experienceSchema,
  hobbiesSchema,
  languageSchema,
  personalDetailSchema,
  professionalSummarySchema,
} from "../../Schemas/schema";

import { educationSchema } from "../../Schemas/schema";

import {
  FaArrowAltCircleRight,
  FaArrowCircleRight,
  FaBook,
  FaDownload,
  FaEnvelope,
  FaGraduationCap,
  FaHands,
  FaLandmark,
  FaLanguage,
  FaPlus,
  FaTrash,
  FaTrophy,
  FaUpload,
  FaUserAlt,
} from "react-icons/fa";
import {
  additionalInfo,
  awardAndCertificateInfo,
  educationInfo,
  experienceInfo,
  hobbiesInfo,
  languageInfo,
  personalDetailsInfo,
  professionalSummary,
} from "../../Schema Info/info";
import performRequest from "../../APIs/request";
import CustomInput from "../CustomComponent/custom_input";
import CustomSelect from "../CustomComponent/custom_select";
import {
  additionalInfoOptions,
  educationOptions,
  readlanguageOptions,
  speaklanguageOptions,
  writelanguageOptions,
} from "../CustomComponent/custom_options";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Footer from "../../Components/Footer/footer";
import { FaFloppyDisk } from "react-icons/fa6";
import CustomTextArea from "../CustomComponent/custom_textarea";
import { Toast } from "../CustomComponent/toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Buttonloader from "../../Components/layouts/button_loader";

const FreeResumeBuilder = () => {
  const store = useSelector((store) => store?.userLogin);
  const [isResumeExist, setIsResumeExist] = useState(false);
  const [inputImage, setInputImage] = useState(null);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [profileSummary, setInputProfileSummary] = useState(
    professionalSummary.description
  );
  const [refresh, setRefresh] = useState(false);
  const [educationArray, setInputEducationArray] = useState(
    educationInfo.education
  );
  const [workExperienceArray, setWorkExperienceArray] = useState(
    experienceInfo.workExperience
  );
  const [awardAndCertificateArray, setAwardAndCertificateArray] = useState(
    awardAndCertificateInfo.awardAndCertificate
  );
  const [userDetails, setUserDetails] = useState(
    personalDetailsInfo.personalDetails
  );
  const [languagesArray, setLanguagesArray] = useState(languageInfo.languages);
  const [hobbiesString, setHobbiesString] = useState(hobbiesInfo.hobbies);

  const [skillsArray, setSkillsArray] = useState([]);
  const [additionalInfoArray, setAdditionalInfoArray] = useState(
    additionalInfo.additionalInformation
  );

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isDownloadTrue, setIsDownloadTrue] = useState();
  const [initialWidth, setInitialWidth] = useState("auto");
  useEffect(() => {
    const getUserResume = (id) => {
      performRequest("GET", `/resume/getresumebyuserid/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setIsResumeExist(true);
            if (res.data?.personalDetails?.length > 0) {
              setUserDetails(res.data.personalDetails);
              if (res.data.personalDetails?.[0].profilePicture) {
                setProgress((prev) => prev + 2.5);
              }
              if (res.data.personalDetails?.[0].workProfile) {
                setProgress((prev) => prev + 2.5);
              }
              if (res.data.personalDetails?.[0].state) {
                setProgress((prev) => prev + 2.5);
              }
              if (res.data.personalDetails?.[0].city) {
                setProgress((prev) => prev + 2.5);
              }
              setProgress((prev) => prev + 10);
            }
            if (res.data?.education?.length > 0) {
              setInputEducationArray(res.data.education);
              setProgress((prev) => prev + 10);
            }
            if (res.data?.description) {
              setInputProfileSummary(res.data.description);
              setProgress((prev) => prev + 10);
            }
            if (res.data?.skills) {
              setSkillsArray(res.data.skills);
              setProgress((prev) => prev + 10);
            }
            if (res.data.languages?.length > 0) {
              setLanguagesArray(res.data.languages);
              setProgress((prev) => prev + 10);
            }
            if (res.data.hobbies) {
              setHobbiesString(res.data.hobbies);
              setProgress((prev) => prev + 10);
            }
            if (res.data.additionalInformation?.length > 0) {
              setAdditionalInfoArray(res.data.additionalInformation);
              setProgress((prev) => prev + 10);
            }
            if (res.data.workExperience?.length > 0) {
              setWorkExperienceArray(res.data.workExperience);
              setProgress((prev) => prev + 10);
            }
            if (res.data.awardAndCertificate?.length > 0) {
              setAwardAndCertificateArray(res.data.awardAndCertificate);
              setProgress((prev) => prev + 10);
            }
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          console.error("Error", err);
        });
    };

    if (store?.user?.UserId) {
      getUserResume(store?.user?.UserId);
    }
  }, [store, refresh]);
  useEffect(() => {
    async function getByCountry(e) {
      try {
        const response = await axios.get(
          `https://api.countrystatecity.in/v1/countries`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "clBHNXFZeDZXQWp2UzA0YzJBSEF6VE00aXVUU2NwdG9DbTlRa0dhcQ==",
            },
          }
        );
        setCountry(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    getByCountry();
  }, []);
  async function getStatesByCountry(code) {
    try {
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${code}/states`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "clBHNXFZeDZXQWp2UzA0YzJBSEF6VE00aXVUU2NwdG9DbTlRa0dhcQ==",
          },
        }
      );

      setState(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  // Converting local image to url
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInputImage(file); // Set the file object to state
      const objectUrl = URL.createObjectURL(file); // Generate a URL for the file
      setImageUrl(objectUrl); // Set the URL to display the image
    }
  };

  // convert html or react component to pdf its generate an image
  const printRef = useRef();
  const handleDownloadPdf = async () => {
    setIsDownloadTrue(true);
    const element = printRef.current;
    setInitialWidth(element.style.width || "auto");
    element.style.width = "794px";
    if (!element) return;

    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
      scale: 3,
    });

    const data = canvas.toDataURL("image/png", "1.0");

    // const pdf = new jsPDF();
    const pdf = new jsPDF({
      orientation: "portrait", // or 'landscape' for landscape
      unit: "mm",
      format: "a4", // Use A4 format
      putOnlyUsedFonts: true,
      floatPrecision: 16, // Optional
    });
    const imgProperties = pdf.getImageProperties(data);
    let pdfWidth = pdf.internal.pageSize.getWidth();

    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    if (imgProperties > pdfHeight) {
      const scaleFactor = pdfHeight / imgProperties;
      pdfWidth *= scaleFactor;
    }
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
    element.style.width = initialWidth;

    setIsDownloadTrue(false);
  };

  const handleUpdateResume = async () => {
    Toast("success", "Resume details update Successfully");
  };

  // resume input section scrollable
  const divWidth = {
    height: "96vh",
    overflowX: "hidden",
    overflowY: "auto",
    // WebkitOverflowScrolling: "touch",
    // WebkitOverflowScrolling: "touch", // Enable smooth scrolling on iOS
    // scrollbarWidth: "none", // For Firefox
    // msOverflowStyle: "none", // For Internet Explorer and Edge
  };

  // handle personal detail submit

  const handleSubmitPersonalDetail = (values) => {
    let image = "";
    if (inputImage) {
      image = inputImage;
    } else {
      image = values.personalDetails[0].profilePicture;
    }
    const formData = new FormData();
    for (let key in values.personalDetails[0]) {
      if (key === "profilePicture") {
        formData.append("profilePicture", image);
      } else {
        formData.append(key, values.personalDetails[0][key]);
      }
    }

    performRequest(
      "PATCH",
      `/resume/updateResumebyuserid/${store?.user?.UserId}`,
      formData,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Personal details update Successfully");
          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  // Handle Professional Summary
  const handleProfessionalSummary = (values) => {
    let data = { description: values.description };

    performRequest(
      "PATCH",
      `/resume/updateResumebyuserid/${store?.user?.UserId}`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Professtional Summary update Successfully");
          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  // Handle Education submit
  const handleEducationSubmit = (values) => {
    let data = { education: values.education };

    performRequest(
      "PATCH",
      `/resume/updateResumebyuserid/${store?.user?.UserId}`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Education details submitted successfully");
          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  // Handle work experience submit
  const handleWorkExperienceSubmit = (values) => {
    let data = { workExperience: values.workExperience };

    performRequest(
      "PATCH",
      `/resume/updateResumebyuserid/${store?.user?.UserId}`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Work Experience Added Successfully");
          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  //  Read write and speak language submit
  const handleLanguageSubmit = (values) => {
    let data = { languages: values.languages };
    performRequest(
      "PATCH",
      `/resume/updateResumebyuserid/${store?.user?.UserId}`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Language added successfully");
          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  const handleAdditionalInfoSubmit = (values) => {
    let data = { additionalInformation: values.additionalInformation };
    performRequest(
      "PATCH",
      `/resume/updateResumebyuserid/${store?.user?.UserId}`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Additional Information added successfully");
          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        // Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  const handleHobbiesSubmit = (values) => {
    let data = { hobbies: values.hobbies };
    performRequest(
      "PATCH",
      `/resume/updateResumebyuserid/${store?.user?.UserId}`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Hobbies added successfully");
          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  // handle award and certificate
  const handleAwardAndCertificateSubmit = (values) => {
    let data = { awardAndCertificate: values.awardAndCertificate };
    performRequest(
      "PATCH",
      `/resume/updateResumebyuserid/${store?.user?.UserId}`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Award and Certificate added successfully");
          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  const handleRemove = (props, index) => {
    if (props.values.education) {
      const updatedArray = [...educationArray];
      const filterArray = updatedArray.filter((el, i) => i !== index);

      let data = { education: filterArray };
      performRequest(
        "PATCH",
        `/resume/updateResumebyuserid/${store?.user?.UserId}`,
        data
      )
        .then((res) => {
          if (res.status === 200) {
            Toast("success", "Education details removed successfully");
            setProgress(0);
            setRefresh(!refresh);
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        });
    } else if (props.values.workExperience) {
      const updatedArray = [...workExperienceArray];
      const filterArray = updatedArray.filter((el, i) => i !== index);

      let data = { workExperience: filterArray };
      performRequest(
        "PATCH",
        `/resume/updateResumebyuserid/${store?.user?.UserId}`,
        data
      )
        .then((res) => {
          if (res.status === 200) {
            Toast("success", "Award and Certificate added successfully");
            setProgress(0);
            setRefresh(!refresh);
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        });
    } else if (props.values.awardAndCertificate) {
      const updatedArray = [...awardAndCertificateArray];
      const filterArray = updatedArray.filter((el, i) => i !== index);
      let data = { awardAndCertificate: filterArray };
      performRequest(
        "PATCH",
        `/resume/updateResumebyuserid/${store?.user?.UserId}`,
        data
      )
        .then((res) => {
          if (res.status === 200) {
            Toast("success", "Award and Certificate added successfully");
            setProgress(0);
            setRefresh(!refresh);
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        });
    }
  };


  useEffect(() => {
    if (!store.isLoggedIn) {
      navigate("/account-login");
    }
  }, [store]);

  return (
    <>
      <div className="page">
        <div className="page-wrapper">
          <div className="container-xxl">
            <div className="page-body">
              <div className="section-header">
                <h2 className="section-title section-title-lg m-0 p-0">
                  BUILD YOUR JOB WINNING RESUME
                </h2>
                <p className="section-description mb-4 p-0">
                  Get the job faster with recruiters approved resume format.
                </p>
              </div>
              <div className="row row-cards">
                {/* Resume section Left Input resume */}
                <div className="col-md-5">
                  <div className="card" style={divWidth}>
                    <div className="card-body">
                      {isResumeExist ? (
                        <>
                          <div className="card card-sm my-3 bg-primary-lt card-1">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                Personal Details:
                              </h3>
                              <hr className="p-0 my-2" />
                              <div>
                                <Formik
                                  enableReinitialize
                                  initialValues={{
                                    personalDetails: userDetails,
                                  }}
                                  validationSchema={personalDetailSchema}
                                  onSubmit={handleSubmitPersonalDetail}
                                >
                                  {(props) => (
                                    <Form id="pj-main">
                                      {props.values?.personalDetails.map(
                                        (el, index) => (
                                          <React.Fragment key={index}>
                                            <fieldset>
                                              <div className="row mb-1">
                                                <label
                                                  htmlFor="image"
                                                  className="form-label col-3"
                                                >
                                                  Profile Photo
                                                </label>
                                                <div className="w-100 col">
                                                  <CustomInput
                                                    type="file"
                                                    name={`personalDetails[${index}].profilePicture`}
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                      props.setFieldValue(
                                                        `personalDetails[${index}].profilePicture`,
                                                        e.target.files[0]
                                                      );

                                                      handleImageUpload(e);
                                                      userDetails[
                                                        index
                                                      ].profilePicture =
                                                        e.target.value;
                                                      const updatedUserDetails =
                                                        [...userDetails];

                                                      setUserDetails(
                                                        updatedUserDetails
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </fieldset>
                                            <fieldset>
                                              <div className="row mb-1">
                                                <label
                                                  htmlFor="name"
                                                  className="form-label col-3 required  "
                                                >
                                                  Full Name
                                                </label>
                                                <div className="w-100 col">
                                                  <CustomInput
                                                    type="text"
                                                    placeholder="Enter Your Full Name"
                                                    name={`personalDetails[${index}].name`}
                                                    onChange={(e) => {
                                                      props.setFieldValue(
                                                        `personalDetails[${index}].name`,
                                                        e.target.value
                                                      );

                                                      userDetails[index].name =
                                                        e.target.value;
                                                      const updatedUserDetails =
                                                        [...userDetails];

                                                      setUserDetails(
                                                        updatedUserDetails
                                                      );
                                                    }}
                                                  />
                                                  {props.touched
                                                    .personalDetails?.[index]
                                                    ?.name &&
                                                    props.errors
                                                      .personalDetails?.[index]
                                                      ?.name && (
                                                      <p className="text-danger">
                                                        {
                                                          props.errors
                                                            .personalDetails?.[
                                                            index
                                                          ]?.name
                                                        }
                                                      </p>
                                                    )}
                                                </div>
                                              </div>
                                            </fieldset>
                                            <fieldset>
                                              <div className="row mb-1">
                                                <label
                                                  htmlFor="email"
                                                  className="form-label col-3 required"
                                                >
                                                  Email
                                                </label>
                                                <div className="w-100 col">
                                                  <CustomInput
                                                    type="email"
                                                    placeholder="Enter Your Email"
                                                    name={`personalDetails[${index}].email`}
                                                    onChange={(e) => {
                                                      props.setFieldValue(
                                                        `personalDetails[${index}].email`,
                                                        e.target.value
                                                      );

                                                      userDetails[index].email =
                                                        e.target.value;
                                                      const updatedUserDetails =
                                                        [...userDetails];

                                                      setUserDetails(
                                                        updatedUserDetails
                                                      );
                                                    }}
                                                  />
                                                  {props.touched
                                                    .personalDetails?.[index]
                                                    ?.email &&
                                                    props.errors
                                                      .personalDetails?.[index]
                                                      ?.email && (
                                                      <p className="text-danger">
                                                        {
                                                          props.errors
                                                            .personalDetails?.[
                                                            index
                                                          ]?.email
                                                        }
                                                      </p>
                                                    )}
                                                </div>
                                              </div>
                                            </fieldset>
                                            <fieldset>
                                              <div className="row mb-1">
                                                <label
                                                  htmlFor="mobile"
                                                  className="form-label col-3 required"
                                                >
                                                  Mobile No.
                                                </label>
                                                <div className="w-100 col">
                                                  <CustomInput
                                                    type="text"
                                                    placeholder="Enter Your Mobile Number"
                                                    id="mobile"
                                                    name={`personalDetails[${index}].mobile`}
                                                    onChange={(e) => {
                                                      props.setFieldValue(
                                                        `personalDetails[${index}].mobile`,
                                                        e.target.value
                                                      );

                                                      userDetails[
                                                        index
                                                      ].mobile = e.target.value;
                                                      const updatedUserDetails =
                                                        [...userDetails];

                                                      setUserDetails(
                                                        updatedUserDetails
                                                      );
                                                    }}
                                                  />
                                                  {props.touched
                                                    .personalDetails?.[index]
                                                    ?.mobile &&
                                                    props.errors
                                                      .personalDetails?.[index]
                                                      ?.mobile && (
                                                      <p className="text-danger">
                                                        {
                                                          props.errors
                                                            .personalDetails?.[
                                                            index
                                                          ]?.mobile
                                                        }
                                                      </p>
                                                    )}
                                                </div>
                                              </div>
                                            </fieldset>
                                            <fieldset>
                                              <div className="row mb-1">
                                                <label
                                                  htmlFor="profile"
                                                  className="form-label col-3 "
                                                >
                                                  Job Profile
                                                </label>
                                                <div className="w-100 col">
                                                  <CustomInput
                                                    type="text"
                                                    placeholder="Enter your profile e.g Sales Executive"
                                                    id="workProfile"
                                                    name={`personalDetails[${index}].workProfile`}
                                                    onChange={(e) => {
                                                      props.setFieldValue(
                                                        `personalDetails[${index}].workProfile`,
                                                        e.target.value
                                                      );

                                                      userDetails[
                                                        index
                                                      ].workProfile =
                                                        e.target.value;
                                                      const updatedUserDetails =
                                                        [...userDetails];

                                                      setUserDetails(
                                                        updatedUserDetails
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </fieldset>
                                            <fieldset>
                                              <div className="row mb-1">
                                                <label
                                                  htmlFor="country name"
                                                  className="form-label col-3 "
                                                >
                                                  Country
                                                </label>
                                                <div className="w-100 col">
                                                  <CustomSelect
                                                    type="text"
                                                    name={`personalDetails[${index}].country`}
                                                    onChange={(e) => {
                                                      const selectedOption =
                                                        e.target.options[
                                                          e.target.selectedIndex
                                                        ];
                                                      const code =
                                                        selectedOption.getAttribute(
                                                          "data-code"
                                                        );
                                                      props.setFieldValue(
                                                        `personalDetails[${index}].country`,
                                                        e.target.value
                                                      );

                                                      userDetails[
                                                        index
                                                      ].country =
                                                        e.target.value;
                                                      const updatedUserDetails =
                                                        [...userDetails];

                                                      setUserDetails(
                                                        updatedUserDetails
                                                      );

                                                      getStatesByCountry(code);
                                                    }}
                                                  >
                                                    <option value="">
                                                      Select country
                                                    </option>

                                                    {country?.map((option) => (
                                                      <option
                                                        key={option.id}
                                                        value={option.name}
                                                        data-code={option.iso2}
                                                      >
                                                        {option.name}
                                                      </option>
                                                    ))}
                                                  </CustomSelect>
                                                </div>
                                              </div>
                                            </fieldset>
                                            <fieldset>
                                              <div className="row mb-1">
                                                <label
                                                  htmlFor="state"
                                                  className="form-label col-3"
                                                >
                                                  State
                                                </label>
                                                <div className="w-100 col">
                                                  <CustomSelect
                                                    type="text"
                                                    name={`personalDetails[${index}].state`}
                                                    onChange={(e) => {
                                                      props.setFieldValue(
                                                        `personalDetails[${index}].state`,
                                                        e.target.value
                                                      );

                                                      userDetails[index].state =
                                                        e.target.value;
                                                      const updatedUserDetails =
                                                        [...userDetails];

                                                      setUserDetails(
                                                        updatedUserDetails
                                                      );
                                                    }}
                                                  >
                                                    <option value="">
                                                      Select state
                                                    </option>

                                                    {state?.map((option) => (
                                                      <option
                                                        key={option.id}
                                                        value={option.name}
                                                      >
                                                        {option.name}
                                                      </option>
                                                    ))}
                                                  </CustomSelect>
                                                </div>
                                              </div>
                                            </fieldset>
                                            <fieldset>
                                              <div className="row mb-1">
                                                <label
                                                  htmlFor="city"
                                                  className="form-label col-3 "
                                                >
                                                  City
                                                </label>
                                                <div className="w-100 col">
                                                  <CustomInput
                                                    type="text"
                                                    placeholder="Enter your city"
                                                    id="city"
                                                    name={`personalDetails[${index}].city`}
                                                    onChange={(e) => {
                                                      props.setFieldValue(
                                                        `personalDetails[${index}].city`,
                                                        e.target.value
                                                      );

                                                      userDetails[index].city =
                                                        e.target.value;
                                                      const updatedUserDetails =
                                                        [...userDetails];

                                                      setUserDetails(
                                                        updatedUserDetails
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </fieldset>
                                            <fieldset>
                                              <div className="text-end">
                                                <button
                                                  className="btn btn-primary m-1"
                                                  type="submit"
                                                  onClick={() =>
                                                    setProgress((prev) => 0)
                                                  }
                                                >
                                                  <FaFloppyDisk
                                                    fontSize={"1em"}
                                                    className="me-2"
                                                  />{" "}
                                                  Save
                                                </button>
                                              </div>
                                            </fieldset>
                                          </React.Fragment>
                                        )
                                      )}
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                          {/* // Professional summary card  */}
                          <div className="card card-sm my-3 bg-primary-lt  card-2">
                            <div className="card-body">
                              <div>
                                <Formik
                                  enableReinitialize
                                  initialValues={{
                                    description: profileSummary,
                                  }}
                                  validationSchema={professionalSummarySchema}
                                  onSubmit={handleProfessionalSummary}
                                >
                                  {(props) => (
                                    <Form id="ps-main">
                                      <fieldset>
                                        <div className="row mb-1">
                                          <label
                                            htmlFor="description"
                                            className="form-label col-3 "
                                          >
                                            Professional Summary:
                                          </label>
                                          <div className="w-100 col">
                                            <CustomTextArea
                                              as="textarea"
                                              type="text"
                                              style={{
                                                height: "auto",
                                                maxHeight: "content",
                                              }}
                                              name="description"
                                              value={props.values.description}
                                              onChange={(e) => {
                                                props.setFieldValue(
                                                  "description",
                                                  e.target.value
                                                );
                                                // userDetails.description  = e.target.value
                                                setInputProfileSummary(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            {props.touched.description &&
                                              props.errors.description && (
                                                <p className="text-danger">
                                                  {props.errors.description}
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                      </fieldset>

                                      <fieldset>
                                        <div className="text-end">
                                          <button
                                            className="btn btn-primary m-1"
                                            type="submit"
                                            onClick={() => setProgress(0)}
                                          >
                                            <FaFloppyDisk
                                              fontSize={"1em"}
                                              className="me-2"
                                            />{" "}
                                            Save
                                          </button>
                                        </div>
                                      </fieldset>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                          {/* Education Detail */}

                          <div className="card card-sm my-3 bg-primary-lt card-3">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                Add Education:
                              </h3>
                              <hr className="p-0 my-2" />
                              <Formik
                                enableReinitialize
                                initialValues={{ education: educationArray }}
                                validationSchema={educationSchema}
                                onSubmit={handleEducationSubmit}
                              >
                                {(props) => (
                                  <Form id="pj-main">
                                    <FieldArray name="education">
                                      {({ push, remove }) => (
                                        <div>
                                          {props.values.education?.map(
                                            (el, index) => (
                                              <React.Fragment key={index}>
                                                <div>
                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="degree"
                                                        className="form-label  col-3 "
                                                      >
                                                        Degree
                                                      </label>
                                                      <div className="w-100 col">
                                                        <CustomSelect
                                                          type="text"
                                                          name={`education[${index}].educationSelect1`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `education[${index}].educationSelect1`,
                                                              e.target.value
                                                            );
                                                            educationArray[
                                                              index
                                                            ].educationSelect1 =
                                                              e.target.value;
                                                            const updatedEducationArray =
                                                              [
                                                                ...educationArray,
                                                              ];

                                                            setInputEducationArray(
                                                              updatedEducationArray
                                                            );
                                                          }}
                                                        >
                                                          <option value="">
                                                            Select Education
                                                          </option>

                                                          {educationOptions?.map(
                                                            (option) => (
                                                              <option
                                                                key={
                                                                  option.value
                                                                }
                                                                value={
                                                                  option.value
                                                                }
                                                              >
                                                                {option.label}
                                                              </option>
                                                            )
                                                          )}
                                                        </CustomSelect>
                                                        {props.touched
                                                          .education?.[index]
                                                          ?.educationSelect1 &&
                                                          props.errors
                                                            .education?.[index]
                                                            ?.educationSelect1 && (
                                                            <p className="text-danger">
                                                              {
                                                                props.errors
                                                                  .education?.[
                                                                  index
                                                                ]
                                                                  ?.educationSelect1
                                                              }
                                                            </p>
                                                          )}
                                                      </div>
                                                    </div>
                                                  </fieldset>
                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="email"
                                                        className="form-label col-3 "
                                                      >
                                                        University
                                                      </label>
                                                      <div className="w-100 col">
                                                        <CustomInput
                                                          type="text"
                                                          placeholder="Enter your university"
                                                          id={`education[${index}].university_1`}
                                                          name={`education[${index}].university_1`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `education[${index}].university_1`,
                                                              e.target.value
                                                            );
                                                            educationArray[
                                                              index
                                                            ].university_1 =
                                                              e.target.value;
                                                            const updatedEducationArray =
                                                              [
                                                                ...educationArray,
                                                              ];

                                                            setInputEducationArray(
                                                              updatedEducationArray
                                                            );
                                                          }}
                                                        />
                                                        {props.touched
                                                          .education?.[index]
                                                          ?.university_1 &&
                                                          props.errors
                                                            .education?.[index]
                                                            ?.university_1 && (
                                                            <p className="text-danger">
                                                              {
                                                                props.errors
                                                                  .education?.[
                                                                  index
                                                                ].university_1
                                                              }
                                                            </p>
                                                          )}
                                                      </div>
                                                    </div>
                                                  </fieldset>
                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="mobile"
                                                        className="form-label col-3 "
                                                      >
                                                        Start Month
                                                      </label>
                                                      <div className="w-100 col">
                                                        <Field
                                                          type="month"
                                                          placeholder="Enter start month"
                                                          id={`education[${index}].startDate1`}
                                                          className="form-control"
                                                          name={`education[${index}].startDate1`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `education[${index}].startDate1`,
                                                              e.target.value
                                                            );
                                                            educationArray[
                                                              index
                                                            ].startDate1 =
                                                              e.target.value;
                                                            const updatedEducationArray =
                                                              [
                                                                ...educationArray,
                                                              ];

                                                            setInputEducationArray(
                                                              updatedEducationArray
                                                            );
                                                          }}
                                                        />
                                                        {props.touched
                                                          .education?.[index]
                                                          ?.startDate1 &&
                                                          props.errors
                                                            .education?.[index]
                                                            ?.startDate1 && (
                                                            <p className="text-danger">
                                                              {
                                                                props.errors
                                                                  .education?.[
                                                                  index
                                                                ].startDate1
                                                              }
                                                            </p>
                                                          )}
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="end month"
                                                        className="form-label  col-3 "
                                                      >
                                                        End Month
                                                      </label>
                                                      <div className="w-100 col">
                                                        <CustomInput
                                                          type="month"
                                                          placeholder="Enter end month"
                                                          id={`education[${index}].endDate1`}
                                                          className="form-control"
                                                          name={`education[${index}].endDate1`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `education[${index}].endDate1`,
                                                              e.target.value
                                                            );
                                                            educationArray[
                                                              index
                                                            ].endDate1 =
                                                              e.target.value;
                                                            const updatedEducationArray =
                                                              [
                                                                ...educationArray,
                                                              ];

                                                            setInputEducationArray(
                                                              updatedEducationArray
                                                            );
                                                          }}
                                                        />
                                                        {props.touched
                                                          .education?.[index]
                                                          ?.endDate1 &&
                                                          props.errors
                                                            .education?.[index]
                                                            ?.endDate1 && (
                                                            <p className="text-danger">
                                                              {
                                                                props.errors
                                                                  .education?.[
                                                                  index
                                                                ]?.endDate1
                                                              }
                                                            </p>
                                                          )}
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="education description"
                                                        className="form-label  col-3 "
                                                      >
                                                        Description
                                                      </label>
                                                      <div className="w-100 col">
                                                        <Field
                                                          as="textarea"
                                                          data-bs-toggle="autosize"
                                                          className="form-control"
                                                          name={`education[${index}].summary`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `education[${index}].summary`,
                                                              e.target.value
                                                            );
                                                            educationArray[
                                                              index
                                                            ].summary =
                                                              e.target.value;
                                                            const updatedEducationArray =
                                                              [
                                                                ...educationArray,
                                                              ];

                                                            setInputEducationArray(
                                                              updatedEducationArray
                                                            );
                                                          }}
                                                        />
                                                        {props.touched
                                                          .education?.[index]
                                                          ?.summary &&
                                                          props.errors
                                                            .education?.[index]
                                                            ?.summary && (
                                                            <p className="text-danger">
                                                              {
                                                                props.errors
                                                                  .education?.[
                                                                  index
                                                                ].summary
                                                              }
                                                            </p>
                                                          )}
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div className="text-end">
                                                      <button
                                                        className="btn btn-primary m-1 ml-3"
                                                        type="submit"
                                                        onClick={() =>
                                                          setProgress(
                                                            (prev) => 0
                                                          )
                                                        }
                                                      >
                                                        <FaFloppyDisk
                                                          fontSize={"1em"}
                                                          className="me-2"
                                                        />{" "}
                                                        Save
                                                      </button>
                                                    </div>
                                                  </fieldset>
                                                </div>
                                                <hr className="my-2 p-0" />
                                                <div>
                                                  {index ===
                                                  props.values.education
                                                    .length -
                                                    1 ? (
                                                    <button
                                                      className="btn m-1"
                                                      type="button"
                                                      onClick={() => {
                                                        const updatedEducationArray =
                                                          [
                                                            ...educationArray,
                                                            {
                                                              educationSelect1:
                                                                "",
                                                              university_1: "",
                                                              startDate1: "",
                                                              endDate1: "",
                                                              summary: "",
                                                            },
                                                          ];

                                                        setInputEducationArray(
                                                          updatedEducationArray
                                                        );

                                                        push({
                                                          educationSelect1: "",
                                                          university_1: "",
                                                          startDate1: "",
                                                          endDate1: "",
                                                          summary: "",
                                                        });
                                                      }}
                                                    >
                                                      <FaPlus
                                                        fontSize={"1em"}
                                                        className="me-2"
                                                      />{" "}
                                                      Add More Education
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="btn m-1"
                                                      type="button"
                                                      onClick={() => {
                                                        index = index + 1;
                                                        remove(index);
                                                        handleRemove(
                                                          props,
                                                          index
                                                        );
                                                        const updatedEducationArray =
                                                          [...educationArray];
                                                        const filterEducationArray =
                                                          updatedEducationArray.filter(
                                                            (el, i) =>
                                                              i !== index
                                                          );

                                                        setInputEducationArray(
                                                          filterEducationArray
                                                        );
                                                      }}
                                                    >
                                                      <FaTrash
                                                        fontSize={"1em"}
                                                        className="me-2"
                                                      />{" "}
                                                      Remove Education
                                                    </button>
                                                  )}
                                                </div>
                                              </React.Fragment>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </FieldArray>
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          </div>

                          {/* Work Experience */}

                          <div className="card card-sm bg-primary-lt  my-3 card-4">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                Add Experience:
                              </h3>
                              <hr className="p-0 my-2" />
                              <div>
                                <Formik
                                  enableReinitialize
                                  initialValues={{
                                    workExperience: workExperienceArray,
                                  }}
                                  validationSchema={experienceSchema}
                                  onSubmit={handleWorkExperienceSubmit}
                                >
                                  {(props) => (
                                    <FieldArray name="experience">
                                      {({ push, remove }) => (
                                        <div>
                                          {props.values.workExperience?.map(
                                            (el, index) => (
                                              <React.Fragment key={index}>
                                                <div>
                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="position"
                                                        className="form-label  col-3 "
                                                      >
                                                        Title/Position
                                                      </label>
                                                      <div className="w-100 col">
                                                        <CustomInput
                                                          type="text"
                                                          placeholder="enter title position"
                                                          id={`workExperience[${index}].position`}
                                                          name={`workExperience[${index}].position`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `workExperience[${index}].position`,
                                                              e.target.value
                                                            );

                                                            workExperienceArray[
                                                              index
                                                            ].position =
                                                              e.target.value;
                                                            const updatedWorkExperienceArray =
                                                              [
                                                                ...workExperienceArray,
                                                              ];

                                                            setWorkExperienceArray(
                                                              updatedWorkExperienceArray
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="name"
                                                        className="form-label  col-3 "
                                                      >
                                                        Employement Type
                                                      </label>
                                                      <div className="w-100 col">
                                                        <CustomSelect
                                                          type="text"
                                                          placeholder="type of employment"
                                                          id={`workExperience[${index}].employmentType`}
                                                          name={`workExperience[${index}].employmentType`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `workExperience[${index}].employmentType`,
                                                              e.target.value
                                                            );
                                                            workExperienceArray[
                                                              index
                                                            ].employmentType =
                                                              e.target.value;
                                                            const updatedWorkExperienceArray =
                                                              [
                                                                ...workExperienceArray,
                                                              ];

                                                            setWorkExperienceArray(
                                                              updatedWorkExperienceArray
                                                            );
                                                          }}
                                                        >
                                                          <option value="">
                                                            Select
                                                          </option>
                                                          <option value="Full Time">
                                                            Full Time
                                                          </option>
                                                          <option value="Part Time">
                                                            Part Time
                                                          </option>
                                                          <option value="Internship">
                                                            Internship
                                                          </option>
                                                        </CustomSelect>
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="name"
                                                        className="form-label  col-3 "
                                                      >
                                                        Location Type
                                                      </label>
                                                      <div className="w-100 col">
                                                        <CustomSelect
                                                          type="text"
                                                          placeholder="hello@gmail.com"
                                                          id={`workExperience[${index}].locationType`}
                                                          name={`workExperience[${index}].locationType`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `workExperience[${index}].locationType`,
                                                              e.target.value
                                                            );
                                                            workExperienceArray[
                                                              index
                                                            ].locationType =
                                                              e.target.value;
                                                            const updatedWorkExperienceArray =
                                                              [
                                                                ...workExperienceArray,
                                                              ];

                                                            setWorkExperienceArray(
                                                              updatedWorkExperienceArray
                                                            );
                                                          }}
                                                        >
                                                          <option value="">
                                                            Select
                                                          </option>
                                                          <option value="Remote">
                                                            Remote
                                                          </option>
                                                          <option value="Work From Office">
                                                            Work From Office
                                                          </option>
                                                          <option value="Hybrid">
                                                            Hybrid
                                                          </option>
                                                        </CustomSelect>
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="email"
                                                        className="form-label  col-3 "
                                                      >
                                                        Company Name
                                                      </label>
                                                      <div className="w-100 col">
                                                        <CustomInput
                                                          type="text"
                                                          placeholder="EnterCompany "
                                                          id={`workExperience[${index}].companyName`}
                                                          name={`workExperience[${index}].companyName`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `workExperience[${index}].companyName`,
                                                              e.target.value
                                                            );
                                                            workExperienceArray[
                                                              index
                                                            ].companyName =
                                                              e.target.value;
                                                            const updatedWorkExperienceArray =
                                                              [
                                                                ...workExperienceArray,
                                                              ];

                                                            setWorkExperienceArray(
                                                              updatedWorkExperienceArray
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="state"
                                                        className="form-label col-3 "
                                                      >
                                                        Location
                                                      </label>
                                                      <div className="w-100 col">
                                                        <CustomInput
                                                          type="text"
                                                          placeholder="e.g Delhi, Pune"
                                                          id={`workExperience[${index}].location`}
                                                          name={`workExperience[${index}].location`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `workExperience[${index}].location`,
                                                              e.target.value
                                                            );
                                                            workExperienceArray[
                                                              index
                                                            ].location =
                                                              e.target.value;
                                                            const updatedWorkExperienceArray =
                                                              [
                                                                ...workExperienceArray,
                                                              ];

                                                            setWorkExperienceArray(
                                                              updatedWorkExperienceArray
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div className="row mt-1">
                                                      <div className="col-3"></div>
                                                      <div className="w-100 col">
                                                        <label className="form-check">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name={`workExperience[${index}].exitDate`}
                                                            onChange={(e) => {
                                                              
                                                              const currentValue =
                                                                props.values
                                                                  .workExperience[
                                                                  index
                                                                ].exitDate;
                                                              const newValue =
                                                                currentValue ===
                                                                "on"
                                                                  ? ""
                                                                  : "on";
                                                              props.setFieldValue(
                                                                `workExperience[${index}].exitDate`,
                                                                newValue
                                                              );

                                                              workExperienceArray[
                                                                index
                                                              ].exitDate =
                                                                e.target.value;
                                                              const updatedWorkExperienceArray =
                                                                [
                                                                  ...workExperienceArray,
                                                                ];

                                                              setWorkExperienceArray(
                                                                updatedWorkExperienceArray
                                                              );
                                                            }}
                                                          />
                                                          <span className="form-check-label">
                                                            I am currently
                                                            working here
                                                          </span>
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="mobile"
                                                        className="form-label col-3 "
                                                      >
                                                        Start Month
                                                      </label>
                                                      <div className="w-100 col">
                                                        <Field
                                                          type="month"
                                                          placeholder="12345678"
                                                          id={`workExperience[${index}].joiningDate`}
                                                          className="form-control"
                                                          name={`workExperience[${index}].joiningDate`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `workExperience[${index}].joiningDate`,
                                                              e.target.value
                                                            );
                                                            workExperienceArray[
                                                              index
                                                            ].joiningDate =
                                                              e.target.value;
                                                            const updatedWorkExperienceArray =
                                                              [
                                                                ...workExperienceArray,
                                                              ];

                                                            setWorkExperienceArray(
                                                              updatedWorkExperienceArray
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="exit month"
                                                        className="form-label  col-3 "
                                                      >
                                                        Exit Month
                                                      </label>
                                                      <div className="w-100 col">
                                                        <CustomInput
                                                          type="month"
                                                          disabled={
                                                            props.values
                                                              .workExperience &&
                                                            props.values
                                                              .workExperience[
                                                              index
                                                            ].exitDate === "on"
                                                          }
                                                          placeholder="Month"
                                                          id={`workExperience[${index}].exitDate`}
                                                          name={`workExperience[${index}].exitDate`}
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `workExperience[${index}].exitDate`,
                                                              e.target.value
                                                            );
                                                            workExperienceArray[
                                                              index
                                                            ].exitDate =
                                                              e.target.value;
                                                            const updatedWorkExperienceArray =
                                                              [
                                                                ...workExperienceArray,
                                                              ];

                                                            setWorkExperienceArray(
                                                              updatedWorkExperienceArray
                                                            );
                                                          }}
                                                        />
                                                       
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div className="row mb-1">
                                                      <label
                                                        htmlFor="description"
                                                        className="form-label  col-3 "
                                                      >
                                                        Description
                                                      </label>
                                                      <div className="w-100 col">
                                                        <Field
                                                          as="textarea"
                                                          id={`workExperience[${index}].jobDescription`}
                                                          name={`workExperience[${index}].jobDescription`}
                                                          className="form-control"
                                                          onChange={(e) => {
                                                            props.setFieldValue(
                                                              `workExperience[${index}].jobDescription`,
                                                              e.target.value
                                                            );
                                                            workExperienceArray[
                                                              index
                                                            ].jobDescription =
                                                              e.target.value;
                                                            const updatedWorkExperienceArray =
                                                              [
                                                                ...workExperienceArray,
                                                              ];

                                                            setWorkExperienceArray(
                                                              updatedWorkExperienceArray
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </fieldset>

                                                  <fieldset>
                                                    <div
                                                      key={index}
                                                      className="text-end"
                                                    >
                                                      <button
                                                        className="btn btn-primary m-1 ml-3"
                                                        type="submit"
                                                        onClick={() => {
                                                          handleWorkExperienceSubmit(
                                                            props.values
                                                          );
                                                          setProgress(0);
                                                        }}
                                                      >
                                                        <FaFloppyDisk
                                                          fontSize={"1em"}
                                                          className="me-2"
                                                        />{" "}
                                                        Save
                                                      </button>
                                                    </div>
                                                  </fieldset>
                                                </div>
                                                <hr className="my-1 p-0" />
                                                <div>
                                                  {index ===
                                                  props.values.workExperience
                                                    .length -
                                                    1 ? (
                                                    <button
                                                      className="btn m-1"
                                                      type="button"
                                                      onClick={() => {
                                                        const updatedWorkExperienceArray =
                                                          [
                                                            ...workExperienceArray,
                                                            {
                                                              companyName: "",
                                                              employmentType:
                                                                "",
                                                              joiningDate: "",
                                                              exitDate: "",
                                                              jobDescription:
                                                                "",
                                                              location: "",
                                                              locationType: "",
                                                              position: "",
                                                            },
                                                          ];

                                                        setWorkExperienceArray(
                                                          updatedWorkExperienceArray
                                                        );

                                                        push({
                                                          companyName: "",
                                                          employmentType: "",
                                                          joiningDate: "",
                                                          exitDate: "",
                                                          jobDescription: "",
                                                          location: "",
                                                          locationType: "",
                                                          position: "",
                                                        });
                                                      }}
                                                    >
                                                      <FaPlus
                                                        fontSize={"1em"}
                                                        className="me-2"
                                                      />{" "}
                                                      Add More Experience
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="btn m-1"
                                                      type="button"
                                                      onClick={() => {
                                                        index = index + 1;
                                                        remove(index);
                                                        handleRemove(
                                                          props,
                                                          index
                                                        );

                                                        const updatedWorkExperienceArray =
                                                          [
                                                            ...workExperienceArray,
                                                          ];
                                                        const filterWorkExperienceArray =
                                                          updatedWorkExperienceArray.filter(
                                                            (el, i) =>
                                                              i !== index
                                                          );

                                                        setWorkExperienceArray(
                                                          filterWorkExperienceArray
                                                        );
                                                      }}
                                                    >
                                                      <FaTrash
                                                        fontSize={"1em"}
                                                        className="me-2"
                                                      />{" "}
                                                      Remove Experience
                                                    </button>
                                                  )}
                                                </div>
                                              </React.Fragment>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </FieldArray>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>

                          {/* Languages  section*/}
                          <div className="card card-sm bg-primary-lt  my-3 card-4">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                Add Languages:
                              </h3>
                              <hr className="p-0 my-2" />
                              <div>
                                <Formik
                                  enableReinitialize
                                  initialValues={{ languages: languagesArray }}
                                  validationSchema={languageSchema}
                                  onSubmit={handleLanguageSubmit}
                                >
                                  {(props) => (
                                    <Form id="pj-main">
                                      <fieldset>
                                        <div className="row my-1">
                                          <label
                                            htmlFor="read language"
                                            className="form-label col-3 "
                                          >
                                            Read
                                          </label>
                                          <div className="w-100 col">
                                            <div className="card card-sm mb-2">
                                              <div className="card-body">
                                                {props.values.languages?.[0]
                                                  ?.readLanguage &&
                                                  props.values.languages?.[0]?.readLanguage?.map(
                                                    (el, index) => {
                                                      return (
                                                        <span
                                                          className="m-1 tag"
                                                          key={index}
                                                        >
                                                          {el}

                                                          <button
                                                            onClick={(e) => {
                                                              const filterSkill =
                                                                props.values.languages?.[0].readLanguage.filter(
                                                                  (el, i) => {
                                                                    return (
                                                                      i !==
                                                                      index
                                                                    );
                                                                  }
                                                                );

                                                              setLanguagesArray(
                                                                (
                                                                  prevLanguages
                                                                ) => {
                                                                  return prevLanguages.map(
                                                                    (
                                                                      language,
                                                                      index
                                                                    ) =>
                                                                      index ===
                                                                      0
                                                                        ? {
                                                                            ...language,
                                                                            readLanguage:
                                                                              filterSkill,
                                                                          }
                                                                        : language
                                                                  );
                                                                }
                                                              );

                                                              props.setFieldValue(
                                                                `languages[0].readLanguage`,
                                                                filterSkill
                                                              );
                                                            }}
                                                            className="btn-close m-0"
                                                          ></button>
                                                        </span>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </div>

                                            <CustomSelect
                                              type="text"
                                              name={`languages[0].readLanguage`}
                                              onChange={(e) => {
                                                let value =
                                                  props.values.languages?.[0]
                                                    .readLanguage || [];

                                                let newValue = [
                                                  ...value,
                                                  e.target.value,
                                                ];

                                                setLanguagesArray(
                                                  (prevLanguages) => {
                                                    return prevLanguages.map(
                                                      (language, index) =>
                                                        index === 0
                                                          ? {
                                                              ...language,
                                                              readLanguage:
                                                                newValue,
                                                            }
                                                          : language
                                                    );
                                                  }
                                                );

                                                props.setFieldValue(
                                                  `languages[0].readLanguage`,

                                                  newValue
                                                );
                                              }}
                                            >
                                              {readlanguageOptions?.map(
                                                (option) => (
                                                  <option
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </option>
                                                )
                                              )}
                                            </CustomSelect>
                                            {props.touched.languages?.[0]
                                              ?.readLanguage &&
                                              props.errors.languages?.[0]
                                                ?.readLanguage && (
                                                <p className="text-danger">
                                                  {
                                                    props.errors.languages?.[0]
                                                      ?.readLanguage
                                                  }
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                      </fieldset>

                                      <fieldset>
                                        <div className="row my-1">
                                          <label
                                            htmlFor="mobile"
                                            className="form-label  col-3 "
                                          >
                                            Write
                                          </label>
                                          <div className="w-100 col">
                                            <div className="card card-sm mb-2">
                                              <div className="card-body">
                                                {props.values.languages?.[0]
                                                  ?.languageWrite &&
                                                  props.values.languages?.[0]?.languageWrite?.map(
                                                    (el, index) => {
                                                      return (
                                                        <span
                                                          className="m-1 tag"
                                                          key={index}
                                                        >
                                                          {el}

                                                          <button
                                                            onClick={(e) => {
                                                              const filterSkill =
                                                                props.values.languages?.[0].languageWrite.filter(
                                                                  (el, i) => {
                                                                    return (
                                                                      i !==
                                                                      index
                                                                    );
                                                                  }
                                                                );

                                                              setLanguagesArray(
                                                                (
                                                                  prevLanguages
                                                                ) => {
                                                                  return prevLanguages.map(
                                                                    (
                                                                      language,
                                                                      index
                                                                    ) =>
                                                                      index ===
                                                                      0
                                                                        ? {
                                                                            ...language,
                                                                            languageWrite:
                                                                              filterSkill,
                                                                          }
                                                                        : language
                                                                  );
                                                                }
                                                              );

                                                              props.setFieldValue(
                                                                `languages[0].languageWrite`,
                                                                filterSkill
                                                              );
                                                            }}
                                                            className="btn-close m-0"
                                                          ></button>
                                                        </span>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </div>

                                            <CustomSelect
                                              type="text"
                                              name={`languages[0].languageWrite`}
                                              onChange={(e) => {
                                                let value =
                                                  props.values.languages?.[0]
                                                    .languageWrite || [];

                                                let newValue = [
                                                  ...value,
                                                  e.target.value,
                                                ];

                                                setLanguagesArray(
                                                  (prevLanguages) => {
                                                    return prevLanguages.map(
                                                      (language, index) =>
                                                        index === 0
                                                          ? {
                                                              ...language,
                                                              languageWrite:
                                                                newValue,
                                                            }
                                                          : language
                                                    );
                                                  }
                                                );

                                                props.setFieldValue(
                                                  `languages[0].languageWrite`,

                                                  newValue
                                                );
                                              }}
                                            >
                                              {writelanguageOptions?.map(
                                                (option) => (
                                                  <option
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </option>
                                                )
                                              )}
                                            </CustomSelect>
                                            {props.touched.languages?.[0]
                                              ?.languageWrite &&
                                              props.errors.languages?.[0]
                                                ?.languageWrite && (
                                                <p className="text-danger">
                                                  {
                                                    props.errors.languages?.[0]
                                                      ?.languageWrite
                                                  }
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                      </fieldset>

                                      <fieldset>
                                        <div className="row my-1">
                                          <label
                                            htmlFor="mobile"
                                            className="form-label  col-3 "
                                          >
                                            Speak
                                          </label>
                                          <div className="w-100 col">
                                            <div className="card card-sm mb-2">
                                              <div className="card-body">
                                                {props.values.languages?.[0]
                                                  ?.languageSpeak &&
                                                  props.values.languages?.[0]?.languageSpeak?.map(
                                                    (el, index) => {
                                                      return (
                                                        <span
                                                          className="m-1 tag"
                                                          key={index}
                                                        >
                                                          {el}

                                                          <button
                                                            onClick={(e) => {
                                                              const filterSkill =
                                                                props.values.languages?.[0]?.languageSpeak.filter(
                                                                  (el, i) => {
                                                                    return (
                                                                      i !==
                                                                      index
                                                                    );
                                                                  }
                                                                );

                                                              setLanguagesArray(
                                                                (
                                                                  prevLanguages
                                                                ) => {
                                                                  return prevLanguages.map(
                                                                    (
                                                                      language,
                                                                      index
                                                                    ) =>
                                                                      index ===
                                                                      0
                                                                        ? {
                                                                            ...language,
                                                                            languageSpeak:
                                                                              filterSkill,
                                                                          }
                                                                        : language
                                                                  );
                                                                }
                                                              );

                                                              props.setFieldValue(
                                                                `languages[0].languageSpeak`,
                                                                filterSkill
                                                              );
                                                            }}
                                                            className="btn-close m-0"
                                                          ></button>
                                                        </span>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                            <CustomSelect
                                              type="text"
                                              name={`languages[0].languageSpeak`}
                                              onChange={(e) => {
                                                let value =
                                                  props.values.languages?.[0]
                                                    .languageSpeak || [];

                                                let newValue = [
                                                  ...value,
                                                  e.target.value,
                                                ];

                                                setLanguagesArray(
                                                  (prevLanguages) => {
                                                    return prevLanguages.map(
                                                      (language, index) =>
                                                        index === 0
                                                          ? {
                                                              ...language,
                                                              languageSpeak:
                                                                newValue,
                                                            }
                                                          : language
                                                    );
                                                  }
                                                );

                                                props.setFieldValue(
                                                  "languages[0].languageSpeak",
                                                  newValue
                                                );
                                              }}
                                            >
                                              {speaklanguageOptions?.map(
                                                (option) => (
                                                  <option
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </option>
                                                )
                                              )}
                                            </CustomSelect>
                                            {props.touched.languages?.[0]
                                              ?.languageSpeak &&
                                              props.errors.languages?.[0]
                                                ?.languageSpeak && (
                                                <p className="text-danger">
                                                  {
                                                    props.errors.languages?.[0]
                                                      ?.languageSpeak
                                                  }
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                      </fieldset>

                                      <fieldset>
                                        <div className="text-end">
                                          <button
                                            className="btn  btn-primary m-1 ml-3"
                                            type="submit"
                                            onClick={() => setProgress(0)}
                                          >
                                            <FaFloppyDisk
                                              fontSize={"1em"}
                                              className="me-2"
                                            />{" "}
                                            Save
                                          </button>
                                        </div>
                                      </fieldset>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>

                          {/* Additional Informaiton */}

                          <div className="card card-sm bg-primary-lt  my-3 card-4">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                {" "}
                                Additional Information:
                              </h3>
                              <hr className="p-0 my-2" />
                              <div>
                                <Formik
                                  enableReinitialize
                                  initialValues={{
                                    additionalInformation: additionalInfoArray,
                                  }}
                                  validationSchema={additionalInfoSchema}
                                  onSubmit={handleAdditionalInfoSubmit}
                                >
                                  {(props) => (
                                    <Form id="pj-main">
                                      <fieldset>
                                        <div className="row my-1">
                                          <label
                                            htmlFor="read language"
                                            className="form-label col-3 "
                                          >
                                            Information
                                          </label>
                                          <div className="w-100 col">
                                            <div className="card card-sm mb-2">
                                              <div className="card-body">
                                                {props.values
                                                  ?.additionalInformation &&
                                                  props.values.additionalInformation?.map(
                                                    (el, index) => {
                                                      return (
                                                        <span
                                                          className="m-1 tag"
                                                          key={index}
                                                        >
                                                          {el}

                                                          <button
                                                            onClick={(e) => {
                                                              const filterInfo =
                                                                props.values.additionalInformation?.filter(
                                                                  (el, i) => {
                                                                    return (
                                                                      i !==
                                                                      index
                                                                    );
                                                                  }
                                                                );

                                                              setAdditionalInfoArray(
                                                                filterInfo
                                                              );

                                                              props.setFieldValue(
                                                                `additionalInformation`,
                                                                filterInfo
                                                              );
                                                            }}
                                                            className="btn-close m-0"
                                                          ></button>
                                                        </span>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </div>

                                            <CustomSelect
                                              type="text"
                                              name={`additionalInformation`}
                                              onChange={(e) => {
                                                let value =
                                                  props.values
                                                    .additionalInformation ||
                                                  [];

                                                let newValue = [
                                                  ...value,
                                                  e.target.value,
                                                ];

                                                setAdditionalInfoArray(
                                                  newValue
                                                );

                                                props.setFieldValue(
                                                  `additionalInformation`,

                                                  newValue
                                                );
                                              }}
                                            >
                                              {additionalInfoOptions?.map(
                                                (option) => (
                                                  <option
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </option>
                                                )
                                              )}
                                            </CustomSelect>
                                            {props.touched
                                              .additionalInformation &&
                                              props.errors
                                                .additionalInformation && (
                                                <p className="text-danger">
                                                  {
                                                    props.errors
                                                      .additionalInformation
                                                  }
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                      </fieldset>

                                      <fieldset>
                                        <div className="text-end">
                                          <button
                                            className="btn  btn-primary m-1 ml-3"
                                            type="submit"
                                            onClick={() => setProgress(0)}
                                          >
                                            <FaFloppyDisk
                                              fontSize={"1em"}
                                              className="me-2"
                                            />{" "}
                                            Save
                                          </button>
                                        </div>
                                      </fieldset>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>

                          {/* {awc} Awards and Certficate section */}
                          <div className="card card-sm my-3 bg-primary-lt card-3">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                Add Awards & Certificates:
                              </h3>
                              <hr className="p-0 my-2" />
                              <div>
                                <Formik
                                  enableReinitialize
                                  initialValues={{
                                    awardAndCertificate:
                                      awardAndCertificateArray,
                                  }}
                                  validationSchema={awardAndCertificateSchema}
                                  onSubmit={handleAwardAndCertificateSubmit}
                                >
                                  {(props) => (
                                    <Form>
                                      <FieldArray name="awardAndCertificate">
                                        {({ push, remove }) => (
                                          <div>
                                            {props.values.awardAndCertificate?.map(
                                              (el, index) => (
                                                <React.Fragment key={index}>
                                                  <div>
                                                    {/* Award and Certification Name */}

                                                    <fieldset>
                                                      <div className="row mb-1">
                                                        <label
                                                          htmlFor="award name"
                                                          className="form-label  col-3 "
                                                        >
                                                          Name
                                                        </label>
                                                        <div className="w-100 col">
                                                          <CustomInput
                                                            type="text"
                                                            placeholder="Award and Certification name"
                                                            id={`awardAndCertificateArray[${index}].awardAndCertificateName`}
                                                            name={`awardAndCertificate[${index}].awardAndCertificateName`}
                                                            onChange={(e) => {
                                                              props.setFieldValue(
                                                                `awardAndCertificate[${index}].awardAndCertificateName`,
                                                                e.target.value
                                                              );

                                                              awardAndCertificateArray[
                                                                index
                                                              ].awardAndCertificateName =
                                                                e.target.value;
                                                              const updatedAwardArray =
                                                                [
                                                                  ...awardAndCertificateArray,
                                                                ];

                                                              setAwardAndCertificateArray(
                                                                updatedAwardArray
                                                              );
                                                            }}
                                                          />
                                                          {props.touched
                                                            ?.awardAndCertificate?.[
                                                            index
                                                          ]
                                                            ?.awardAndCertificateName &&
                                                            props.errors
                                                              ?.awardAndCertificate?.[
                                                              index
                                                            ]
                                                              ?.awardAndCertificateName && (
                                                              <p className="text-danger">
                                                                {
                                                                  props.errors
                                                                    ?.awardAndCertificate?.[
                                                                    index
                                                                  ]
                                                                    ?.awardAndCertificateName
                                                                }
                                                              </p>
                                                            )}
                                                        </div>
                                                      </div>
                                                    </fieldset>

                                                    {/* Award and Certificate Given By */}
                                                    <fieldset>
                                                      <div className="row mb-1">
                                                        <label
                                                          htmlFor={`awardAndCertificate[${index}].givenBy`}
                                                          className="form-label  col-3 "
                                                        >
                                                          Given By
                                                        </label>
                                                        <div className="w-100 col">
                                                          <CustomInput
                                                            type="text"
                                                            placeholder="Award given by"
                                                            id={`awardAndCertificate[${index}].givenBy`}
                                                            name={`awardAndCertificate[${index}].givenBy`}
                                                            onChange={(e) => {
                                                              props.setFieldValue(
                                                                `awardAndCertificate[${index}].givenBy`,
                                                                e.target.value
                                                              );
                                                              awardAndCertificateArray[
                                                                index
                                                              ].givenBy =
                                                                e.target.value;
                                                              const updatedAwardArray =
                                                                [
                                                                  ...awardAndCertificateArray,
                                                                ];

                                                              setAwardAndCertificateArray(
                                                                updatedAwardArray
                                                              );
                                                            }}
                                                          />
                                                          {props.touched
                                                            ?.awardAndCertificate?.[
                                                            index
                                                          ]?.givenBy &&
                                                            props.errors
                                                              ?.awardAndCertificate?.[
                                                              index
                                                            ]?.givenBy && (
                                                              <p className="text-danger">
                                                                {
                                                                  props.errors
                                                                    ?.awardAndCertificate?.[
                                                                    index
                                                                  ]?.givenBy
                                                                }
                                                              </p>
                                                            )}
                                                        </div>
                                                      </div>
                                                    </fieldset>

                                                    {/* Award and Certificate Description */}
                                                    <fieldset>
                                                      <div className="row mb-1">
                                                        <label
                                                          htmlFor={`awardAndCertificate[${index}].acDescription`}
                                                          className="form-label  col-3 "
                                                        >
                                                          Description
                                                        </label>
                                                        <div className="w-100 col">
                                                          <CustomInput
                                                            as="textarea"
                                                            type="text"
                                                            name={`awardAndCertificate[${index}].acDescription`}
                                                            id={`awardAndCertificate[${index}].acDescription`}
                                                            onChange={(e) => {
                                                              props.setFieldValue(
                                                                `awardAndCertificate[${index}].acDescription`,
                                                                e.target.value
                                                              );

                                                              awardAndCertificateArray[
                                                                index
                                                              ].acDescription =
                                                                e.target.value;
                                                              const updatedAwardArray =
                                                                [
                                                                  ...awardAndCertificateArray,
                                                                ];

                                                              setAwardAndCertificateArray(
                                                                updatedAwardArray
                                                              );
                                                            }}
                                                          />
                                                          {props.touched
                                                            ?.awardAndCertificate?.[
                                                            index
                                                          ]?.acDescription &&
                                                            props.errors
                                                              ?.awardAndCertificate?.[
                                                              index
                                                            ]
                                                              ?.acDescription && (
                                                              <p className="text-danger">
                                                                {
                                                                  props.errors
                                                                    ?.awardAndCertificate?.[
                                                                    index
                                                                  ]
                                                                    ?.acDescription
                                                                }
                                                              </p>
                                                            )}
                                                        </div>
                                                      </div>
                                                    </fieldset>

                                                    {/* Buttons */}
                                                    <fieldset>
                                                      <div className="text-end">
                                                        <button
                                                          className="btn btn-primary m-1 ml-3"
                                                          type="submit"
                                                          onClick={() =>
                                                            setProgress(0)
                                                          }
                                                        >
                                                          <FaFloppyDisk
                                                            fontSize={"1em"}
                                                            className="me-2"
                                                          />{" "}
                                                          Save
                                                        </button>
                                                      </div>
                                                    </fieldset>
                                                  </div>
                                                  <hr className="my-1 p-0" />

                                                  <div>
                                                    {index ===
                                                    props.values
                                                      .awardAndCertificate
                                                      .length -
                                                      1 ? (
                                                      <button
                                                        className="btn m-1"
                                                        type="button"
                                                        onClick={() => {
                                                          const updatedAwardArray =
                                                            [
                                                              ...awardAndCertificateArray,
                                                              {
                                                                awardAndCertificateName:
                                                                  "",
                                                                givenBy: "",
                                                                acDescription:
                                                                  "",
                                                              },
                                                            ];

                                                          setAwardAndCertificateArray(
                                                            updatedAwardArray
                                                          );

                                                          push({
                                                            awardAndCertificateName:
                                                              "",
                                                            givenBy: "",
                                                            acDescription: "",
                                                          });
                                                        }}
                                                      >
                                                        <FaPlus
                                                          fontSize={"1em"}
                                                          className="me-2"
                                                        />{" "}
                                                        Add More Awards &
                                                        Certificates
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="btn m-1"
                                                        type="button"
                                                        onClick={() => {
                                                          index = index + 1;
                                                          remove(index);

                                                          const updatedAwardArray =
                                                            [
                                                              ...awardAndCertificateArray,
                                                            ];
                                                          const filterAwardArray =
                                                            updatedAwardArray.filter(
                                                              (el, i) =>
                                                                i !== index
                                                            );
                                                          setAwardAndCertificateArray(
                                                            filterAwardArray
                                                          );
                                                          handleRemove(
                                                            props,
                                                            index
                                                          );
                                                        }}
                                                      >
                                                        <FaTrash
                                                          fontSize={"1em"}
                                                          className="me-2"
                                                        />{" "}
                                                        Remove Award /
                                                        Certification
                                                      </button>
                                                    )}
                                                  </div>
                                                </React.Fragment>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </FieldArray>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>

                          {/* Hobbies Section */}

                          <div className="card card-sm my-3 bg-primary-lt  card-2">
                            <div className="card-body">
                              <div>
                                <Formik
                                  enableReinitialize
                                  initialValues={{ hobbies: hobbiesString }}
                                  validationSchema={hobbiesSchema}
                                  onSubmit={handleHobbiesSubmit}
                                >
                                  {(props) => (
                                    <Form id="ps-main">
                                      <fieldset>
                                        <div className="row mb-1">
                                          <label
                                            htmlFor="description"
                                            className="form-label col-3 "
                                          >
                                            Add Your Hobbies:
                                          </label>
                                          <div className="w-100 col">
                                            <CustomTextArea
                                              as="textarea"
                                              type="text"
                                              name="hobbies"
                                              onChange={(e) => {
                                                props.setFieldValue(
                                                  "hobbies",
                                                  e.target.value
                                                );
                                                setHobbiesString(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            {props.touched.hobbies &&
                                              props.errors.hobbies && (
                                                <p className="text-danger">
                                                  {props.errors.hobbies}
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                      </fieldset>

                                      <fieldset>
                                        <div className="text-end">
                                          <button
                                            className="btn btn-primary m-1"
                                            type="submit"
                                            onClick={() => setProgress(0)}
                                          >
                                            <FaFloppyDisk
                                              fontSize={"1em"}
                                              className="me-2"
                                            />{" "}
                                            Save
                                          </button>
                                        </div>
                                      </fieldset>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>

                          {/* Add Skills*/}
                          <div className="card card-sm my-3 bg-primary-lt card-3">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                Showcase verified skills & get better job
                                offers.
                              </h3>
                              <hr className="p-0 my-2" />
                              <div>
                                <p>
                                  Verified skills helped 3349+ Candidates get
                                  upto 30% hike in joining package.
                                </p>
                                <p>
                                  Verified skills helped 2349+ Candidates get
                                  offer on same day of interview.
                                </p>
                                <p>
                                  Verified skills helped 6349+ Candidates get
                                  upto designation hike during switch.
                                </p>
                              </div>
                              <div className="text-end">
                                <button
                                  onClick={() => {
                                    navigate("/get-skill-badges");
                                  }}
                                  className="btn btn-primary"
                                  type="submit"
                                >
                                  <FaArrowAltCircleRight
                                    fontSize={"1em"}
                                    className="me-2"
                                  />{" "}
                                  ADD SKILLS TO YOUR PROFILE
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="">
                          <h2>Amplify your reach!</h2>
                          <p>
                            Build your resume and reach out to 1lakh+ recruiters
                            instantly.
                          </p>
                          <span
                            className="text-blue btn btn-md"
                            onClick={() => setIsResumeExist(true)}
                          >
                            BUILD YOUR RESUME
                          </span>
                          <hr />

                          <h3>1.2Lakhs+ resume developed so far.</h3>
                          <p>
                            Use tested and proven professional resume templates
                            that match what employers look for. Powerful and
                            precise tools at your fingertips. Easy and fast. Try
                            it today for free!
                          </p>

                          <hr />
                          <h3>
                            Rise above the rest with professional resume
                            templates
                          </h3>
                          <p>
                            Over impressive designed and
                            professionally-formatted resume templates let you
                            stand out.
                          </p>

                          <hr />
                          <h3>A resume builder designed to land you the job</h3>
                          <p>
                            Improve every part of your resume to land your dream
                            job using smart suggestions supported by AI and
                            powerful expert insight.
                          </p>

                          <hr />
                          <h3>Recruiter tested and market proven</h3>
                          <p>
                            Our resume builder and template designs are tested
                            by recruiters and job experts. We know your resume
                            will become truly competitive in the hiring process.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Resume Section  right section  data display */}
                <div className="col-md-7">
                  <div className="card" style={{ minHeight: "80vh" }}>
                    <div className="card-header">
                      <div className="w-100">
                        <h3 className="p-0 m-0">Profile progress:</h3>
                        <div className="progress progress-sm">
                          <div
                            className="progress-bar bg-primary"
                            style={{ width: `${progress}%` }}
                            role="progressbar"
                          ></div>
                        </div>
                        <div>
                          <span>{progress}% Complete</span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      {!isResumeExist ? (
                        <>
                          <img
                            src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730619375/resume-demo_abdggy.jpg"
                            alt=""
                            srcSet=""
                          />
                        </>
                      ) : (
                        <div ref={printRef} className="card-body">
                          <div className="row">
                            <div
                              className="col-md-4 card bg-secondary-lt p-2"
                              style={{ minHeight: "80vh" }}
                            >
                              <div>
                                <p className="text-center m-0 pb-2">
                                  {imageUrl ? (
                                    <img src={imageUrl} alt="User" srcSet="" />
                                  ) : (
                                    userDetails?.[0]?.profilePicture && (
                                      <img
                                        src={userDetails?.[0].profilePicture}
                                        alt="Profile"
                                        srcSet=""
                                      />
                                    )
                                  )}
                                </p>
                              </div>

                              <p className="fs-5 m-0">
                                {userDetails?.[0]?.email && (
                                  <FaEnvelope
                                    fontSize={"1.2em"}
                                    className="me-2"
                                  />
                                )}
                                {userDetails?.[0]?.email &&
                                  userDetails?.[0].email}
                              </p>
                              <p className="fs-5 m-0">
                                {userDetails?.[0]?.mobile &&
                                  userDetails?.[0].mobile}
                              </p>
                              <p className="fs-5 m-0">
                                {userDetails?.[0]?.country &&
                                  userDetails?.[0].country}
                              </p>
                              <p className="fs-5 m-0">
                                {userDetails?.[0]?.city &&
                                  userDetails?.[0].city}
                                {userDetails?.[0]?.city && ","}
                                {userDetails?.[0]?.state &&
                                  userDetails?.[0].state}
                              </p>

                              {Object.values(educationArray?.[0]).some(
                                (value) => value !== ""
                              ) && (
                                <>
                                  <hr className="p-0 my-2" />
                                  <p className="p-0 mb-2 fs-3 fw-bold">
                                    <FaGraduationCap
                                      fontSize={"1em"}
                                      className="me-1"
                                    />
                                    Education
                                  </p>
                                </>
                              )}

                              <>
                                {educationArray &&
                                  educationArray?.map((el, index) => (
                                    <div key={index}>
                                      <h4 className="p-0 m-0 fs-4">
                                        {el.educationSelect1}
                                      </h4>
                                      <p className="p-0 m-0 fs-5">
                                        {el.university_1}
                                      </p>
                                      <p className="fs-5">
                                        {el.startDate1}
                                        <span>
                                          {el.endDate1 && " To "}
                                          {el.endDate1}
                                        </span>
                                      </p>
                                      <p className="fs-5">{el.summary}</p>
                                    </div>
                                  ))}
                              </>

                              {skillsArray && skillsArray.length > 0 && (
                                <>
                                  <hr className="p-0 my-2" />
                                  <p className="p-0 mb-2 fs-3 fw-bold">
                                    <FaBook fontSize={"1em"} className="me-1" />{" "}
                                    Skills
                                  </p>
                                  <p className="m-0 p-0">
                                    {skillsArray?.map((skills, index) => {
                                      return (
                                        <span
                                          key={index}
                                          className="avatar avatar-md m-1"
                                          style={{
                                            backgroundImage: `url(${skills.icon})`,
                                          }}
                                        ></span>
                                      );
                                    })}
                                  </p>
                                  <hr className="p-0 my-2" />
                                </>
                              )}
                              {languagesArray &&
                                Object.values(languagesArray?.[0]).some(
                                  (arr) => arr.length > 0
                                ) && (
                                  <>
                                    <>
                                      <p className="p-0 mb-2 fs-3 fw-bold">
                                        <FaLanguage
                                          fontSize={"1em"}
                                          className="me-1"
                                        />{" "}
                                        Languages
                                      </p>

                                      {languagesArray &&
                                        languagesArray?.[0].languageSpeak
                                          ?.length > 0 && (
                                          <>
                                            <p className="p-0 m-0 fs-4 fw-bold">
                                              Speak:
                                            </p>
                                            <p className="p-0 m-0">
                                              {languagesArray?.[0].languageSpeak?.map(
                                                (el) => (
                                                  <span
                                                    className="badge badge-outline text-green m-1"
                                                    key={el}
                                                  >
                                                    {el}
                                                  </span>
                                                )
                                              )}
                                            </p>
                                          </>
                                        )}

                                      {languagesArray &&
                                        languagesArray?.[0]?.readLanguage
                                          ?.length > 0 && (
                                          <>
                                            <p className="p-0 m-0 fs-4 fw-bold">
                                              Read:
                                            </p>
                                            <p className="p-0 m-0">
                                              {languagesArray?.[0]?.readLanguage.map(
                                                (el) => (
                                                  <span
                                                    className="badge badge-outline text-green me-1"
                                                    key={el}
                                                  >
                                                    {el}
                                                  </span>
                                                )
                                              )}
                                            </p>
                                          </>
                                        )}

                                      {languagesArray?.[0]?.languageWrite
                                        .length > 0 && (
                                        <>
                                          <p className="p-0 m-0 fs-4 fw-bold">
                                            Write:
                                          </p>
                                          <p className="p-0 m-0">
                                            {languagesArray?.[0]?.languageWrite
                                              .length > 0 ? (
                                              languagesArray?.[0]?.languageWrite.map(
                                                (el) => (
                                                  <span
                                                    className="badge badge-outline text-green me-1"
                                                    key={el}
                                                  >
                                                    {el}
                                                  </span>
                                                )
                                              )
                                            ) : (
                                              <></>
                                            )}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  </>
                                )}

                              {/* hobbies */}

                              {/* <p className="p-0 mb-2 fs-5">
                           This resume is designed by Hire48.com
                         </p> */}
                            </div>
                            <div className="col-md-8">
                              <h1 className="p-0 m-0 fs-1">
                                {userDetails && userDetails?.[0]?.name}
                              </h1>
                              {userDetails && userDetails?.[0].workProfile && (
                                <>
                                  <p className="p-0 m-0 fs-4">
                                    <FaArrowCircleRight
                                      fontSize={"1em"}
                                      className="me-1"
                                    />{" "}
                                    <span>
                                      {" "}
                                      {userDetails &&
                                        userDetails?.[0]?.workProfile}
                                    </span>
                                  </p>
                                </>
                              )}
                              {additionalInfoArray &&
                                additionalInfoArray?.map((el, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge badge-outline text-green my-1 me-1"
                                    >
                                      {el}
                                    </span>
                                  );
                                })}
                              {profileSummary && (
                                <>
                                  <hr className="p-0 my-2" />
                                  <p className="p-0 m-0 fs-4 fw-bold">
                                    <FaLandmark
                                      fontSize={"1em"}
                                      className="me-1"
                                    />
                                    Professional Summary
                                  </p>
                                </>
                              )}
                              <p className="fs-5 ps-3">
                                {profileSummary && profileSummary}
                              </p>
                              <hr className="p-0 my-2" />
                              {Object.values(workExperienceArray?.[0]).some(
                                (value) => value !== ""
                              ) && (
                                <p className="p-0 m-0 fs-4 fw-bold">
                                  <FaUserAlt
                                    fontSize={"1em"}
                                    className="me-1"
                                  />
                                  Professional Experience
                                </p>
                              )}
                              {/* work Experience */}
                              <div className="ps-3">
                                <>
                                  {workExperienceArray &&
                                    workExperienceArray.map((el) => (
                                      <div key={el.position + el.companyName}>
                                        <p className="p-0 m-0 fs-4 fw-bold">
                                          {el.position}
                                        </p>
                                        <p className="p-0 m-0 fs-5">
                                          {el.companyName} <br />
                                          {el.location} <br />
                                          {el.locationType} <br />
                                          {el.joiningDate} {el.exitDate && "To"}{" "}
                                          {el.exitDate === "on"
                                            ? "Currently Working"
                                            : el.exitDate}
                                        </p>
                                        <p className="fs-5 pt-2">
                                          {el.jobDescription}
                                        </p>
                                      </div>
                                    ))}
                                </>
                              </div>
                              {Object.values(awardAndCertificateArray[0]).some(
                                (value) => value !== ""
                              ) && (
                                <>
                                  <hr className="p-0 my-2" />
                                  <p className="p-0 m-0 fs-4 fw-bold">
                                    <FaTrophy
                                      fontSize={"1em"}
                                      className="me-1"
                                    />
                                    Awards & Certificates
                                  </p>
                                </>
                              )}
                              <div className="ps-3">
                                <>
                                  {awardAndCertificateArray &&
                                    awardAndCertificateArray?.map(
                                      (el, index) => (
                                        <div key={index}>
                                          <p className="p-0 m-0 fs-4 fw-bold">
                                            {el.awardAndCertificateName}
                                          </p>
                                          <p className="p-0 m-0 fs-5">
                                            {el.givenBy}
                                          </p>
                                          <p className="fs-5 pt-2">
                                            {el.acDescription}
                                          </p>
                                        </div>
                                      )
                                    )}
                                </>
                              </div>
                              {hobbiesString && (
                                <>
                                  <hr className="p-0 my-2" />
                                  <p className="p-0 m-0 fs-4 fw-bold">
                                    <FaHands
                                      fontSize={"1em"}
                                      className="me-1"
                                    />
                                    Hobbies
                                  </p>
                                  <p className="p-0 ms-3 m-0 fs-4">
                                    {hobbiesString}
                                  </p>
                                </>
                              )}{" "}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="card-footer text-end">
                      {isDownloadTrue ? (
                        <Buttonloader className="btn btn-primary p-2" />
                      ) : (
                        <button
                          disabled={!store?.isLoggedIn || !isResumeExist}
                          className="btn  btn-primary m-1"
                          onClick={handleDownloadPdf}
                        >
                          <FaDownload fontSize={"1em"} className="me-2" />{" "}
                          Download Resume
                        </button>
                      )}
                      <button
                        disabled={!store?.isLoggedIn || !isResumeExist}
                        className="btn btn-primary"
                        onClick={handleUpdateResume}
                      >
                        <FaUpload fontSize={"1em"} className="me-2" /> Update
                        Resume
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <></>

      <Footer />
    </>
  );
};

export default FreeResumeBuilder;
