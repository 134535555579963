import React, {useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form } from "formik";
import { EmployeeSchema } from "../../../Schemas/employee/employee_schema";
import CustomInput from "../../CustomComponent/custom_input";
import CustomSelect from "../../CustomComponent/custom_select";
import performRequest from "../../../APIs/request";
import { EmployeeInfo } from "../../../Schema Info/employeeInfo";
import { Toast } from "../../CustomComponent/toast";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { FcBusinessman } from "react-icons/fc";
import { BsArrowRightShort } from "react-icons/bs";
import { useSelector } from "react-redux";
import { CustomErrorResponse } from "../../CustomComponent/custom_error_response";

const AddEmployee = () => {
  const navigate = useNavigate();
  const store = useSelector((store) => store.user);
  const [loading, setLoading] = useState(false);
  const [reportingTo, setReportingTo] = useState([]);
  const handelSubmit = (values, actions) => {
    let formData = new FormData();
    for (let key in values) {
      formData.append(key, values[key]);
    }

    const headers = { "Content-Type": "multipart/form-data" };

    setLoading(true);
    performRequest(
      "POST",
      "/employeemanagement/createemployeemanagement",
      formData,
      headers
    )
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Employee Added to Database!");
          navigate(`/employee-management`);
        } else if (res.data?.error === "User already exists") {
          Toast("error", "Error while uploading data");
        } else {
          Toast("error", "Error while uploading data");
        }
      })
      .catch((err) => {
        CustomErrorResponse(err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">
                  <FcBusinessman className="fs-2  me-1" /> Employee management
                </h2>
                <h4 className="text-muted">
                  <p>Add new employee to organization.</p>
                </h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-2 mb-1">
                <h3 className="m-0 p-0">Organization Details:</h3>
                <hr className="m-1" />
                <div className="p-1">
                  <p className="p-0 m-0 fw-bold">Hire48 Pvt Ltd</p>
                  <p className="p-0 mb-2 fw-bold">
                    #98, At. Post Degaon, Wai, Satara, Maharashtra, India 412803
                  </p>
                  <p className="p-0 m-0">GST: 27AAHCK7659R1ZF</p>
                  <p className="p-0 m-0">PAN:AAHCK7659R</p>
                  <p className="p-0 m-0">CIN: U01100PN2019PTC186207</p>
                  <hr className="p-0 my-2" />
                  <p className="p-0 m-0">Total Staff:22</p>
                  <p className="p-0 m-0">Active Position: 2</p>
                  <p className="p-0 m-0">On Probation:20</p>
                </div>
              </div>
              <div className="card p-2 mb-1">
                <h3 className="m-0 p-0">Next Steps:</h3>
                <hr className="m-1" />
                <p className="p-0 my-1">
                  <BsArrowRightShort className="me-1" /> Download Offer Letter
                </p>
                <p className="p-0 my-1">
                  <BsArrowRightShort className="me-1" /> Download Asset
                  Declaration
                </p>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={EmployeeInfo}
                  validationSchema={EmployeeSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <h2 className="m-0 p-2">Fill all the information</h2>
                      <hr className="my-1" />
                      <div className="row px-4">
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              First name
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput name="firstName" type="text" />
                              {props.touched.firstName &&
                                props.errors.firstName && (
                                  <p className="text-danger">
                                    {props.errors.firstName}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Last name
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput name="lastName" type="text" />
                              {props.touched.lastName &&
                                props.errors.lastName && (
                                  <p className="text-danger">
                                    {props.errors.lastName}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Email address
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput name="emailId" type="email" />
                              {props.touched.emailId &&
                                props.errors.emailId && (
                                  <p className="text-danger">
                                    {props.errors.emailId}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Date of birth
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput name="dateOfBirth" type="date" />
                              {props.touched.dateOfBirth &&
                                props.errors.dateOfBirth && (
                                  <p className="text-danger">
                                    {props.errors.dateOfBirth}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Contact number
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput name="contactNumber" type="text" />
                              {props.touched.contactNumber &&
                                props.errors.contactNumber && (
                                  <p className="text-danger">
                                    {props.errors.contactNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Aadhar card number
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                name="aadharcardNumber"
                                type="text"
                              />
                              {props.touched.aadharcardNumber &&
                                props.errors.aadharcardNumber && (
                                  <p className="text-danger">
                                    {props.errors.aadharcardNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Pan card number
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput name="pancardNumber" type="text" />
                              {props.touched.pancardNumber &&
                                props.errors.pancardNumber && (
                                  <p className="text-danger">
                                    {props.errors.pancardNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Bank account number
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                name="bankAccountNumber"
                                type="text"
                              />
                              {props.touched.bankAccountNumber &&
                                props.errors.bankAccountNumber && (
                                  <p className="text-danger">
                                    {props.errors.bankAccountNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Permanant address
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                name="permanentAddress"
                                type="text"
                              />
                              {props.touched.permanentAddress &&
                                props.errors.permanentAddress && (
                                  <p className="text-danger">
                                    {props.errors.permanentAddress}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Residential address
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                name="residentialAddress"
                                type="text"
                              />
                              {props.touched.residentialAddress &&
                                props.errors.residentialAddress && (
                                  <p className="text-danger">
                                    {props.errors.residentialAddress}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Qualification
                            </label>
                            <div className="w-100">
                              <CustomSelect type="text" name="qualification">
                                <option value="">Select</option>
                                <option value="high_school">High School</option>
                                <option value="associate_degree">
                                  Associate Degree
                                </option>
                                <option value="bachelor_degree">
                                  Bachelor's Degree
                                </option>
                                <option value="master_degree">
                                  Master's Degree
                                </option>
                                <option value="phd">PhD</option>
                                <option value="vocational_certificate">
                                  Vocational Certificate
                                </option>
                                <option value="diploma">Diploma</option>
                                <option value="professional_certification">
                                  Professional Certification
                                </option>
                                <option value="postgraduate_certificate">
                                  Postgraduate Certificate
                                </option>
                                <option value="technical_degree">
                                  Technical Degree
                                </option>
                                <option value="trade_school">
                                  Trade School
                                </option>
                                <option value="online_course">
                                  Online Course
                                </option>
                                <option value="apprenticeship">
                                  Apprenticeship
                                </option>
                                <option value="military_training">
                                  Military Training
                                </option>
                                <option value="bootcamp">Bootcamp</option>
                              </CustomSelect>
                              {props.touched.qualification &&
                                props.errors.qualification && (
                                  <p className="text-danger">
                                    {props.errors.qualification}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Emergency contact name
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                name="emergencyContactName"
                                type="text"
                              />
                              {props.touched.emergencyContactName &&
                                props.errors.emergencyContactName && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactName}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Emergency contact number
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                name="emergencyContactNumber"
                                type="text"
                              />
                              {props.touched.emergencyContactNumber &&
                                props.errors.emergencyContactNumber && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Emergency contact relation
                            </label>
                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="emergencyContactRelation"
                              >
                                <option value="">Select</option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                                <option value="spouse">Spouse</option>
                                <option value="sister">Sister</option>
                                <option value="brother">Brother</option>
                                <option value="friend">Friend</option>
                              </CustomSelect>
                              {props.touched.emergencyContactRelation &&
                                props.errors.emergencyContactRelation && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactRelation}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="row px-4">
                        {store?.Role === "superAdmin" && (
                          <div className="col-md-4 col-sm-12 my-1">
                            <div className="row">
                              <label className="form-label required">
                                Salary per month
                              </label>
                              <div className="w-100">
                                <CustomInput
                                  type="text"
                                  name="salaryPerMonth"
                                />
                                {props.touched.salaryPerMonth &&
                                  props.errors.salaryPerMonth && (
                                    <p className="text-danger">
                                      {props.errors.salaryPerMonth}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Date of joining
                            </label>
                            <div className="w-100">
                              <CustomInput type="date" name="dateOfJoining" />
                              {props.touched.dateOfJoining &&
                                props.errors.dateOfJoining && (
                                  <p className="text-danger">
                                    {props.errors.dateOfJoining}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        {store?.Role === "superAdmin" && (
                          <div className="col-md-4 col-sm-12 my-1">
                            <div className="row">
                              <label className="form-label required">
                                PF/PT deduction
                              </label>
                              <div className="w-100">
                                <CustomInput type="text" name="pfptDeduction" />
                                {props.touched.pfptDeduction &&
                                  props.errors.pfptDeduction && (
                                    <p className="text-danger">
                                      {props.errors.pfptDeduction}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                        {store?.Role === "superAdmin" && (
                          <div className="col-md-4 col-sm-12 my-1">
                            <div className="row">
                              <label className="form-label required">
                                Mode of salary
                              </label>

                              <div className="w-100">
                                <CustomSelect type="text" name="modeOfSalary">
                                  <option value="">Select</option>
                                  <option value="cash">Cash</option>
                                  <option value="nft">NFT</option>
                                  <option value="cheque">Cheque</option>
                                </CustomSelect>
                                {props.touched.modeOfSalary &&
                                  props.errors.modeOfSalary && (
                                    <p className="text-danger">
                                      {props.errors.modeOfSalary}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Hired via agency or direct
                            </label>

                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="hireViaAgencyOrDirect"
                              >
                                <option value="">Select</option>
                                <option value="agency">Agency</option>
                                <option value="direct">Direct</option>
                              </CustomSelect>
                              {props.touched.hireViaAgencyOrDirect &&
                                props.errors.hireViaAgencyOrDirect && (
                                  <p className="text-danger">
                                    {props.errors.hireViaAgencyOrDirect}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        {props.values.hireViaAgencyOrDirect === "agency" ? (
                          <div className="col-md-4 col-sm-12 my-1">
                            <div className="mb-3 row">
                              <label className="w-25 form-label required col-3">
                                Agency expense (exl GST)
                              </label>
                              <div className="w-100 col">
                                {" "}
                                <CustomInput
                                  required
                                  name="agencyExpences_exlGST"
                                  type="text"
                                />
                                {props.touched.agencyExpences_exlGST &&
                                  props.errors.agencyExpences_exlGST && (
                                    <p className="text-danger">
                                      {props.errors.agencyExpences_exlGST}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Total years of experience
                            </label>

                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="totalYearsOfExperience"
                              >
                                <option value=""></option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                              </CustomSelect>
                              {props.touched.totalYearsOfExperience &&
                                props.errors.totalYearsOfExperience && (
                                  <p className="text-danger">
                                    {props.errors.totalYearsOfExperience}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        {props.values.totalYearsOfExperience !== "0" &&
                        props.values.totalYearsOfExperience !=="" ? (
                          <div className="col-md-4 col-sm-12 my-1">
                            <div className="row">
                              <label className="form-label required">
                                Previous organization name
                              </label>
                              <div className="w-100">
                                {" "}
                                <CustomInput
                                  required
                                  name="previousOrganizationName"
                                  type="text"
                                />
                                {props.touched.previousOrganizationName &&
                                  props.errors.previousOrganizationName && (
                                    <p className="text-danger">
                                      {props.errors.previousOrganizationName}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {props.values.totalYearsOfExperience !== "0" ? (
                          <div className="col-md-4 col-sm-12 my-1">
                            <div className="mb-3 row">
                              <label className="form-label required">
                                Previous organization location
                              </label>
                              <div className="w-100">
                                {" "}
                                <CustomInput
                                  required
                                  name="previousOrganizationLocation"
                                  type="text"
                                />
                                {props.touched.previousOrganizationLocation &&
                                  props.errors.previousOrganizationLocation && (
                                    <p className="text-danger">
                                      {
                                        props.errors
                                          .previousOrganizationLocation
                                      }
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Role in organization
                            </label>

                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="roleInOrganisation"
                              >
                                <option value="">Select Role</option>
                                <option value="superAdmin">Super Admin</option>
                                <option value="recruiter">Recruiter</option>

                                <option value="adminStaff">Admin Staff</option>
                                <option value="purchaseStaff">
                                  Purchase Staff
                                </option>
                                <option value="saleStaff">Sales Staff</option>

                                <option value="opsStaff">Ops Staff</option>

                                <option value="marketingStaff">
                                  Marketing Staff
                                </option>
                                <option value="labourStaff">
                                  Labour Staff
                                </option>
                                <option value="accountStaff">
                                  Account Staff
                                </option>
                                <option value="ITStaff">IT Staff</option>
                                <option value="SSMStaff">SMM Staff</option>
                                <option value="salesManager">
                                  Sales Manager
                                </option>
                                <option value="clientFeedback">
                                  Client Feedback
                                </option>
                              </CustomSelect>
                              {props.touched.roleInOrganisation &&
                                props.errors.roleInOrganisation && (
                                  <p className="text-danger">
                                    {props.errors.roleInOrganisation}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Reporting to
                            </label>

                            <div className="w-100">
                              <CustomSelect type="text" name="reportingTo">
                                <option value="">Select</option>
                                {reportingTo &&
                                  reportingTo.map((emp, index) => {
                                    return (
                                      <option key={index} value={emp?.userName}>
                                        {/* {emp?.userName}  'temp' */}
                                      </option>
                                    );
                                  })}
                              </CustomSelect>
                              {props.touched.reportingTo &&
                                props.errors.reportingTo && (
                                  <p className="text-danger">
                                    {props.errors.reportingTo}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Package joined at
                            </label>
                            <div className="w-100">
                              <CustomInput type="text" name="packageJoinedAt" />
                              {props.touched.packageJoinedAt &&
                                props.errors.packageJoinedAt && (
                                  <p className="text-danger">
                                    {props.errors.packageJoinedAt}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Contract of employment
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomSelect
                                name="contractOfEmployment"
                                type="text"
                              >
                                <option value="">Select Contract</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </CustomSelect>
                              {props.touched.contractOfEmployment &&
                                props.errors.contractOfEmployment && (
                                  <p className="text-danger">
                                    {props.errors.contractOfEmployment}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Laptop given by organisation
                            </label>
                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="laptopGivenByOrganisation"
                              >
                                <option value="">Select</option>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                              </CustomSelect>
                              {props.touched.laptopGivenByOrganisation &&
                                props.errors.laptopGivenByOrganisation && (
                                  <p className="text-danger">
                                    {props.errors.laptopGivenByOrganisation}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        {props.values.laptopGivenByOrganisation === "yes" ? (
                          <div className="col-md-4 col-sm-12 my-1">
                            <div className="row">
                              <label className="form-label required">
                                Laptop id:
                              </label>

                              <div className="w-100">
                                <CustomInput
                                  type="text"
                                  required
                                  name="laptopId"
                                />

                                {props.touched.laptopId &&
                                  props.errors.laptopId && (
                                    <p className="text-danger">
                                      {props.errors.laptopId}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Mobile given by organisation
                            </label>

                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="mobileGivenByOrganisation"
                              >
                                <option value="">Select</option>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                              </CustomSelect>
                              {props.touched.mobileGivenByOrganisation &&
                                props.errors.mobileGivenByOrganisation && (
                                  <p className="text-danger">
                                    {props.errors.mobileGivenByOrganisation}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        {props.values.mobileGivenByOrganisation === "yes" ? (
                          <div className="col-md-4 col-sm-12 my-1">
                            <div className="row">
                              <label className="form-label required">
                                Mobile id:
                              </label>

                              <div className="w-100">
                                <CustomInput
                                  type="text"
                                  required
                                  name="mobileId"
                                />

                                {props.touched.mobileId &&
                                  props.errors.mobileId && (
                                    <p className="text-danger">
                                      {props.errors.mobileId}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              SIM card given by organisation
                            </label>

                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="simCardGivenByOrganisation"
                              >
                                <option value="">Select</option>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                              </CustomSelect>
                              {props.touched.simCardGivenByOrganisation &&
                                props.errors.simCardGivenByOrganisation && (
                                  <p className="text-danger">
                                    {props.errors.simCardGivenByOrganisation}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        {props.values.simCardGivenByOrganisation === "yes" ? (
                          <div className="col-md-4 col-sm-12 my-1">
                            <div className="row">
                              <label className="form-label required">
                                Sim Card Number:
                              </label>

                              <div className="w-100">
                                <CustomInput
                                  type="text"
                                  required
                                  name="simCardNumber"
                                />

                                {props.touched.simCardNumber &&
                                  props.errors.simCardNumber && (
                                    <p className="text-danger">
                                      {props.errors.simCardNumber}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Working place
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput name="workingPlace" type="text" />
                              {props.touched.workingPlace &&
                                props.errors.workingPlace && (
                                  <p className="text-danger">
                                    {props.errors.workingPlace}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <div className="row px-4">
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Photograph
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "photograph",
                                    e.target.files[0]
                                  )
                                }
                                name="photograph"
                                type="file"
                              />
                              {props.touched.photograph &&
                                props.errors.photograph && (
                                  <p className="text-danger">
                                    {props.errors.photograph}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Aadhar card photo front
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "aadharcardPhotoFront",
                                    e.target.files[0]
                                  )
                                }
                                name="aadharcardPhotoFront"
                                type="file"
                              />
                              {props.touched.aadharcardPhotoFront &&
                                props.errors.aadharcardPhotoFront && (
                                  <p className="text-danger">
                                    {props.errors.aadharcardPhotoFront}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Aadhar card photo back
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "aadharcardPhotoBack",
                                    e.target.files[0]
                                  )
                                }
                                name="aadharcardPhotoBack"
                                type="file"
                              />
                              {props.touched.aadharcardPhotoBack &&
                                props.errors.aadharcardPhotoBack && (
                                  <p className="text-danger">
                                    {props.errors.aadharcardPhotoBack}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Pan card photo
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "pancardPhoto",
                                    e.target.files[0]
                                  )
                                }
                                name="pancardPhoto"
                                type="file"
                              />
                              {props.touched.pancardPhoto &&
                                props.errors.pancardPhoto && (
                                  <p className="text-danger">
                                    {props.errors.pancardPhoto}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Offer letter acceptance
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "offerLetterAcceptance",
                                    e.target.files[0]
                                  )
                                }
                                name="offerLetterAcceptance"
                                type="file"
                              />
                              {props.touched.offerLetterAcceptance &&
                                props.errors.offerLetterAcceptance && (
                                  <p className="text-danger">
                                    {props.errors.offerLetterAcceptance}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Joining letter scan
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "joiningLetterScan",
                                    e.target.files[0]
                                  )
                                }
                                name="joiningLetterScan"
                                type="file"
                              />
                              {props.touched.joiningLetterScan &&
                                props.errors.joiningLetterScan && (
                                  <p className="text-danger">
                                    {props.errors.joiningLetterScan}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12 my-1">
                          {" "}
                          <div className="row">
                            <label className="form-label required">
                              Bank passbook
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "bankpassbookPhoto",
                                    e.target.files[0]
                                  )
                                }
                                name="bankpassbookPhoto"
                                type="file"
                              />
                              {props.touched.bankpassbookPhoto &&
                                props.errors.bankpassbookPhoto && (
                                  <p className="text-danger">
                                    {props.errors.bankpassbookPhoto}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12 my-1">
                          <div className="row">
                            <label className="form-label required">
                              Residential proof
                            </label>
                            <div className="w-100">
                              {" "}
                              <CustomInput
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "residentialProofPhoto",
                                    e.target.files[0]
                                  )
                                }
                                name="residentialProofPhoto"
                                type="file"
                              />
                              {props.touched.residentialProofPhoto &&
                                props.errors.residentialProofPhoto && (
                                  <p className="text-danger">
                                    {props.errors.residentialProofPhoto}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer mt-4">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Create Employee" />

                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default AddEmployee;
