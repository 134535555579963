import React from "react";
import Footer from "../../Components/Footer/footer";
import { useSelector } from "react-redux";
import LoadingPage from "../../Components/layouts/loading";
import { useState } from "react";
import { useNavigate } from "react-router";

const Pricing = () => {
  const store = useSelector((store) => store?.userLogin);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="page-body">
        <div className="container-xl">
          <section className="section my-2 py-2">
            <div className="section-header text-center p-4 m-4">
              <h3 className="section-description m-0 p-0">
                ⚠️ Hire48 is free for employees, & it will always be. This
                pricing table is only for recruiters.
              </h3>
              <hr />
              <h2
                className="hero-title aos-init aos-animate m-0 display-5 fw-bold"
                data-aos="zoom-y-out"
              >
                NOT JUST A JOB PLATFORM!
              </h2>
              <h3 className="section-description m-0 p-0">
                A secret superpower of top-notch agency to hire for any post in
                less then 48Hrs.
              </h3>
            </div>

            <div className="card card-lg">
              <div className="card-body p-0 m-0">
                <div className="table-responsive">
                  <table className="table table-vcenter table-bordered table-nowrap card-table">
                    <thead>
                      <tr>
                        <td className="w-50">
                          <h2>Simple flat pricing plans.</h2>
                          <div className="text-secondary text-wrap">
                            Choose an affordable plan that comes with the best
                            features to build your team.
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="text-uppercase text-secondary font-weight-medium">
                            For Salary below
                          </div>
                          <div className="text-uppercase text-primary font-weight-medium">
                            ₹15000/Mo
                          </div>
                          <div className="display-6 fw-bold">₹4,499</div>
                          <p>Flat Pricing, Inc. Tax</p>
                        </td>
                        <td className="text-center">
                          <div className="text-uppercase text-secondary font-weight-medium">
                            For Salary below
                          </div>
                          <div className="text-uppercase text-primary font-weight-medium">
                            ₹50,000/Mo
                          </div>
                          <div className="display-6 fw-bold">₹9,999</div>
                          <p>Flat Pricing, Inc. Tax</p>
                        </td>
                        <td className="text-center">
                          <div className="text-uppercase text-secondary font-weight-medium">
                            For Headhunting
                          </div>
                          <div className="text-uppercase text-primary font-weight-medium">
                            TM & CXO
                          </div>
                          <div className="display-6 fw-bold">₹24,999</div>
                          <p>Flat Pricing, Inc. Tax</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-light">
                        <th colspan="4" className="subheader">
                          Features
                        </th>
                      </tr>
                      <tr>
                        <td>Dedicated Account Manager</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                      </tr>
                      <tr>
                        <td>Manually Filtered Profiles</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                      </tr>
                      <tr>
                        <td>Unlimited Profile Options</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                      </tr>
                      <tr>
                        <td>Live Screening Score</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                      </tr>
                      <tr>
                        <td>Skill Verification</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                      </tr>
                      <tr className="bg-light">
                        <th colspan="4" className="subheader">
                          Additional Features
                        </th>
                      </tr>
                      <tr>
                        <td>Background Verification</td>
                        <td className="text-center">No</td>
                        <td className="text-center">No</td>
                        <td className="text-center">Yes</td>
                      </tr>
                      <tr>
                        <td>Free 3 months replacement</td>
                        <td className="text-center">No</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Yes</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td>
                          <button
                            value="4999"
                            onClick={() => {
                              if (store?.user) {
                                navigate("/payment");
                              } else {
                                navigate("/account-login");
                              }
                            }}
                            href="#"
                            className="btn btn-green w-100"
                          >
                            Choose Plan
                          </button>
                        </td>
                        <td>
                          <button
                            value="9999"
                            onClick={() => {
                              if (store?.user) {
                                navigate("/payment");
                              } else {
                                navigate("/account-login");
                              }
                            }}
                            href="#"
                            className="btn btn-green w-100"
                          >
                            Choose Plan
                          </button>
                        </td>
                        <td>
                          <button
                            value="24999"
                            onClick={() => {
                              if (store?.user) {
                                navigate("/payment");
                              } else {
                                navigate("/account-login");
                              }
                            }}
                            href="#"
                            className="btn btn-green w-100"
                          >
                            Choose Plan
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </section>
          <section className="section my-2 py-2">
            <div className="col-12">
              <div className="section-header text-center p-4 m-4">
                <h2
                  className="hero-title aos-init aos-animate m-0 display-5 fw-bold"
                  data-aos="zoom-y-out"
                >
                  Why we are different? 🔥
                </h2>
                <h3 className="section-description m-0 p-0">
                  Once you know about us, You definately switch to us!!!
                </h3>
              </div>
            </div>

            <div class="col-12">
              <div class="card">
                <div class="card-table table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>USP & Features</th>
                        <th className="text-center">Naukri</th>
                        <th className="text-center">LinkedIn</th>
                        <th className="text-center">Glassdoor</th>
                        <th className="text-center">Indeed</th>
                        <th className="text-center">TimesJobs</th>
                        <th className="text-center">Apna Jobs</th>
                        <th className="text-center">Work India</th>
                        <th className="text-center">HIRE48</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Jobs for everyone</td>
                        <td className="text-center fs-2">✅</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Skill & experience showcase</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Targeted job search</td>
                        <td className="text-center fs-2">✅</td>
                        <td className="text-center fs-2">✅</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>

                      <tr>
                        <td>Direct communication</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Build your portfolio</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Industry tests</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Build your portfolio</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Dedicated account manager</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Skill testing</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Mass hiring</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Head hunting</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Build your portfolio</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Free candidate replacement</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Proofing of candidates</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Resume verification</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Relevancy score</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Increment matrix</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Tie up with universities</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                      <tr>
                        <td>Fastest hiring</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">❌</td>
                        <td className="text-center fs-2">✅</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
