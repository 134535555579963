import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const cookie = Cookies.get("user") || null;
const cookielog = Cookies.get("userlog") || null;
let userData = null;
if (cookie) {
  userData = JSON.parse(cookie);
}
let userDatalog = null;
if (cookielog) {
  userDatalog = JSON.parse(cookielog);
}

function getTime() {
  const now = new Date();
  let expireTime;

  if (now.getHours() >= 20) {
    expireTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      8,
      0,
      0
    );
  } else {
    expireTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      20,
      0,
      0
    );
  }

  return expireTime;
}

function setCookiesOnTimeandRole(user) {
  if (user.isEmployee === true) {
    Cookies.set("user", JSON.stringify(user), { expires: getTime() });
  } else {
    Cookies.set("user", JSON.stringify(user), { expires: 7 });
  }
}
function setCookiesOnTimeandRoleLog(user) {
  if (user.isEmployee === true) {
    Cookies.set("userlog", JSON.stringify(user), {
      expires: getTime(),
    });
  }
}

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoggedIn: userData?.Token ? true : false,
    user: userData ? userData : null,
    userlog: userDatalog,
  },
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      Cookies.set("user", JSON.stringify(action.payload), { expires: 7 });
      setCookiesOnTimeandRole(action.payload);
    },
    userLoginLog: (state, action) => {
      state.userlog = action.payload;

      setCookiesOnTimeandRoleLog(action.payload);
    },
    logout: (state) => {
      Cookies.remove("user");
       Cookies.remove("userlog");
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        userlog: null,
      };
    },
    finalOfficeLogout: (state) => {
      Cookies.remove("user");
      Cookies.remove("userlog");
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        userlog: null,
      };
    },
  },
});

export const { login, logout, userLoginLog, finalOfficeLogout } =
  loginSlice.actions;
export default loginSlice.reducer;
