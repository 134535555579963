import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import Scrollbar from "../../Components/layouts/Scrollbar";
import { FaAddressCard, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router";
import performRequest from "../../APIs/request";
import { useSelector } from "react-redux";
import hp1 from "../../assets/homepage/hp-1.png";
import hp2 from "../../assets/homepage/hp-2.png";
import hp3 from "../../assets/homepage/hp-3.png";
import hp4 from "../../assets/homepage/hp-4.png";
import hp5 from "../../assets/homepage/hp-5.png";
import hp6 from "../../assets/homepage/hp-6.png";
import hp7 from "../../assets/homepage/hp-7.png";
import hp8 from "../../assets/homepage/hp-8.png";
import hp9 from "../../assets/homepage/hp-9.png";

const Dashboard = () => {
  const store = useSelector((store) => store?.userLogin);
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState([]);
  const [testsData, setTeastData] = useState([]);
  const [demoComp, setDemoComp] = useState([]);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  useEffect(() => {
    performRequest("GET", `/test/alltests`)
      .then((res) => {
        setTeastData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    performRequest("GET", `/jobs/getalljobs`)
      .then((res) => {
        setJobsData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    performRequest("GET", "/democomp/alldemocompany")
      .then((res) => {
        if (res.status === 200) {
          setDemoComp(res?.data);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  let se1 = 0;
  let sbd1 = 0;
  let abc1 = 0;
  return (
    <div className="Page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <header className="hero text-center py-2 my-4 my-8">
            <div className="my-2">
              <h1
                className="hero-title aos-init aos-animate m-0 display-4 fw-bold"
                data-aos="zoom-y-out"
              >
                YOUR JOB SEARCH ENDS HERE!!!
              </h1>
              <h2 className="p-0 m-0">
                Streamline your job search with AI-driven precision.
                <span className="text-primary">
                  India's fastest job placement platform!
                </span>
              </h2>
            </div>
            <img
              src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730659727/candidates-img_yhirnh.png"
              className="w-50"
            ></img>

            <div className="card card-md py-5">
              <h3 className="hero-description p-0 fs-2">
                Discover 50,000+ career opportunities & get placed in 48Hrs.
                <br />
                <span className="text-primary">
                  Get in touch with HR directly for free!
                </span>
              </h3>

              <div className="row">
                <div className="col-mg-12 col-lg-6 text-lg-end text-md-center">
                  <div className="">
                    <span
                      onClick={() => {
                        if (!store?.isLoggedIn) {
                          navigate("/account-login");
                        } else {
                          navigate("/resume-builder");
                        }
                      }}
                      className="btn btn-primary btn-md p-3 btn-text"
                    >
                      <FaAddressCard fontSize={"1.2em"} className="me-2" />{" "}
                      BUILD YOUR PROFILE
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 text-lg-start text-md-center">
                  <div className="">
                    <span
                      className="btn btn-md p-3 btn-text"
                      onClick={() => {
                        navigate("/top-hires");
                      }}
                    >
                      <FaSearch fontSize={"1.2em"} className="me-2" />
                      BROWSE JOB POSTS
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <Scrollbar direction={"right"}></Scrollbar>
        <Scrollbar direction={"left"}></Scrollbar>
        <div className="container-xxl">
          <section className="section text-center my-8 py-4">
            <div className="section-header">
              <h2
                className="hero-title aos-init aos-animate m-0 display-5 fw-bold"
                data-aos="zoom-y-out"
              >
                NOT JUST A JOB PLATFORM!
              </h2>
              <h3 className="section-description m-0 p-0">
                We are not just focused on creating list of jobs. We prepare you
                well in advance to make sure you crack the interview.
              </h3>
            </div>
            <div className="row my-md-6" data-aos-id-blocks-previews="true">
              <div className="col-lg-4 col-sm-12 col-md-6">
                {/* <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730649687/1_knzoxz.jpg"
                  className="w-100 card card-link card-link-pop"
                ></img> */}

                <div className="card card-link card-link-pop text-start">
                  <div
                    className="card-body"
                    style={{
                      backgroundImage:
                        "url(https://res.cloudinary.com/dph1m5tq7/image/upload/v1730650710/2_fsyvix.png)",
                    }}
                  >
                    <h2 className="fs-2">NO FAKE JOBS</h2>
                    <p>
                      We only work with GST Registered Companies. We post zero
                      fake jobs and close every position in less then 36Hrs.
                      Reducing your hire time & hassle.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 col-md-6">
                {/* <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730649687/2_ynr5ui.jpg"
                  className="w-100 card card-link card-link-pop"
                ></img> */}
                <div className="card card-link card-link-pop text-start">
                  <div className="card-body">
                    {/* <img src={hp2} className="p-5 w-75"></img> */}
                    <h3 className="fs-2">DEDICATED MANAGER</h3>
                    <p>
                      We assign you dedicated account manager once you get
                      shortlisted. We ensure that you are prepared for the
                      interview from core skills to soft skills.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 col-md-6">
                {/* <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730649688/3_qybqng.jpg"
                  className="w-100 card card-link card-link-pop"
                ></img> */}

                <div className="card card-link card-link-pop text-start">
                  <div className="card-body">
                    {/* <img src={hp3} className="p-5 w-75"></img> */}
                    <h3 className="fs-2">SKILLS VERIFICATION</h3>
                    <p>
                      We build strong repo with clients and help you stand out
                      from crowd with skill-test. Give tests and get your skills
                      badges to improve your skill score.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section text-center my-8 py-4">
            <div className="section-header">
              <h2
                className="hero-title aos-init aos-animate m-0 display-5 fw-bold"
                data-aos="zoom-y-out"
              >
                GET POWERFUL WITH AI!
              </h2>
              <h3 className="section-description m-0 p-0">
                Revolutionize your hiring with our AI-powered profile builder.
              </h3>
            </div>

            <div className="row my-4">
              <div className="col-md-6">
                <div className="card card-link card-link-pop text-start">
                  <div className="card-body">
                    {/* <img src={hp3} className="p-5 w-75"></img> */}
                    <h3 className="fs-2">BUILD YOUR PROFILE WITH AI</h3>
                    <p>
                      Build the strongest profile with help of AI profile
                      builder and stand out in the crowd. Put your best foot
                      forward and land a dream job. A clear inductive resume
                      increases your chance of selection by 35%!
                    </p>
                  </div>
                </div>

                {/* <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730649688/4_i5ole2.jpg"
                  className="w-100 card card-link card-link-pop"
                ></img> */}
                {/* <div
                  className="card my-4"
                  style={{
                    border: "0px solid #fff",
                    // boxShadow:
                    //   "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <div className="card-body row">
                    <div className="col-md-3">
                      <img src={hp5} className="p-5 w-100"></img>
                    </div>

                    <div className="col-md-9">
                      <h3 className="card-title">
                        AI AUTOMATED RESUME BUILDER!
                      </h3>
                      <p>
                        Drive your career forward with our AI based Resume
                        Builder. System build after analysing lakhs of resumes
                        and we filtered out the wining secret for you.
                      </p>

                      <button
                        onClick={() => {
                          if (!store?.isLoggedIn) {
                            alert(
                              "You must be logged in before creating profile"
                            );
                            navigate("/account-login");
                          } else {
                            navigate("/resume-builder");
                          }
                        }}
                        className="btn btn-primary w-20"
                      >
                        BUILD YOUR RESUME
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-md-6">
                <div className="card card-link card-link-pop text-start">
                  <div className="card-body">
                    {/* <img src={hp3} className="p-5 w-75"></img> */}
                    <h3 className="fs-2">MOCK QnA & INTERVIEWS</h3>
                    <p>
                      Based on your profile, skills and job positions you are
                      applying for our strong AI prepares a list of ‘Mock
                      questions & Answers’ for you to prepare for, increasing
                      your chances of selection by 50%!
                    </p>
                  </div>
                </div>

                {/* <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730649689/5_cfas5i.jpg"
                  className="w-100 card card-link card-link-pop"
                ></img> */}
                {/* <div
                  className="card my-4"
                  style={{
                    border: "0px solid #fff",
                    // boxShadow:
                    //   "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <div className="card-body row">
                    <div className="col-md-3">
                      <img src={hp7} className="p-5 w-100"></img>
                    </div>

                    <div className="col-md-9">
                      <h3 className="card-title">AI INTERVIEW CRACKER!</h3>
                      <p>
                        From your profile we create set of possible questions
                        interviewer might ask you. Preparing this questions will
                        increase your chances of getting selected by almost 60%.
                      </p>

                      <button
                        onClick={() => {
                          if (!store?.isLoggedIn) {
                            alert(
                              "You must be logged in before creating profile"
                            );
                            navigate("/account-login");
                          } else {
                            navigate("/resume-builder");
                          }
                        }}
                        className="btn btn-primary w-20"
                      >
                        PREPARE YOUR INTERVIEW
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          <section className="section text-center my-8 py-4">
            <div className="container my-10">
              <div className="section-header">
                <h2
                  className="hero-title aos-init aos-animate m-0 display-5 fw-bold"
                  data-aos="zoom-y-out"
                >
                  SHOWCASE YOUR SKILLS TO INTERVIEWER
                </h2>
                <h3 className="section-description m-0 p-0">
                  Get your skill badge and certificate to shocase on your
                  profile. Makes hiring 80% faster.
                </h3>
              </div>

              <div className="row my-md-6">
                <div className="col-md-4 my-3">
                  <div className="card card-stacked">
                    <div className="card-header">
                      <h3 className="card-title">Software Engineering</h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card my-2">
                            <div className="row g-0">
                              {testsData?.map((testdep) => {
                                if (
                                  se1 < 3 &&
                                  testdep.department === "Software Engineering"
                                ) {
                                  se1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 2,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                            <div className="row g-0">
                              {testsData?.map((testdep, index) => {
                                if (
                                  se1 > 5 &&
                                  se1 < 9 &&
                                  testdep.department === "Software Engineering"
                                ) {
                                  se1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                                if (
                                  testdep.department === "Software Engineering"
                                ) {
                                  se1++;
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-3">
                  <div className="card card-stacked">
                    <div className="card-header">
                      <h3 className="card-title">
                        Sales & Business Development
                      </h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card my-2">
                            <div className="row g-0">
                              {testsData?.map((testdep, index) => {
                                if (
                                  sbd1 < 3 &&
                                  testdep.department ===
                                    "Sales & Business Development"
                                ) {
                                  sbd1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                            <div className="row g-0">
                              {testsData?.map((testdep, index) => {
                                if (
                                  sbd1 > 5 &&
                                  sbd1 < 9 &&
                                  testdep.department ===
                                    "Sales & Business Development"
                                ) {
                                  sbd1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                                if (
                                  testdep.department ===
                                  "Sales & Business Development"
                                ) {
                                  sbd1++;
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-3">
                  <div className="card card-stacked">
                    <div className="card-header">
                      <h3 className="card-title">ADMIN & BACK OFFICE</h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card my-2">
                            <div className="row g-0">
                              {testsData?.map((testdep, index) => {
                                if (
                                  abc1 < 3 &&
                                  testdep.department === "Admin & Back Office"
                                ) {
                                  abc1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                            <div className="row g-0">
                              {testsData?.map((testdep, index) => {
                                if (
                                  abc1 > 5 &&
                                  abc1 < 9 &&
                                  testdep.department === "Admin & Back Office"
                                ) {
                                  abc1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                                if (
                                  testdep.department === "Admin & Back Office"
                                ) {
                                  abc1++;
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate("/get-skill-badges");
                }}
                className="btn btn-primary w-20"
              >
                Explore 200+ Tests
              </button>
            </div>
          </section>

          <section className="section text-center my-8 py-4">
            <div className="row">
              <div className="col-md-4">
                <h2
                  className="hero-title aos-init aos-animate m-0 display-5 fw-bold"
                  data-aos="zoom-y-out"
                >
                  POPULAR SEARCHES ON HIRE48! 💥
                </h2>
              </div>
              <div className="col-md-4">
                <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730658243/18_etpbzl.jpg"
                  className="w-100 card card-link card-link-pop my-2"
                ></img>
              </div>
              <div className="col-md-4">
                <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730658243/19_aq59ku.jpg"
                  className="w-100 card card-link card-link-pop my-2"
                ></img>
              </div>
              <div className="col-md-4">
                <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730658443/20_e1o2d5.jpg"
                  className="w-100 card card-link card-link-pop my-2"
                ></img>
              </div>
              <div className="col-md-4">
                <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730658444/21_mvkllg.jpg"
                  className="w-100 card card-link card-link-pop my-2"
                ></img>
              </div>
              <div className="col-md-4">
                <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730658446/22_wa1lc7.jpg"
                  className="w-100 card card-link card-link-pop my-2"
                ></img>
              </div>
            </div>
          </section>

          <section className="section text-center my-8 py-4">
            <div className="section-header">
              <h2
                className="hero-title aos-init aos-animate m-0 display-5 fw-bold"
                data-aos="zoom-y-out"
              >
                CONNECT TO RIGHT EMPLOYER!
              </h2>
              <h3 className="section-description m-0 p-0">
                With relevency score matrix, we connect you to right job.
              </h3>
            </div>

            <div className="row my-4">
              <div className="col-md-6">
                <div className="card card-link card-link-pop text-start">
                  <div className="card-body">
                    {/* <img src={hp3} className="p-5 w-75"></img> */}
                    <h3 className="fs-2">RELEVANCY SCORE</h3>
                    <p>
                      Showcase your relevant skills to recruiters by getting a
                      skill badge on your resume. Recruter prefer candidates
                      with skill badge more over candidates with just resume!
                    </p>
                    <p>
                      <span className="text-primary strong">
                        10,000+ candidate secure job in 24 hours with relevancy
                        score.
                      </span>
                    </p>
                  </div>
                </div>

                {/* <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730649689/6_htpvwn.jpg"
                  className="w-100 card card-link card-link-pop"
                ></img> */}
                {/* <div
                  className="card my-4"
                  style={{
                    border: "0px solid #fff",
                    // boxShadow:
                    //   "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <div className="card-body row">
                    <div className="col-md-3">
                      <img src={hp5} className="p-5 w-100"></img>
                    </div>

                    <div className="col-md-9">
                      <h3 className="card-title">
                        AI AUTOMATED RESUME BUILDER!
                      </h3>
                      <p>
                        Drive your career forward with our AI based Resume
                        Builder. System build after analysing lakhs of resumes
                        and we filtered out the wining secret for you.
                      </p>

                      <button
                        onClick={() => {
                          if (!store?.isLoggedIn) {
                            alert(
                              "You must be logged in before creating profile"
                            );
                            navigate("/account-login");
                          } else {
                            navigate("/resume-builder");
                          }
                        }}
                        className="btn btn-primary w-20"
                      >
                        BUILD YOUR RESUME
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-md-6">
                <div className="card card-link card-link-pop text-start">
                  <div className="card-body">
                    {/* <img src={hp3} className="p-5 w-75"></img> */}
                    <h3 className="fs-2">UPTO 50% HIKE ON CTC</h3>
                    <p>
                      The skill score will help you shortlist the companies that
                      can give you good hike and align with the relevant
                      skillset you posses.
                    </p>
                    <p>
                      <span className="text-primary strong">
                        6,000+ candidate got hike more then market standards
                        with skill score.
                      </span>
                    </p>
                  </div>
                </div>

                {/* <img
                  src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1730649690/7_ws8pzu.jpg"
                  className="w-100 card card-link card-link-pop"
                ></img> */}
                {/* <div
                  className="card my-4"
                  style={{
                    border: "0px solid #fff",
                    // boxShadow:
                    //   "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <div className="card-body row">
                    <div className="col-md-3">
                      <img src={hp7} className="p-5 w-100"></img>
                    </div>

                    <div className="col-md-9">
                      <h3 className="card-title">AI INTERVIEW CRACKER!</h3>
                      <p>
                        From your profile we create set of possible questions
                        interviewer might ask you. Preparing this questions will
                        increase your chances of getting selected by almost 60%.
                      </p>

                      <button
                        onClick={() => {
                          if (!store?.isLoggedIn) {
                            alert(
                              "You must be logged in before creating profile"
                            );
                            navigate("/account-login");
                          } else {
                            navigate("/resume-builder");
                          }
                        }}
                        className="btn btn-primary w-20"
                      >
                        PREPARE YOUR INTERVIEW
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>

          <section className="section text-center my-8 py-4">
            <div className="section-header">
              <h2
                className="hero-title aos-init aos-animate m-0 display-5 fw-bold"
                data-aos="zoom-y-out"
              >
                What kind of a role do you want?
              </h2>
              <h3 className="section-description m-0 p-0">
                We connect you to the job roll, you deserve!
              </h3>
            </div>
            <div className="row">
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Software Developer</h3>
                    <p>250+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Web Developer</h3>
                    <p>350+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Graphic Designer</h3>
                    <p>450+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Video Editor</h3>
                    <p>690+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Digital Marketing</h3>
                    <p>680+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Organic Marketing</h3>
                    <p>785+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Growth Hacker</h3>
                    <p>370+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Architect</h3>
                    <p>830+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Civil Engineer</h3>
                    <p>490+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Acountant</h3>
                    <p>340+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Legal</h3>
                    <p>570+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">HR / Admin</h3>
                    <p>860+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Telesales</h3>
                    <p>930+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Customer Support</h3>
                    <p>290+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">BDM</h3>
                    <p>910+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Sales</h3>
                    <p>420+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Field Sales</h3>
                    <p>580+ Vaccencies</p>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Counter Sales</h3>
                    <p>490+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Back Office</h3>
                    <p>570+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Cashier</h3>
                    <p>640+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">IT/ HW Engg.</h3>
                    <p>680+ Vaccencies </p>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Teacher/ Tutor</h3>
                    <p>940+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Receptionist</h3>
                    <p>790+ Vaccencies</p>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Event Manager</h3>
                    <p>320+ Vaccencies</p>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Manufacturing</h3>
                    <p>820+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Operator</h3>
                    <p>730+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Logistics</h3>
                    <p>790+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Warehouse</h3>
                    <p>920+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Delivery</h3>
                    <p>930+ Vaccencies</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="card my-2">
                  <div className="card-body">
                    <h3 className="m-0 p-0">Technician</h3>
                    <p>790+ Vaccencies</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section text-center my-8 py-4">
            <div className="row" data-aos-id-blocks-previews="true">
              <div className="col-12">
                <div className="card bg-secondary-lt">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h2 className="section-title section-title-lg">
                          SOME OF THE COMPANIES WE WORK WITH!
                        </h2>
                        <p className="section-description">
                          HIRE48 in widespread across <strong>INDIA</strong>!
                        </p>
                      </div>
                      <div className="col-md-12 ">
                        <div className="btn-md-list">
                          {demoComp?.map((el) => {
                            if (el.id < 100 && el.squareLogo) {
                              return (
                                <div
                                  key={el.id}
                                  style={{
                                    backgroundImage: `url(${el.squareLogo})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                  className="m-2 avatar avatar-lg"
                                ></div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
