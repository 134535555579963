import React from "react";
import Footer from "../../Components/Footer/footer";

const FAQs = () => {
  return (
    <>
      <div className="page-body">
        <div className="container-xl">
          <div className="card card-lg">
            <div className="card-body">
              <div className="row g-4">
                <div className="col-12 markdown">
                  <h1>FAQ</h1>
                  <p>
                    This are some of the frequently asked questions to our team.
                  </p>
                </div>
              </div>
              <div className="row col-12 mt-4">
                <div className="col-12">
                  <hr />
                  <p>
                    <span className="text-primary">
                      What services does Hire48.com offer to employers?
                    </span>
                    <br />
                    Hire48.com offers employers a dedicated account manager,
                    resume proofing, background verification, salary
                    verification, and a 48-hour hiring guarantee to ensure quick
                    and efficient hiring of the right candidates.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      How does the dedicated account manager assist employers?
                    </span>
                    <br />
                    Our dedicated account manager provides personalized support
                    to streamline your hiring process, offering assistance with
                    candidate selection, interview scheduling, and addressing
                    any hiring needs to help you fill positions efficiently.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      What is the resume proofing service, and how does it
                      benefit employers?
                    </span>
                    <br />
                    Our resume proofing service ensures that all candidate
                    resumes are professionally reviewed and verified, giving
                    employers confidence that the information is accurate and
                    reflective of the candidate's abilities.
                  </p>
                  <hr />

                  <p>
                    <span className="text-primary">
                      What is the resume proofing service, and how does it
                      benefit employers?
                    </span>
                    <br />
                    Our resume proofing service ensures that all candidate
                    resumes are professionally reviewed and verified, giving
                    employers confidence that the information is accurate and
                    reflective of the candidate's abilities.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      What is the salary verification service?
                    </span>
                    <br />
                    Our salary verification service confirms the candidate’s
                    previous salary details, providing employers with an
                    accurate understanding of the candidate’s compensation
                    history for better offer alignment.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      How can Hire48.com help me hire a candidate within 48
                      hours?
                    </span>
                    <br />
                    With our streamlined processes, dedicated account managers,
                    and pre-verified candidate pool, Hire48.com is equipped to
                    match you with the right candidates and complete the hiring
                    process within 48 hours.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      Can I request additional screenings for a candidate?
                    </span>
                    <br />
                    Yes, our team can accommodate additional screenings or
                    custom verifications based on your specific hiring
                    requirements. Your account manager will guide you through
                    the options available.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      How does Hire48.com ensure the quality of candidates?
                    </span>
                    <br />
                    We conduct rigorous screenings, including resume proofing,
                    skill verification, background checks, and salary
                    verification, to ensure that only qualified, reliable
                    candidates are recommended to you.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      Is there a cost associated with using Hire48.com’s
                      services for employers?
                    </span>
                    <br />
                    Yes, Hire48.com offers various service packages tailored to
                    employers' needs. Our team will provide you with a detailed
                    pricing structure based on your hiring requirements.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      What if the candidate hired does not meet the job
                      requirements?
                    </span>
                    <br />
                    Our goal is to ensure the perfect fit. If a candidate does
                    not meet the requirements, we work with you to find a
                    replacement candidate as quickly as possible to ensure
                    satisfaction.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      How does HIRE48 ensure there are no fake jobs?
                    </span>
                    <br />
                    HIRE48 only works with GST-registered companies and verifies
                    every job posting before it goes live.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      How does HIRE48 ensure there are no fake jobs?
                    </span>
                    <br />
                    HIRE48 only works with GST-registered companies and verifies
                    every job posting before it goes live.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      Is HIRE48 free for job seekers?
                    </span>
                    <br />
                    Yes, HIRE48 does not charge any fees from job seekers at any
                    stage of the hiring process.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      How fast can I get a job through HIRE48?
                    </span>
                    <br />
                    Yes, HIRE48 does not charge any fees from job seekers at any
                    stage of the hiring process.
                  </p>
                  <hr />
                  <p>
                    <span className="text-primary">
                      How does the dedicated manager help me?
                    </span>
                    <br />
                    dedicated manager guides you through the interview process,
                    provides mock Q&A, and helps improve your soft skills.
                  </p>
                  <hr />
                </div>

                <div className="col-lg-6 col-sm-12">
                  <div className="card border-0">
                    <div class="accordion" id="accordion-example">
                      {/*


5. What is skill verification, and why is it important?
Skill verification ensures that your abilities match employer requirements, making you a stronger candidate.

6. How can I improve my skill score?
You can take skill tests on HIRE48 to earn skill badges and increase your relevancy score.

7. What is the AI profile builder?
The AI profile builder helps create a structured and optimized resume to improve your chances of selection.

8. Can I get interview questions before my interview?
Yes, our AI generates mock interview Q&As based on your profile and the job role you apply for.

9. What is a relevancy score?
It is a score based on your skills and job experience, helping you match with the most relevant job openings.

10. How does the skill badge help me?
Recruiters prefer candidates with verified skill badges, increasing your chances of getting hired.

11. Can I get a salary hike through HIRE48?
Yes, many candidates receive up to a 50% hike in their salary through our skill-based matchmaking.

12. How do I apply for jobs on HIRE48?
Simply create your profile, upload your resume, and apply for relevant job listings.

13. How does HIRE48 match me with jobs?
Our AI algorithm selects jobs based on your skills, experience, and relevancy score.

14. Can I track my job applications?
Yes, you can track application status through your HIRE48 dashboard.

15. What happens after I apply for a job?
Once you apply, recruiters review your profile, and if shortlisted, you will be contacted for further steps.

16. Will I be notified if I am not selected?
Yes, HIRE48 keeps you informed about your application status.

17. What are the eligibility criteria for applying to jobs?
Eligibility criteria vary by job but generally include skill requirements and work experience.

18. Can I apply for multiple jobs at once?
Yes, you can apply for as many jobs as you want.

19. How do I prepare for my interview?
Your dedicated manager will help with mock Q&As, soft skills, and interview preparation.

20. What kind of jobs are available on HIRE48?
HIRE48 offers jobs across 70+ categories, including IT, finance, healthcare, and more.

21. How do I know if a company is genuine?
HIRE48 verifies all employers before allowing them to post jobs.

22. Can I work remotely?
Yes, remote job listings are available on HIRE48.

23. How do I update my resume on HIRE48?
You can upload a new resume or use the AI profile builder for updates.

24. How often should I update my profile?
It’s best to update your profile whenever you gain new skills or experience.

25. Can I negotiate my salary through HIRE48?
Yes, many employers allow salary negotiations during the final interview stage.

26. Does HIRE48 provide job recommendations?
Yes, our AI system recommends jobs based on your profile and skill score.

27. How do I get shortlisted faster?
Improve your skill score by taking tests and earning skill badges.

28. Are there part-time jobs available?
Yes, HIRE48 offers part-time, full-time, and freelance job opportunities.

29. What should I do if I don’t receive interview calls?
Enhance your profile, take skill tests, and apply to more relevant jobs.

30. Is my data safe on HIRE48?
Yes, we prioritize data security and never share your information without consent.

31. Can I delete my account?
Yes, you can delete your account anytime from your profile settings.

32. Does HIRE48 provide career counseling?
Yes, our dedicated managers provide career guidance and job search tips.

33. Can I refer a friend to HIRE48?
Yes, you can refer friends, and they can apply for jobs through our platform.

34. What should I do if I find a fake job?
Report it to 101@hire48.com immediately.

35. Does HIRE48 offer internship opportunities?
Yes, many companies list internships on our platform.

1. How do I post a job on HIRE48?
Simply sign up, fill in job details, and post in less than 60 seconds using our AI-based system.

2. How does HIRE48 verify candidates?
We conduct skill verification tests, background checks, and profile screening.

3. Can I interview candidates before shortlisting?
Yes, our platform allows pre-screening and interview scheduling.

4. What is the recruiter access process?
Our recruiters interview candidates and shortlist the most relevant ones for your review.

5. How many applications can I receive per job posting?
There is no limit; you receive unlimited applications.

6. What is the turnaround time for hiring?
HIRE48 helps you close positions in less than 36 hours.

7. What are the pricing plans for employers?
Plans start at ₹4,499 for salary below ₹15,000/month, ₹9,999 for salaries up to ₹50,000, and ₹24,999 for CXO-level hiring.

8. How does background verification work?
We verify work experience, salary, and employment history before final selection.

9. How does AI help in hiring?
AI-based job posting and skill mapping improve accuracy and speed in hiring.

10. How do I manage candidate shortlisting?
You receive a list of pre-screened candidates based on relevance and skills.

11. Can I hire multiple candidates for a single job post?
Yes, you can hire as many candidates as needed.

12. Do you provide bulk hiring solutions?
Yes, we offer solutions for bulk hiring across multiple roles.

13. What industries do you serve?
We cater to IT, healthcare, finance, manufacturing, and 70+ other sectors.

14. Can I directly contact candidates?
Yes, once shortlisted, you can connect with them.

15. What happens if I don’t find the right candidate?
We continue sourcing candidates until your position is filled.

16. Is HIRE48 better than traditional job portals?
Yes, we offer AI-driven hiring with 90% better ROI.

17. How does HIRE48 prevent fraudulent applications?
We verify every candidate through skill tests and background checks.

18. What if a hired candidate leaves soon?
We offer replacement guarantees based on your hiring plan.

19. Can I edit my job postings?
Yes, job postings can be updated anytime.

20. How do I reach support?
Contact us at 101@hire48.com.

 */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FAQs;
