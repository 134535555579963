import React from "react";
import Footer from "../../Components/Footer/footer";

const AboutUs = () => {
  return (
    <>
      <div className="page-body">
        <div className="container-xl">
          <div className="card card-lg">
            <div className="card-body ">
              <div className="row g-4">
                <div className="col-12 markdown">
                  <h1>About Us</h1>
                  <p>
                    Hire48 is build by the same team who build Kryzen Biotech. A
                    successful company in Agritech domain. From the same
                    problems we faced in building our team we identified few
                    gaps in the industry processes and decided to build a new
                    product for it.
                  </p>
                  <p>
                    From startups to SMEs to established enterprises, Hire48
                    revolutionizes the way businesses find high-quality talent
                    quickly & effortlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
